.hero-abs-container {position: absolute; left: 0; top: 0; width: 100%;  height:var(--bkgCont);}
.hero-img-cont {height: 100%; background-position: center; background-size: cover; background-repeat: no-repeat;}
/* Don't use when height is 100%
.hero-abs-container::before {
    background: linear-gradient(to bottom, rgba(234,237,237,0) ,#f3f6fb );
    bottom: 0;
    content: "";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00EAEDED', endColorstr='#EAEDED', GradientType=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00EAEDED', endColorstr='#EAEDED',GradientType=0)";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: var(--frontCont);
    z-index: 1;
}
*/
