.cl-range-right-bar {width: 25%; margin-left: 16px; box-shadow: 1px 1px 2px rgba(0,0,0,.2);border-radius: 6px; background-color: white;padding: 12px;}
.cl-range-right-bar > div {margin-bottom: 16px;}
.cl-range-right-bar > div:last-child {margin-bottom: 0}

.cl-actions-cont { background-color: #FBF4F6; border-radius: 4px;min-height: 214px;}
.cl-actions-btn-cont > div {margin-right: 8px;}
.cl-actions-btn-cont > div:last-child {margin-right: 0px;}
.cl-actions-heading {min-height: 32px; margin-bottom: 0px;}
.cl-actions-heading-h4 {font-size: 19px; min-height: 21px; line-height: 1.1; width: 100%; margin: 16px 0 16px 0; font-weight: 600; }
.cl-actions-heading-h4-large {font-size: 30px; min-height: 33px; line-height: 1.1; width: 100%; margin: 40px 0 16px 0; font-weight: 600; }
.cl-actions-message {font-size: 14px; line-height: 1.2; color: #e85858; font-weight: 400;}

.form-noLab-cl-title {
  /* container */   /**/
  --bRadius: 6px; --brdBot: none ; --brd:none; --height: calc(21px + 12px);  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
  /* input */
  --inpContPad: 14px; --inpSize: 17px; --inpPadTop: 16px; --inpWeight: 500;

}
.form-noLab-cl-title-large {
  /* container */   /**/
  --bRadius: 6px; --brdBot: none ; --brd:none; --height: calc(33px + 24px);  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
  /* input */
  --inpContPad: 14px; --inpSize: 27px; --inpPadTop: 16px; --inpWeight: 500;

}

@media (max-width:734px) {
.cl-actions-cont {background-color: transparent;}
.cl-range-right-bar {width: 100%; background-color: transparent;margin-left: 0; box-shadow: none; padding: 20px 0;}
.scarlet-is-open .cl-range-right-bar, .cl-range-right-bar.conv-is-focused {padding: 0 !important}
.cl-actions-heading-h4 {font-size: 22px; min-height: 24px;}
}
