.cl-range-row-container {  margin-bottom: 8px;border-radius: 10px; overflow: hidden;background: hsl(210,100%,97%);}
.cl-range-row-container:last-child {margin-bottom: 0;}
.cl-range-row {padding: 0 20px 3px 20px;   height:72px;}

.cl-input-range-edit-container {position: absolute;top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255,255,255,.8);z-index: 10}
.cl-input-range-edit { width: 80px}
.cl-col-imp-done-ic {transform: translate(100%, -50%); position: absolute; top: 60%; right: -16px; cursor: pointer;}

.cl-range-lev-range-num {line-height: 1; font-size: 12px; height: 12px;left: 0;display: inline-block;position: absolute;top: 0;line-height: 1; margin-top: -1px}
.cl-range-lev-range-num:nth-child(2) {left: 50%; transform: translateX(-50%);}
.cl-range-lev-range-num:nth-child(3) {right: 0;left: auto;}
.cl-range-input-title {padding: 11px 16px; color: dodgerblue; font-weight: 700;position: absolute; top: 0; font-size: 15px;line-height: 1;}
.rg-ph-sliding .cl-range-input-title {color: darkorange;}
.cl-inp-col-err {position: absolute;left: 100%; margin-left: 48px; top: 0%; transform: translateY(-10px); font-size: 13px; width: 160px;z-index: 50;
  padding: 0 10px;border: none;border-radius: 6px 0 0 6px; border-left: 4px solid red; ;background-color: white; line-height: 1.2; min-height: 60px; font-weight: 500}


.range {-webkit-appearance: none;  height: 17px;  border-radius: 12px; background-size: 100% 100%; border: 1px solid rgb(210,210,210); background-repeat: no-repeat;  overflow: visible;}
.rg-ph-sliding .range {border:2px solid darkorange; height: 18px}
.range::-webkit-slider-thumb {  -webkit-appearance: none;height: 16px;  width: 16px;  border-radius: 50%;  background: blue;  cursor: pointer;  box-shadow: 0 0 2px 0 #555;  transition: background .3s ease-in-out;  }
.range::-moz-range-thumb {height: 18px;  width: 18px;  border-radius: 50%;  background: blue;  cursor: pointer;  box-shadow: 0 0 2px 0 #555;  transition: background .3s ease-in-out;}
.range::-ms-thumb {  height: 16px;  width: 16px;  border-radius: 50%;  background: blue;  cursor: pointer;  box-shadow: 0 0 2px 0 #555;  transition: background .3s ease-in-out;}
.rg-ph-sliding .range::-webkit-slider-thumb {background: darkorange;}
.rg-ph-sliding .range::-moz-range-thumb {background: darkorange;}
.rg-ph-sliding .range::-ms-thumb {background: darkorange;}
.range::-webkit-slider-runnable-track {box-shadow: none;  border: none;  background: transparent;}
.range::-moz-range-track {  box-shadow: none;  border: none;background: transparent; }
.range::-ms-track {  box-shadow: none;  border: none;  background: transparent;}

.cl-range-input-defs {
  /* container */   /**/
  --bRadius: 5px;  --brd:2px; --height: 45px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 10px ; --covLabelSize: 14px; --cLabWeight: 500;
  /* label */
  --labelTopPad: 8px;--labelBotPad: 0; --labelContPad: 8px;  --labelSize: 12px; --labWeight: 500;
  --labelAlign: left; ; --labelCol: black;
  /* input */
  --inpContPad: 22px; --inpSize: 17px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 40px;
}

  @media (max-width:734px) {
    .cl-range-row-container {width: 100%; background: inherit;}
    .cl-input-range-edit { width: 90px}
    .cl-range-row {padding: 0 21px;   height:55px; align-items: flex-end; }
    .scarlet-is-open .cl-range-row {padding: 0 21px;   height:45px;  }
    .rg-ph-sliding .cl-range-row {padding: 0 20px;}
    .cl-range-input-title {font-size: 18px;padding: 10px 35px;}
    .scarlet-is-open .cl-range-input-title {font-size: 16px;padding: 4px 35px;}
    .range { height: 20px; }
    .range-phone { height: 22px; border-radius: 12px; background-size: 100% 100%;  background-repeat: no-repeat;  overflow: visible;position: relative;border: 1px solid rgb(210,210,210);}
    .rg-ph-sliding .range-phone {border:2px solid darkorange; height: 24px;}
    .range-phone-thumb { height: 20px;  width: 20px; border-radius: 50%;  background: blue;  cursor: pointer;  box-shadow: 0 0 2px 0 #555;  transition: background .3s ease-in-out;position: absolute;top: 0; }
    .rg-ph-sliding .range-phone-thumb {background: darkorange;}
    .range::-webkit-slider-thumb { height: 20px;  width: 20px; }
    .range::-moz-range-thumb { height: 20px;  width: 20px; }
    .range::-ms-thumb { height: 20px;  width: 20px; }
    .cl-range-lev-range-num {font-size: 13px}
    .cl-range-input-defs {  --height: 50px; --inpContPad: 22px; --inpSize: 18px; --inpWeight: 500;}
  }
