.cl-range-in-level {line-height: 1; padding: 4px 0; font-size: 12px; font-weight: 600;border-radius: 20%;position: absolute; bottom: 65%; transform: translateX(-50%);
   background: dodgerblue; color: white;width: 40px;text-align: center;}
.rg-ph-sliding .cl-range-in-level, .cl-lev-clicked.cl-range-in-level {background-color: darkorange}

.cl-range-in-level-arrow {content: "";position: absolute;top: 100%; left:50%;border-width: 5px;transform: translateX(-50%);border-style: solid;border-color:  dodgerblue transparent transparent  transparent;}
.rg-ph-sliding .cl-range-in-level-arrow, .cl-lev-clicked .cl-range-in-level-arrow {border-color:  darkorange transparent transparent  transparent;}
.cl-range-arr-right-action {position: absolute;top: 50%; right: -10px; transform: translate(100%, -50%); cursor: pointer;}
.cl-range-arr-left-action {position: absolute;top: 50%; left: -10px; transform: translate(-100%, -50%); cursor: pointer;}
.cl-range-arr-right-action.cl-range-start {position: absolute;top: 50%; right: -20px; transform: translate(200%, -50%); cursor: pointer;}
.cl-range-arr-left-action.cl-range-start {position: absolute;top: 50%; right: -10px; transform: translate(100%, -50%); cursor: pointer; left: auto;}
.cl-range-arr-right-action.cl-range-end {position: absolute;top: 50%; left: -10px; transform: translate(-100%, -50%); cursor: pointer;right: auto}
.cl-range-arr-left-action.cl-range-end {position: absolute;top: 50%; left: -20px; transform: translate(-200%, -50%); cursor: pointer;}


  @media (max-width:734px) {
    .cl-range-in-level {line-height: 1; padding: 5px 0; font-size: 13px; font-weight: 600;border-radius: 20%;position: absolute; bottom: 74%; transform: translateX(-50%);
       background: dodgerblue; color: white;width: 44px;text-align: center;}
    .scarlet-is-open   .cl-range-in-level-arrow {display: none;}
    .scarlet-is-open .cl-range-in-level {bottom: 66%;padding: 3px 0; font-size: 12px;}
    .cl-range-arr-right-action {right: -20px; }
    .cl-range-arr-left-action  {left: -20px; }
    .cl-range-in-level-arrow {border-width: 7px;}

  }
