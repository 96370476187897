.ukr-home-defs {
--centerBlockWidth:1110px;--sideBlocksMargin:32px;--centerMainBlockWidth: 63%;--feedBlockPadding:16px;--centBlocksIntraMargin:24px;
/* Header */
--headH2: 45px;--headerBotPadding: calc(1.3 * var(--feed-cont-padding));
/* Feed */
--feed-cont-padding: 20px;--feedTitleSize:22px;--feedsubtitleSize:16px;--feedTextSize:16px;
}
.ukr-home-body {display: flex;align-items: stretch;justify-content: space-between;flex-wrap: nowrap;--sideBlockWidth: calc((100% - var(--centerBlockWidth) - 2 * var(--sideBlocksMargin)) / 2);
  background-color: rgb(242,242,242);padding: calc(4 * var(--feed-cont-padding)) 0}
.ukr-home-left {width: var(--sideBlockWidth);position: sticky; top:55px;height: calc(100vh - 55px);display: flex;justify-content: center;align-items: center;}
.ukr-home-right {width: var(--sideBlockWidth);}
.ukr-home-center {width: var(--centerBlockWidth); display: flex;align-items: flex-start;justify-content: space-between;position: relative;flex-wrap: wrap;}
.ukr-home-center-main {width: var(--centerMainBlockWidth);}
.ukr-home-center-side {width: calc(100% - var(--centBlocksIntraMargin) - var(--centerMainBlockWidth));position: sticky;top:calc(55px);}

@media only screen and (max-width: 1400px) {
  .ukr-home-defs {--sideBlocksMargin:32px;--centerBlockWidth:calc(100% - 2 * var(--sideBlocksMargin));--centerMainBlockWidth: 66%;--feedBlockPadding:16px;--centBlocksIntraMargin:32px;
    --headH2: 35px;
  }
}

@media only screen and (max-width: 1068px) {
  .ukr-home-defs {--sideBlocksMargin:16px;--centerBlockWidth:calc(100% - 2 * var(--sideBlocksMargin));--centerMainBlockWidth: 66%;--feedBlockPadding:16px;--centBlocksIntraMargin:16px;
    --headH2: 32px;
  }

}

@media only screen and (max-width: 850px)  {
  .ukr-home-defs {--sideBlocksMargin:16px;--centerBlockWidth:calc(100% - 2 * var(--sideBlocksMargin));--centerMainBlockWidth: 100%;--feedBlockPadding:16px;--centBlocksIntraMargin:0%;
  /* Header */
  --headH2: 30px;--headerTopPadding: calc(3 * var(--feed-cont-padding));--headerBotPadding: var(--feed-cont-padding);
  /* Feed */
  --feed-cont-padding: 14px;--feedTitleSize:20px;--feedsubtitleSize:16px;--feedTextSize:16px;
  }
  .ukr-home-center-side {display: none}
}
