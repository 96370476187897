.cl-hex-cont {height: 65px;padding: 0 !important; flex-grow: 1; position: relative;background-color: white}

.cl-contrast-black {color: black;}
.cl-contrast-white {color: white;}
.cl-bkg-contrast-black {background-color: rgba(0,0,0,.3); color: white; }
.cl-bkg-contrast-white {color: black;background-color: rgba(255,255,255,.5);}

.hex-clipboard-copied { font-size: 12px; font-weight: 700;  line-height: 1; margin-top: 20px;}

.cl-hex-imp-act-icons {position: absolute;height: 20px; top: 50%; right: 16px; transform: translateY(-50%);}
.cl-hex-imp-act-icons div {height: 20px;}
.cl-inp-hex-col-err {position: absolute;left: 50%;  top: 100%; transform: translate(-50%, 12px);font-size: 14px; width: 260px;z-index: 50;
  padding: 0 10px;border: none;border-radius: 6px 0 0 6px; border-left: 4px solid red; ;background-color: white; line-height: 1.2; min-height: 50px; font-weight: 500; color: black;}

.hex-imp-defs {
  /* container */   /**/
  --bRadius: 5px;  --brd:2px; --height: 50px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 10px ; --covLabelSize: 14px; --cLabWeight: 500;
  /* label */
  --labelTopPad: 8px;--labelBotPad: 0; --labelContPad: 8px;  --labelSize: 12px; --labWeight: 500;
  --labelAlign: left; ; --labelCol: black;
  /* input */
  --inpContPad: 20px; --inpSize: 32px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 40px;
}


@media (max-width:734px) {
  .cl-hex-cont {height: calc(100vw * 0.26); flex-grow: 1;padding:0 !important; position: fixed;top: var(--tBarHeight);left: 0;z-index: 100; border-radius: 0 !important; transform: translate3d(0,0,0);
     margin: 0 0 16px 0 !important; width: 100% !important; box-shadow: none !important;}
  .no-top-bar .cl-hex-cont {top:0;}
  .cl-hex-cont-inner {height: calc(100vw * 0.26);padding: 6px 20px !important;width: 100% !important;}
  .cl-hex-cont.scarlet-open {height: calc(100vw * 0.2);}
  .scarlet-open .cl-hex-cont-inner {height: calc(100vw * 0.2);padding: 0 !important;}
  .hex-clipboard-copied { margin-top: 20px; margin-left: 8px;font-size: 12px; font-weight: 700;  line-height: 1}
}
@media (min-width:1800px) {
  .cl-contrast-black:hover {color: hsl(0,0%,30%);}
  .cl-contrast-white:hover {color: hsl(360,100%,88%);}
}
