.embedded-outer {position: relative;width: 100%;z-index: 0;}
.embedded-container {  margin: 0 30px 30px 0;width: 300px;float: left;  clear: both;}
.embedded-container.no-caption {  margin-bottom: 15px}
.embedded-container img {display: block; width: 100%; height: auto;vertical-align: middle;}
.embedded-caption {color: #595959;margin-top: 30px;position: relative;font-size: 16px; font-family: "Helvetica Neue", Helvetica, Arial, Utkal, sans-serif; position: relative;}
.embedded-caption::before { border-top: 1px solid #d9d9d9;  bottom: 100%;  content: "";display: block;  height: 1px;  margin-top: 10px;  margin-bottom: 10px;  position: absolute;  width: 80px;}

.embedded-caption span {color: #595959;  line-height: 1.5; font-family: "Helvetica Neue", Helvetica, Arial, Utkal, sans-serif; font-weight: 300}

@media only screen and (max-width: 734px) {
  .embedded-container {margin: 0 0 10px 0;width: 100%;}
  .embedded-container.no-caption {  margin-bottom: var(--cn-par-verMarg)}
  .embedded-caption {line-height: 20px;}
}
