    /* pdL pdR 0-20px inc 1 */
    .a-pdL-0 {padding-left: 0px!important;}.a-pdR-0 {padding-right: 0px!important;}.a-pdL-1 {padding-left: 1px;}.a-pdR-1 {padding-right: 1px;}.a-pdL-2 {padding-left: 2px;}.a-pdR-2 {padding-right: 2px;}.a-pdL-3 {padding-left: 3px;}.a-pdR-3 {padding-right: 3px;}.a-pdL-4 {padding-left: 4px;}.a-pdR-4 {padding-right: 4px;}.a-pdL-5 {padding-left: 5px;}.a-pdR-5 {padding-right: 5px;}.a-pdL-6 {padding-left: 6px;}.a-pdR-6 {padding-right: 6px;}.a-pdL-7 {padding-left: 7px;}.a-pdR-7 {padding-right: 7px;}.a-pdL-8 {padding-left: 8px;}.a-pdR-8 {padding-right: 8px;}.a-pdL-9 {padding-left: 9px;}.a-pdR-9 {padding-right: 9px;}.a-pdL-10 {padding-left: 10px;}.a-pdR-10 {padding-right: 10px;}.a-pdL-11 {padding-left: 11px;}.a-pdR-11 {padding-right: 11px;}.a-pdL-12 {padding-left: 12px;}.a-pdR-12 {padding-right: 12px;}.a-pdL-13 {padding-left: 13px;}.a-pdR-13 {padding-right: 13px;}.a-pdL-14 {padding-left: 14px;}.a-pdR-14 {padding-right: 14px;}.a-pdL-15 {padding-left: 15px;}.a-pdR-15 {padding-right: 15px;}.a-pdL-16 {padding-left: 16px;}.a-pdR-16 {padding-right: 16px;}.a-pdL-17 {padding-left: 17px;}.a-pdR-17 {padding-right: 17px;}.a-pdL-18 {padding-left: 18px;}.a-pdR-18 {padding-right: 18px;}.a-pdL-19 {padding-left: 19px;}.a-pdR-19 {padding-right: 19px;}.a-pdL-20 {padding-left: 20px;}.a-pdR-20 {padding-right: 20px;}
    /* pdL pdR 22-40px inc 2 */
    .a-pdL-22 {padding-left: 22px;}.a-pdR-22 {padding-right: 22px;}.a-pdL-24 {padding-left: 24px;}.a-pdR-24 {padding-right: 24px;}.a-pdL-26 {padding-left: 26px;}.a-pdR-26 {padding-right: 26px;}.a-pdL-28 {padding-left: 28px;}.a-pdR-28 {padding-right: 28px;}.a-pdL-30 {padding-left: 30px;}.a-pdR-30 {padding-right: 30px;}.a-pdL-32 {padding-left: 32px;}.a-pdR-32 {padding-right: 32px;}.a-pdL-34 {padding-left: 34px;}.a-pdR-34 {padding-right: 34px;}.a-pdL-36 {padding-left: 36px;}.a-pdR-36 {padding-right: 36px;}.a-pdL-38 {padding-left: 38px;}.a-pdR-38 {padding-right: 38px;}.a-pdL-40 {padding-left: 40px;}.a-pdR-40 {padding-right: 40px;}
    /* padding 0-32px inc 2 */
    .a-pd-0 {padding: 0px !important;} .a-pd-2 {padding: 2px;} .a-pd-4 {padding: 4px;} .a-pd-6 {padding: 6px;} .a-pd-8 {padding: 8px;} .a-pd-10 {padding: 10px;} .a-pd-12 {padding: 12px;} .a-pd-14 {padding: 14px;} .a-pd-16 {padding: 16px;} .a-pd-18 {padding: 18px;} .a-pd-20 {padding: 20px;} .a-pd-22 {padding: 22px;} .a-pd-24 {padding: 24px;} .a-pd-26 {padding: 26px;} .a-pd-28 {padding: 28px;} .a-pd-30 {padding: 30px;} .a-pd-32 {padding: 32px;}


@media (max-width:1200px) {
    /* pdL pdR 0-20px inc 1 */
    .a-pdL-0-t {padding-left: 0px!important;}.a-pdR-0 {padding-right: 0px!important;} .a-pdL-1-t {padding-left: 1px;}.a-pdR-1 {padding-right: 1px;} .a-pdL-2-t {padding-left: 2px;}.a-pdR-2 {padding-right: 2px;} .a-pdL-3-t {padding-left: 3px;}.a-pdR-3 {padding-right: 3px;} .a-pdL-4-t {padding-left: 4px;}.a-pdR-4 {padding-right: 4px;} .a-pdL-5-t {padding-left: 5px;}.a-pdR-5 {padding-right: 5px;} .a-pdL-6-t {padding-left: 6px;}.a-pdR-6 {padding-right: 6px;} .a-pdL-7-t {padding-left: 7px;}.a-pdR-7 {padding-right: 7px;} .a-pdL-8-t {padding-left: 8px;}.a-pdR-8 {padding-right: 8px;} .a-pdL-9-t {padding-left: 9px;}.a-pdR-9 {padding-right: 9px;} .a-pdL-10-t {padding-left: 10px;}.a-pdR-10 {padding-right: 10px;} .a-pdL-11-t {padding-left: 11px;}.a-pdR-11 {padding-right: 11px;} .a-pdL-12-t {padding-left: 12px;}.a-pdR-12 {padding-right: 12px;} .a-pdL-13-t {padding-left: 13px;}.a-pdR-13 {padding-right: 13px;} .a-pdL-14-t {padding-left: 14px;}.a-pdR-14 {padding-right: 14px;} .a-pdL-15-t {padding-left: 15px;}.a-pdR-15 {padding-right: 15px;} .a-pdL-16-t {padding-left: 16px;}.a-pdR-16 {padding-right: 16px;} .a-pdL-17-t {padding-left: 17px;}.a-pdR-17 {padding-right: 17px;} .a-pdL-18-t {padding-left: 18px;}.a-pdR-18 {padding-right: 18px;} .a-pdL-19-t {padding-left: 19px;}.a-pdR-19 {padding-right: 19px;} .a-pdL-20-t {padding-left: 20px;}.a-pdR-20 {padding-right: 20px;}
    /* pdL pdR 22-40px inc 2 */
    .a-pdL-22-t {padding-left: 22px;}.a-pdR-22 {padding-right: 22px;} .a-pdL-24-t {padding-left: 24px;}.a-pdR-24 {padding-right: 24px;} .a-pdL-26-t {padding-left: 26px;}.a-pdR-26 {padding-right: 26px;} .a-pdL-28-t {padding-left: 28px;}.a-pdR-28 {padding-right: 28px;} .a-pdL-30-t {padding-left: 30px;}.a-pdR-30 {padding-right: 30px;} .a-pdL-32-t {padding-left: 32px;}.a-pdR-32 {padding-right: 32px;} .a-pdL-34-t {padding-left: 34px;}.a-pdR-34 {padding-right: 34px;} .a-pdL-36-t {padding-left: 36px;}.a-pdR-36 {padding-right: 36px;} .a-pdL-38-t {padding-left: 38px;}.a-pdR-38 {padding-right: 38px;} .a-pdL-40-t {padding-left: 40px;}.a-pdR-40 {padding-right: 40px;}
    /* padding 0-32px inc 2 */
    .a-pd-0-t {padding: 0px;} .a-pd-2-t {padding: 2px;} .a-pd-4-t {padding: 4px;} .a-pd-6-t {padding: 6px;} .a-pd-8-t {padding: 8px;} .a-pd-10-t {padding: 10px;} .a-pd-12-t {padding: 12px;} .a-pd-14-t {padding: 14px;}
    .a-pd-16-t{padding: 16px;} .a-pd-18-t {padding: 18px;} .a-pd-20-t {padding: 20px;} .a-pd-22-t {padding: 22px;} .a-pd-24-t {padding: 24px;} .a-pd-26-t {padding: 26px;} .a-pd-28-t {padding: 28px;} .a-pd-30-t {padding: 30px;} .a-pd-32-t {padding: 32px;}
  }


@media (max-width:734px) {
    /* pdL pdR 0-20px inc 1 */
    .a-pdL-0-s {padding-left: 0px!important;}.a-pdR-0 {padding-right: 0px!important;} .a-pdL-1-s {padding-left: 1px;}.a-pdR-1 {padding-right: 1px;} .a-pdL-2-s {padding-left: 2px;}.a-pdR-2 {padding-right: 2px;} .a-pdL-3-s {padding-left: 3px;}.a-pdR-3 {padding-right: 3px;} .a-pdL-4-s {padding-left: 4px;}.a-pdR-4 {padding-right: 4px;} .a-pdL-5-s {padding-left: 5px;}.a-pdR-5 {padding-right: 5px;} .a-pdL-6-s {padding-left: 6px;}.a-pdR-6 {padding-right: 6px;} .a-pdL-7-s {padding-left: 7px;}.a-pdR-7 {padding-right: 7px;} .a-pdL-8-s {padding-left: 8px;}.a-pdR-8 {padding-right: 8px;} .a-pdL-9-s {padding-left: 9px;}.a-pdR-9 {padding-right: 9px;} .a-pdL-10-s {padding-left: 10px;}.a-pdR-10 {padding-right: 10px;} .a-pdL-11-s {padding-left: 11px;}.a-pdR-11 {padding-right: 11px;} .a-pdL-12-s {padding-left: 12px;}.a-pdR-12 {padding-right: 12px;} .a-pdL-13-s {padding-left: 13px;}.a-pdR-13 {padding-right: 13px;} .a-pdL-14-s {padding-left: 14px;}.a-pdR-14 {padding-right: 14px;} .a-pdL-15-s {padding-left: 15px;}.a-pdR-15 {padding-right: 15px;} .a-pdL-16-s {padding-left: 16px;}.a-pdR-16 {padding-right: 16px;} .a-pdL-17-s {padding-left: 17px;}.a-pdR-17 {padding-right: 17px;} .a-pdL-18-s {padding-left: 18px;}.a-pdR-18 {padding-right: 18px;} .a-pdL-19-s {padding-left: 19px;}.a-pdR-19 {padding-right: 19px;} .a-pdL-20-s {padding-left: 20px;}.a-pdR-20 {padding-right: 20px;}
    /* pdL pdR 22-40px inc 2 */
    .a-pdL-22-s {padding-left: 22px;}.a-pdR-22 {padding-right: 22px;} .a-pdL-24-s {padding-left: 24px;}.a-pdR-24 {padding-right: 24px;} .a-pdL-26-s {padding-left: 26px;}.a-pdR-26 {padding-right: 26px;} .a-pdL-28-s {padding-left: 28px;}.a-pdR-28 {padding-right: 28px;} .a-pdL-30-s {padding-left: 30px;}.a-pdR-30 {padding-right: 30px;} .a-pdL-32-s {padding-left: 32px;}.a-pdR-32 {padding-right: 32px;} .a-pdL-34-s {padding-left: 34px;}.a-pdR-34 {padding-right: 34px;} .a-pdL-36-s {padding-left: 36px;}.a-pdR-36 {padding-right: 36px;} .a-pdL-38-s {padding-left: 38px;}.a-pdR-38 {padding-right: 38px;} .a-pdL-40-s {padding-left: 40px;}.a-pdR-40 {padding-right: 40px;}
    /* padding 0-32px inc 2 */
    .a-pd-0-s {padding: 2px;} .a-pd-2-s {padding: 2px;} .a-pd-4-s {padding: 4px;} .a-pd-6-s {padding: 6px;} .a-pd-8-s {padding: 8px;} .a-pd-10-s {padding: 10px;} .a-pd-12-s {padding: 12px;} .a-pd-14-s {padding: 14px;}
    .a-pd-16-s {padding: 16px;} .a-pd-18-s {padding: 18px;} .a-pd-20-s {padding: 20px;} .a-pd-22-s {padding: 22px;} .a-pd-24-s {padding: 24px;} .a-pd-26-s {padding: 26px;} .a-pd-28-s {padding: 28px;} .a-pd-30-s {padding: 30px;} .a-pd-32-s {padding: 32px;}
}
