/*
Colors definitions.
--h --s --l sets the background for filled buttons, the text color and border for bordered buttons. background and border hover use the same hsl value incrising only the luminosity as specified directly in the button, the luminosity on hover is not set in the colors definition
--btn-color sets the text color only for filled buttons.

.a-btn-text-white { --btn-color: rgb(247,247,247)}
.a-btn-blue-1 {--h: 205.1; --s: 100%; --l: 42%; --btn-color: white; --hex: #007DD6}
.a-btn-blue-2 {--h: 212; --s: 67%; --l: 46%;--btn-color: white; --hex: #2770C4}
.a-btn-blue-twitter {--h: 204; --s: 88%; --l: 53%;--btn-color: white; --hex: #1E9CF1}
.a-btn-FBblue {--h: 220; --s: 46%; --l: 48%;--btn-color: white; --hex: #4268B3}
.a-btn-orange-1 {--h: 35; --s: 95%; --l: 49%;--btn-color: white; --hex: #F49106}
.a-btn-orange-2 {--h: 22; --s: 100%; --l: 50%;--btn-color: white; --hex: #FF5E00}
.a-btn-green-1 {--h: 140; --s: 27%; --l: 56%;--btn-color: white; --hex: #71AD85}
.a-btn-gray {--h: 0; --s: 0%; --l: 50%;--btn-color: white; --hex: #808080}
.a-btn-whatsapp-green {--h: 142; --s: 70%; --l: 49%;--btn-color: white; --hex: #25D466}
.a-btn-black {--h: 0; --s: 0%; --l: 0%;--btn-color: white; --hex: #000000}
.a-btn-ukr-flag {--h: 212; --s: 100%; --l: 36%;--btn-color: #FFD700; --hex: #0056B8}
.a-btn-violet {--h: 248; --s: 99%; --l: 73%;--btn-color: white; --hex: #8876fe}
.a-btn-magenta {--h: 303; --s: 78%; --l: 47%;--btn-color: white; --hex:#d71bce}
.a-btn-indigo {--h: 256; --s: 100%; --l: 58%;--btn-color: white; --hex: #622aff}
.a-btn-grape {--h: 257; --s: 94%; --l: 57%;--btn-color: white; --hex: #6529f8}
.a-btn-azure {--h: 210; --s: 100%; --l: 50%;--btn-color: white; --hex: #027fff}
.a-btn-tomato {--h: 353; --s: 91%; --l: 60%;--btn-color: white; --hex: #f63e54}
.a-btn-orange {--h: 27; --s: 98%; --l: 45%;--btn-color: white; --hex: #e66702}
.a-btn-green {--h: 144; --s: 86%; --l: 33%;--btn-color: white; --hex: #0c9d46}
.a-btn-forest {--h: 149; --s: 83%; --l: 28%;--btn-color: white; --hex: #0c8346}
.a-btn-sea-green {--h: 181; --s: 89%; --l: 33%;--btn-color: white; --hex: #099b9d}
.a-btn-teal {--h: 189; --s: 96%; --l: 37%;--btn-color: white; --hex: #049cb7}
.a-btn-aqua {--h: 174; --s: 96%; --l: 32%;--btn-color: white; --hex: #039e8f}
.a-btn-moonstone {--h: 192; --s: 68%; --l: 41%;--btn-color: white; --hex:#2294b1}
.a-btn-midnight {--h: 237; --s: 74%; --l: 40%;--btn-color: white; --hex: #1b23b3}
.a-btn-sapphire {--h: 237; --s: 53%; --l: 50%;--btn-color: white; --hex: #3d44c3}
.a-btn-bumblebee {--h: 54; --s: 98%; --l: 50%;--btn-color: white; --hex: #FCE205}
.a-btn-amber {--h: 45; --s: 100%; --l: 50%;--btn-color: white; --hex: #FFBF00}
.a-btn-lemon {--h: 65; --s: 98%; --l: 68%;--btn-color: white; --hex: #EFFD5F}
.a-btn-cyber {--h: 50; --s: 100%; --l: 50%;--btn-color: white; --hex: #FFD300}
.a-btn-honey {--h: 45; --s: 100%; --l: 52%;--btn-color: white; --hex: #FFC30B}
.a-btn-ukr-flag {--h: 212; --s: 100%; --l: 36%;--btn-color: #FFD700; --hex: #0056B8}
*/




.a-font-aliceblue {color: #f0f8ff}
.a-brd-aliceblue  {border-color: #f0f8ff}
.a-bkg-aliceblue {background-color: #f0f8ff}
:root {--aliceblue: #f0f8ff}
.a-btn-aliceblue {--hex: #f0f8ff;--h:208;--s:100%;--l:97%;--btn-color:#000000}
.a-font-amber {color: #ffbf00}
.a-brd-amber  {border-color: #ffbf00}
.a-bkg-amber {background-color: #ffbf00}
:root {--amber: #ffbf00}
.a-btn-amber {--hex: #ffbf00;--h:45;--s:100%;--l:50%;--btn-color:#000000}
.a-font-antiquewhite {color: #faebd7}
.a-brd-antiquewhite  {border-color: #faebd7}
.a-bkg-antiquewhite {background-color: #faebd7}
:root {--antiquewhite: #faebd7}
.a-btn-antiquewhite {--hex: #faebd7;--h:34;--s:78%;--l:91%;--btn-color:#000000}
.a-font-aqua {color: #039e8f}
.a-brd-aqua  {border-color: #039e8f}
.a-bkg-aqua {background-color: #039e8f}
:root {--aqua: #039e8f}
.a-btn-aqua {--hex: #039e8f;--h:174;--s:96%;--l:32%;--btn-color:#ffffff}
.a-font-aqua {color: #00ffff}
.a-brd-aqua  {border-color: #00ffff}
.a-bkg-aqua {background-color: #00ffff}
:root {--aqua: #00ffff}
.a-btn-aqua {--hex: #00ffff;--h:180;--s:100%;--l:50%;--btn-color:#000000}
.a-font-aquamarine {color: #7fffd4}
.a-brd-aquamarine  {border-color: #7fffd4}
.a-bkg-aquamarine {background-color: #7fffd4}
:root {--aquamarine: #7fffd4}
.a-btn-aquamarine {--hex: #7fffd4;--h:160;--s:100%;--l:75%;--btn-color:#000000}
.a-font-azure {color: #027fff}
.a-brd-azure  {border-color: #027fff}
.a-bkg-azure {background-color: #027fff}
:root {--azure: #027fff}
.a-btn-azure {--hex: #027fff;--h:210;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-azure {color: #f0ffff}
.a-brd-azure  {border-color: #f0ffff}
.a-bkg-azure {background-color: #f0ffff}
:root {--azure: #f0ffff}
.a-btn-azure {--hex: #f0ffff;--h:180;--s:100%;--l:97%;--btn-color:#000000}
.a-font-beige {color: #f5f5dc}
.a-brd-beige  {border-color: #f5f5dc}
.a-bkg-beige {background-color: #f5f5dc}
:root {--beige: #f5f5dc}
.a-btn-beige {--hex: #f5f5dc;--h:60;--s:56%;--l:91%;--btn-color:#000000}
.a-font-bisque {color: #ffe4c4}
.a-brd-bisque  {border-color: #ffe4c4}
.a-bkg-bisque {background-color: #ffe4c4}
:root {--bisque: #ffe4c4}
.a-btn-bisque {--hex: #ffe4c4;--h:33;--s:100%;--l:88%;--btn-color:#000000}
.a-font-black {color: #000000}
.a-brd-black  {border-color: #000000}
.a-bkg-black {background-color: #000000}
:root {--black: #000000}
.a-btn-black {--hex: #000000;--h:0;--s:0%;--l:0%;--btn-color:#ffffff}
.a-font-blanchedalmond {color: #ffebcd}
.a-brd-blanchedalmond  {border-color: #ffebcd}
.a-bkg-blanchedalmond {background-color: #ffebcd}
:root {--blanchedalmond: #ffebcd}
.a-btn-blanchedalmond {--hex: #ffebcd;--h:36;--s:100%;--l:90%;--btn-color:#000000}
.a-font-blue {color: #0000ff}
.a-brd-blue  {border-color: #0000ff}
.a-bkg-blue {background-color: #0000ff}
:root {--blue: #0000ff}
.a-btn-blue {--hex: #0000ff;--h:240;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-blue-1 {color: #007dd6}
.a-brd-blue-1  {border-color: #007dd6}
.a-bkg-blue-1 {background-color: #007dd6}
:root {--blue-1: #007dd6}
.a-btn-blue-1 {--hex: #007dd6;--h:205;--s:100%;--l:42%;--btn-color:#ffffff}
.a-font-blue-2 {color: #2770c4}
.a-brd-blue-2  {border-color: #2770c4}
.a-bkg-blue-2 {background-color: #2770c4}
:root {--blue-2: #2770c4}
.a-btn-blue-2 {--hex: #2770c4;--h:212;--s:67%;--l:46%;--btn-color:#ffffff}
.a-font-blue-twitter {color: #1e9cf1}
.a-brd-blue-twitter  {border-color: #1e9cf1}
.a-bkg-blue-twitter {background-color: #1e9cf1}
:root {--blue-twitter: #1e9cf1}
.a-btn-blue-twitter {--hex: #1e9cf1;--h:204;--s:88%;--l:53%;--btn-color:#ffffff}
.a-font-blueviolet {color: #8a2be2}
.a-brd-blueviolet  {border-color: #8a2be2}
.a-bkg-blueviolet {background-color: #8a2be2}
:root {--blueviolet: #8a2be2}
.a-btn-blueviolet {--hex: #8a2be2;--h:271;--s:76%;--l:53%;--btn-color:#ffffff}
.a-font-brown {color: #a52a2a}
.a-brd-brown  {border-color: #a52a2a}
.a-bkg-brown {background-color: #a52a2a}
:root {--brown: #a52a2a}
.a-btn-brown {--hex: #a52a2a;--h:0;--s:59%;--l:41%;--btn-color:#ffffff}
.a-font-bumblebee {color: #fce205}
.a-brd-bumblebee  {border-color: #fce205}
.a-bkg-bumblebee {background-color: #fce205}
:root {--bumblebee: #fce205}
.a-btn-bumblebee {--hex: #fce205;--h:54;--s:98%;--l:50%;--btn-color:#000000}
.a-font-burlywood {color: #deb887}
.a-brd-burlywood  {border-color: #deb887}
.a-bkg-burlywood {background-color: #deb887}
:root {--burlywood: #deb887}
.a-btn-burlywood {--hex: #deb887;--h:34;--s:57%;--l:70%;--btn-color:#000000}
.a-font-cadetblue {color: #5f9ea0}
.a-brd-cadetblue  {border-color: #5f9ea0}
.a-bkg-cadetblue {background-color: #5f9ea0}
:root {--cadetblue: #5f9ea0}
.a-btn-cadetblue {--hex: #5f9ea0;--h:182;--s:25%;--l:50%;--btn-color:#ffffff}
.a-font-chartreuse {color: #7fff00}
.a-brd-chartreuse  {border-color: #7fff00}
.a-bkg-chartreuse {background-color: #7fff00}
:root {--chartreuse: #7fff00}
.a-btn-chartreuse {--hex: #7fff00;--h:90;--s:100%;--l:50%;--btn-color:#000000}
.a-font-chocolate {color: #d2691e}
.a-brd-chocolate  {border-color: #d2691e}
.a-bkg-chocolate {background-color: #d2691e}
:root {--chocolate: #d2691e}
.a-btn-chocolate {--hex: #d2691e;--h:25;--s:75%;--l:47%;--btn-color:#ffffff}
.a-font-coral {color: #ff7f50}
.a-brd-coral  {border-color: #ff7f50}
.a-bkg-coral {background-color: #ff7f50}
:root {--coral: #ff7f50}
.a-btn-coral {--hex: #ff7f50;--h:16;--s:100%;--l:66%;--btn-color:#ffffff}
.a-font-cornflowerblue {color: #6495ed}
.a-brd-cornflowerblue  {border-color: #6495ed}
.a-bkg-cornflowerblue {background-color: #6495ed}
:root {--cornflowerblue: #6495ed}
.a-btn-cornflowerblue {--hex: #6495ed;--h:219;--s:79%;--l:66%;--btn-color:#ffffff}
.a-font-cornsilk {color: #fff8dc}
.a-brd-cornsilk  {border-color: #fff8dc}
.a-bkg-cornsilk {background-color: #fff8dc}
:root {--cornsilk: #fff8dc}
.a-btn-cornsilk {--hex: #fff8dc;--h:48;--s:100%;--l:93%;--btn-color:#000000}
.a-font-crimson {color: #dc143c}
.a-brd-crimson  {border-color: #dc143c}
.a-bkg-crimson {background-color: #dc143c}
:root {--crimson: #dc143c}
.a-btn-crimson {--hex: #dc143c;--h:348;--s:83%;--l:47%;--btn-color:#ffffff}
.a-font-cyan {color: #00ffff}
.a-brd-cyan  {border-color: #00ffff}
.a-bkg-cyan {background-color: #00ffff}
:root {--cyan: #00ffff}
.a-btn-cyan {--hex: #00ffff;--h:180;--s:100%;--l:50%;--btn-color:#000000}
.a-font-cyber {color: #ffd300}
.a-brd-cyber  {border-color: #ffd300}
.a-bkg-cyber {background-color: #ffd300}
:root {--cyber: #ffd300}
.a-btn-cyber {--hex: #ffd300;--h:50;--s:100%;--l:50%;--btn-color:#000000}
.a-font-darkblue {color: #00008b}
.a-brd-darkblue  {border-color: #00008b}
.a-bkg-darkblue {background-color: #00008b}
:root {--darkblue: #00008b}
.a-btn-darkblue {--hex: #00008b;--h:240;--s:100%;--l:27%;--btn-color:#ffffff}
.a-font-darkcyan {color: #008b8b}
.a-brd-darkcyan  {border-color: #008b8b}
.a-bkg-darkcyan {background-color: #008b8b}
:root {--darkcyan: #008b8b}
.a-btn-darkcyan {--hex: #008b8b;--h:180;--s:100%;--l:27%;--btn-color:#ffffff}
.a-font-darkgoldenrod {color: #b8860b}
.a-brd-darkgoldenrod  {border-color: #b8860b}
.a-bkg-darkgoldenrod {background-color: #b8860b}
:root {--darkgoldenrod: #b8860b}
.a-btn-darkgoldenrod {--hex: #b8860b;--h:43;--s:89%;--l:38%;--btn-color:#ffffff}
.a-font-darkgray {color: #a9a9a9}
.a-brd-darkgray  {border-color: #a9a9a9}
.a-bkg-darkgray {background-color: #a9a9a9}
:root {--darkgray: #a9a9a9}
.a-btn-darkgray {--hex: #a9a9a9;--h:0;--s:0%;--l:66%;--btn-color:#ffffff}
.a-font-darkgreen {color: #006400}
.a-brd-darkgreen  {border-color: #006400}
.a-bkg-darkgreen {background-color: #006400}
:root {--darkgreen: #006400}
.a-btn-darkgreen {--hex: #006400;--h:120;--s:100%;--l:20%;--btn-color:#ffffff}
.a-font-darkgrey {color: #a9a9a9}
.a-brd-darkgrey  {border-color: #a9a9a9}
.a-bkg-darkgrey {background-color: #a9a9a9}
:root {--darkgrey: #a9a9a9}
.a-btn-darkgrey {--hex: #a9a9a9;--h:0;--s:0%;--l:66%;--btn-color:#ffffff}
.a-font-darkkhaki {color: #bdb76b}
.a-brd-darkkhaki  {border-color: #bdb76b}
.a-bkg-darkkhaki {background-color: #bdb76b}
:root {--darkkhaki: #bdb76b}
.a-btn-darkkhaki {--hex: #bdb76b;--h:56;--s:38%;--l:58%;--btn-color:#ffffff}
.a-font-darkmagenta {color: #8b008b}
.a-brd-darkmagenta  {border-color: #8b008b}
.a-bkg-darkmagenta {background-color: #8b008b}
:root {--darkmagenta: #8b008b}
.a-btn-darkmagenta {--hex: #8b008b;--h:300;--s:100%;--l:27%;--btn-color:#ffffff}
.a-font-darkolivegreen {color: #556b2f}
.a-brd-darkolivegreen  {border-color: #556b2f}
.a-bkg-darkolivegreen {background-color: #556b2f}
:root {--darkolivegreen: #556b2f}
.a-btn-darkolivegreen {--hex: #556b2f;--h:82;--s:39%;--l:30%;--btn-color:#ffffff}
.a-font-darkorange {color: #ff8c00}
.a-brd-darkorange  {border-color: #ff8c00}
.a-bkg-darkorange {background-color: #ff8c00}
:root {--darkorange: #ff8c00}
.a-btn-darkorange {--hex: #ff8c00;--h:33;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-darkorchid {color: #9932cc}
.a-brd-darkorchid  {border-color: #9932cc}
.a-bkg-darkorchid {background-color: #9932cc}
:root {--darkorchid: #9932cc}
.a-btn-darkorchid {--hex: #9932cc;--h:280;--s:61%;--l:50%;--btn-color:#ffffff}
.a-font-darkred {color: #8b0000}
.a-brd-darkred  {border-color: #8b0000}
.a-bkg-darkred {background-color: #8b0000}
:root {--darkred: #8b0000}
.a-btn-darkred {--hex: #8b0000;--h:0;--s:100%;--l:27%;--btn-color:#ffffff}
.a-font-darksalmon {color: #e9967a}
.a-brd-darksalmon  {border-color: #e9967a}
.a-bkg-darksalmon {background-color: #e9967a}
:root {--darksalmon: #e9967a}
.a-btn-darksalmon {--hex: #e9967a;--h:15;--s:72%;--l:70%;--btn-color:#ffffff}
.a-font-darkseagreen {color: #8fbc8f}
.a-brd-darkseagreen  {border-color: #8fbc8f}
.a-bkg-darkseagreen {background-color: #8fbc8f}
:root {--darkseagreen: #8fbc8f}
.a-btn-darkseagreen {--hex: #8fbc8f;--h:120;--s:25%;--l:65%;--btn-color:#ffffff}
.a-font-darkslateblue {color: #483d8b}
.a-brd-darkslateblue  {border-color: #483d8b}
.a-bkg-darkslateblue {background-color: #483d8b}
:root {--darkslateblue: #483d8b}
.a-btn-darkslateblue {--hex: #483d8b;--h:248;--s:39%;--l:39%;--btn-color:#ffffff}
.a-font-darkslategray {color: #2f4f4f}
.a-brd-darkslategray  {border-color: #2f4f4f}
.a-bkg-darkslategray {background-color: #2f4f4f}
:root {--darkslategray: #2f4f4f}
.a-btn-darkslategray {--hex: #2f4f4f;--h:180;--s:25%;--l:25%;--btn-color:#ffffff}
.a-font-darkslategrey {color: #2f4f4f}
.a-brd-darkslategrey  {border-color: #2f4f4f}
.a-bkg-darkslategrey {background-color: #2f4f4f}
:root {--darkslategrey: #2f4f4f}
.a-btn-darkslategrey {--hex: #2f4f4f;--h:180;--s:25%;--l:25%;--btn-color:#ffffff}
.a-font-darkturquoise {color: #00ced1}
.a-brd-darkturquoise  {border-color: #00ced1}
.a-bkg-darkturquoise {background-color: #00ced1}
:root {--darkturquoise: #00ced1}
.a-btn-darkturquoise {--hex: #00ced1;--h:181;--s:100%;--l:41%;--btn-color:#ffffff}
.a-font-darkviolet {color: #9400d3}
.a-brd-darkviolet  {border-color: #9400d3}
.a-bkg-darkviolet {background-color: #9400d3}
:root {--darkviolet: #9400d3}
.a-btn-darkviolet {--hex: #9400d3;--h:282;--s:100%;--l:41%;--btn-color:#ffffff}
.a-font-deeppink {color: #ff1493}
.a-brd-deeppink  {border-color: #ff1493}
.a-bkg-deeppink {background-color: #ff1493}
:root {--deeppink: #ff1493}
.a-btn-deeppink {--hex: #ff1493;--h:328;--s:100%;--l:54%;--btn-color:#ffffff}
.a-font-deepskyblue {color: #00bfff}
.a-brd-deepskyblue  {border-color: #00bfff}
.a-bkg-deepskyblue {background-color: #00bfff}
:root {--deepskyblue: #00bfff}
.a-btn-deepskyblue {--hex: #00bfff;--h:195;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-dimgray {color: #696969}
.a-brd-dimgray  {border-color: #696969}
.a-bkg-dimgray {background-color: #696969}
:root {--dimgray: #696969}
.a-btn-dimgray {--hex: #696969;--h:0;--s:0%;--l:41%;--btn-color:#ffffff}
.a-font-dimgrey {color: #696969}
.a-brd-dimgrey  {border-color: #696969}
.a-bkg-dimgrey {background-color: #696969}
:root {--dimgrey: #696969}
.a-btn-dimgrey {--hex: #696969;--h:0;--s:0%;--l:41%;--btn-color:#ffffff}
.a-font-dodgerblue {color: #1e90ff}
.a-brd-dodgerblue  {border-color: #1e90ff}
.a-bkg-dodgerblue {background-color: #1e90ff}
:root {--dodgerblue: #1e90ff}
.a-btn-dodgerblue {--hex: #1e90ff;--h:210;--s:100%;--l:56%;--btn-color:#ffffff}
.a-font-fb-blue {color: #4268b3}
.a-brd-fb-blue  {border-color: #4268b3}
.a-bkg-fb-blue {background-color: #4268b3}
:root {--fb-blue: #4268b3}
.a-btn-fb-blue {--hex: #4268b3;--h:220;--s:46%;--l:48%;--btn-color:#ffffff}
.a-font-firebrick {color: #b22222}
.a-brd-firebrick  {border-color: #b22222}
.a-bkg-firebrick {background-color: #b22222}
:root {--firebrick: #b22222}
.a-btn-firebrick {--hex: #b22222;--h:0;--s:68%;--l:42%;--btn-color:#ffffff}
.a-font-floralwhite {color: #fffaf0}
.a-brd-floralwhite  {border-color: #fffaf0}
.a-bkg-floralwhite {background-color: #fffaf0}
:root {--floralwhite: #fffaf0}
.a-btn-floralwhite {--hex: #fffaf0;--h:40;--s:100%;--l:97%;--btn-color:#000000}
.a-font-forest {color: #0c8346}
.a-brd-forest  {border-color: #0c8346}
.a-bkg-forest {background-color: #0c8346}
:root {--forest: #0c8346}
.a-btn-forest {--hex: #0c8346;--h:149;--s:83%;--l:28%;--btn-color:#ffffff}
.a-font-forestgreen {color: #228b22}
.a-brd-forestgreen  {border-color: #228b22}
.a-bkg-forestgreen {background-color: #228b22}
:root {--forestgreen: #228b22}
.a-btn-forestgreen {--hex: #228b22;--h:120;--s:61%;--l:34%;--btn-color:#ffffff}
.a-font-fuchsia {color: #ff00ff}
.a-brd-fuchsia  {border-color: #ff00ff}
.a-bkg-fuchsia {background-color: #ff00ff}
:root {--fuchsia: #ff00ff}
.a-btn-fuchsia {--hex: #ff00ff;--h:300;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-gainsboro {color: #dcdcdc}
.a-brd-gainsboro  {border-color: #dcdcdc}
.a-bkg-gainsboro {background-color: #dcdcdc}
:root {--gainsboro: #dcdcdc}
.a-btn-gainsboro {--hex: #dcdcdc;--h:0;--s:0%;--l:86%;--btn-color:#000000}
.a-font-ghostwhite {color: #f8f8ff}
.a-brd-ghostwhite  {border-color: #f8f8ff}
.a-bkg-ghostwhite {background-color: #f8f8ff}
:root {--ghostwhite: #f8f8ff}
.a-btn-ghostwhite {--hex: #f8f8ff;--h:240;--s:100%;--l:99%;--btn-color:#000000}
.a-font-gold {color: #ffd700}
.a-brd-gold  {border-color: #ffd700}
.a-bkg-gold {background-color: #ffd700}
:root {--gold: #ffd700}
.a-btn-gold {--hex: #ffd700;--h:51;--s:100%;--l:50%;--btn-color:#000000}
.a-font-goldenrod {color: #daa520}
.a-brd-goldenrod  {border-color: #daa520}
.a-bkg-goldenrod {background-color: #daa520}
:root {--goldenrod: #daa520}
.a-btn-goldenrod {--hex: #daa520;--h:43;--s:74%;--l:49%;--btn-color:#ffffff}
.a-font-grape {color: #6529f8}
.a-brd-grape  {border-color: #6529f8}
.a-bkg-grape {background-color: #6529f8}
:root {--grape: #6529f8}
.a-btn-grape {--hex: #6529f8;--h:257;--s:94%;--l:57%;--btn-color:#ffffff}
.a-font-gray {color: #808080}
.a-brd-gray  {border-color: #808080}
.a-bkg-gray {background-color: #808080}
:root {--gray: #808080}
.a-btn-gray {--hex: #808080;--h:0;--s:0%;--l:50%;--btn-color:#ffffff}
.a-font-green {color: #0c9d46}
.a-brd-green  {border-color: #0c9d46}
.a-bkg-green {background-color: #0c9d46}
:root {--green: #0c9d46}
.a-btn-green {--hex: #0c9d46;--h:144;--s:86%;--l:33%;--btn-color:#ffffff}
.a-font-green {color: #008000}
.a-brd-green  {border-color: #008000}
.a-bkg-green {background-color: #008000}
:root {--green: #008000}
.a-btn-green {--hex: #008000;--h:120;--s:100%;--l:25%;--btn-color:#ffffff}
.a-font-green-1 {color: #71ad85}
.a-brd-green-1  {border-color: #71ad85}
.a-bkg-green-1 {background-color: #71ad85}
:root {--green-1: #71ad85}
.a-btn-green-1 {--hex: #71ad85;--h:140;--s:27%;--l:56%;--btn-color:#ffffff}
.a-font-greenyellow {color: #adff2f}
.a-brd-greenyellow  {border-color: #adff2f}
.a-bkg-greenyellow {background-color: #adff2f}
:root {--greenyellow: #adff2f}
.a-btn-greenyellow {--hex: #adff2f;--h:84;--s:100%;--l:59%;--btn-color:#000000}
.a-font-grey {color: #808080}
.a-brd-grey  {border-color: #808080}
.a-bkg-grey {background-color: #808080}
:root {--grey: #808080}
.a-btn-grey {--hex: #808080;--h:0;--s:0%;--l:50%;--btn-color:#ffffff}
.a-font-honey {color: #ffc30b}
.a-brd-honey  {border-color: #ffc30b}
.a-bkg-honey {background-color: #ffc30b}
:root {--honey: #ffc30b}
.a-btn-honey {--hex: #ffc30b;--h:45;--s:100%;--l:52%;--btn-color:#000000}
.a-font-honeydew {color: #f0fff0}
.a-brd-honeydew  {border-color: #f0fff0}
.a-bkg-honeydew {background-color: #f0fff0}
:root {--honeydew: #f0fff0}
.a-btn-honeydew {--hex: #f0fff0;--h:120;--s:100%;--l:97%;--btn-color:#000000}
.a-font-hotpink {color: #ff69b4}
.a-brd-hotpink  {border-color: #ff69b4}
.a-bkg-hotpink {background-color: #ff69b4}
:root {--hotpink: #ff69b4}
.a-btn-hotpink {--hex: #ff69b4;--h:330;--s:100%;--l:71%;--btn-color:#ffffff}
.a-font-indianred {color: #cd5c5c}
.a-brd-indianred  {border-color: #cd5c5c}
.a-bkg-indianred {background-color: #cd5c5c}
:root {--indianred: #cd5c5c}
.a-btn-indianred {--hex: #cd5c5c;--h:0;--s:53%;--l:58%;--btn-color:#ffffff}
.a-font-indigo {color: #622aff}
.a-brd-indigo  {border-color: #622aff}
.a-bkg-indigo {background-color: #622aff}
:root {--indigo: #622aff}
.a-btn-indigo {--hex: #622aff;--h:256;--s:100%;--l:58%;--btn-color:#ffffff}
.a-font-indigo {color: #4b0082}
.a-brd-indigo  {border-color: #4b0082}
.a-bkg-indigo {background-color: #4b0082}
:root {--indigo: #4b0082}
.a-btn-indigo {--hex: #4b0082;--h:275;--s:100%;--l:25%;--btn-color:#ffffff}
.a-font-ivory {color: #fffff0}
.a-brd-ivory  {border-color: #fffff0}
.a-bkg-ivory {background-color: #fffff0}
:root {--ivory: #fffff0}
.a-btn-ivory {--hex: #fffff0;--h:60;--s:100%;--l:97%;--btn-color:#000000}
.a-font-khaki {color: #f0e68c}
.a-brd-khaki  {border-color: #f0e68c}
.a-bkg-khaki {background-color: #f0e68c}
:root {--khaki: #f0e68c}
.a-btn-khaki {--hex: #f0e68c;--h:54;--s:77%;--l:75%;--btn-color:#000000}
.a-font-lavender {color: #e6e6fa}
.a-brd-lavender  {border-color: #e6e6fa}
.a-bkg-lavender {background-color: #e6e6fa}
:root {--lavender: #e6e6fa}
.a-btn-lavender {--hex: #e6e6fa;--h:240;--s:67%;--l:94%;--btn-color:#000000}
.a-font-lavenderblush {color: #fff0f5}
.a-brd-lavenderblush  {border-color: #fff0f5}
.a-bkg-lavenderblush {background-color: #fff0f5}
:root {--lavenderblush: #fff0f5}
.a-btn-lavenderblush {--hex: #fff0f5;--h:340;--s:100%;--l:97%;--btn-color:#000000}
.a-font-lawngreen {color: #7cfc00}
.a-brd-lawngreen  {border-color: #7cfc00}
.a-bkg-lawngreen {background-color: #7cfc00}
:root {--lawngreen: #7cfc00}
.a-btn-lawngreen {--hex: #7cfc00;--h:90;--s:100%;--l:49%;--btn-color:#000000}
.a-font-lemon {color: #effd5f}
.a-brd-lemon  {border-color: #effd5f}
.a-bkg-lemon {background-color: #effd5f}
:root {--lemon: #effd5f}
.a-btn-lemon {--hex: #effd5f;--h:65;--s:98%;--l:68%;--btn-color:#000000}
.a-font-lemonchiffon {color: #fffacd}
.a-brd-lemonchiffon  {border-color: #fffacd}
.a-bkg-lemonchiffon {background-color: #fffacd}
:root {--lemonchiffon: #fffacd}
.a-btn-lemonchiffon {--hex: #fffacd;--h:54;--s:100%;--l:90%;--btn-color:#000000}
.a-font-lightblue {color: #add8e6}
.a-brd-lightblue  {border-color: #add8e6}
.a-bkg-lightblue {background-color: #add8e6}
:root {--lightblue: #add8e6}
.a-btn-lightblue {--hex: #add8e6;--h:195;--s:53%;--l:79%;--btn-color:#000000}
.a-font-lightcoral {color: #f08080}
.a-brd-lightcoral  {border-color: #f08080}
.a-bkg-lightcoral {background-color: #f08080}
:root {--lightcoral: #f08080}
.a-btn-lightcoral {--hex: #f08080;--h:0;--s:79%;--l:72%;--btn-color:#ffffff}
.a-font-lightcyan {color: #e0ffff}
.a-brd-lightcyan  {border-color: #e0ffff}
.a-bkg-lightcyan {background-color: #e0ffff}
:root {--lightcyan: #e0ffff}
.a-btn-lightcyan {--hex: #e0ffff;--h:180;--s:100%;--l:94%;--btn-color:#000000}
.a-font-lightgoldenrodyellow {color: #fafad2}
.a-brd-lightgoldenrodyellow  {border-color: #fafad2}
.a-bkg-lightgoldenrodyellow {background-color: #fafad2}
:root {--lightgoldenrodyellow: #fafad2}
.a-btn-lightgoldenrodyellow {--hex: #fafad2;--h:60;--s:80%;--l:90%;--btn-color:#000000}
.a-font-lightgray {color: #d3d3d3}
.a-brd-lightgray  {border-color: #d3d3d3}
.a-bkg-lightgray {background-color: #d3d3d3}
:root {--lightgray: #d3d3d3}
.a-btn-lightgray {--hex: #d3d3d3;--h:0;--s:0%;--l:83%;--btn-color:#000000}
.a-font-lightgreen {color: #90ee90}
.a-brd-lightgreen  {border-color: #90ee90}
.a-bkg-lightgreen {background-color: #90ee90}
:root {--lightgreen: #90ee90}
.a-btn-lightgreen {--hex: #90ee90;--h:120;--s:73%;--l:75%;--btn-color:#000000}
.a-font-lightgrey {color: #d3d3d3}
.a-brd-lightgrey  {border-color: #d3d3d3}
.a-bkg-lightgrey {background-color: #d3d3d3}
:root {--lightgrey: #d3d3d3}
.a-btn-lightgrey {--hex: #d3d3d3;--h:0;--s:0%;--l:83%;--btn-color:#000000}
.a-font-lightpink {color: #ffb6c1}
.a-brd-lightpink  {border-color: #ffb6c1}
.a-bkg-lightpink {background-color: #ffb6c1}
:root {--lightpink: #ffb6c1}
.a-btn-lightpink {--hex: #ffb6c1;--h:351;--s:100%;--l:86%;--btn-color:#000000}
.a-font-lightsalmon {color: #ffa07a}
.a-brd-lightsalmon  {border-color: #ffa07a}
.a-bkg-lightsalmon {background-color: #ffa07a}
:root {--lightsalmon: #ffa07a}
.a-btn-lightsalmon {--hex: #ffa07a;--h:17;--s:100%;--l:74%;--btn-color:#000000}
.a-font-lightseagreen {color: #20b2aa}
.a-brd-lightseagreen  {border-color: #20b2aa}
.a-bkg-lightseagreen {background-color: #20b2aa}
:root {--lightseagreen: #20b2aa}
.a-btn-lightseagreen {--hex: #20b2aa;--h:177;--s:70%;--l:41%;--btn-color:#ffffff}
.a-font-lightskyblue {color: #87cefa}
.a-brd-lightskyblue  {border-color: #87cefa}
.a-bkg-lightskyblue {background-color: #87cefa}
:root {--lightskyblue: #87cefa}
.a-btn-lightskyblue {--hex: #87cefa;--h:203;--s:92%;--l:75%;--btn-color:#000000}
.a-font-lightslategray {color: #778899}
.a-brd-lightslategray  {border-color: #778899}
.a-bkg-lightslategray {background-color: #778899}
:root {--lightslategray: #778899}
.a-btn-lightslategray {--hex: #778899;--h:210;--s:14%;--l:53%;--btn-color:#ffffff}
.a-font-lightslategrey {color: #778899}
.a-brd-lightslategrey  {border-color: #778899}
.a-bkg-lightslategrey {background-color: #778899}
:root {--lightslategrey: #778899}
.a-btn-lightslategrey {--hex: #778899;--h:210;--s:14%;--l:53%;--btn-color:#ffffff}
.a-font-lightsteelblue {color: #b0c4de}
.a-brd-lightsteelblue  {border-color: #b0c4de}
.a-bkg-lightsteelblue {background-color: #b0c4de}
:root {--lightsteelblue: #b0c4de}
.a-btn-lightsteelblue {--hex: #b0c4de;--h:214;--s:41%;--l:78%;--btn-color:#000000}
.a-font-lightyellow {color: #ffffe0}
.a-brd-lightyellow  {border-color: #ffffe0}
.a-bkg-lightyellow {background-color: #ffffe0}
:root {--lightyellow: #ffffe0}
.a-btn-lightyellow {--hex: #ffffe0;--h:60;--s:100%;--l:94%;--btn-color:#000000}
.a-font-lime {color: #00ff00}
.a-brd-lime  {border-color: #00ff00}
.a-bkg-lime {background-color: #00ff00}
:root {--lime: #00ff00}
.a-btn-lime {--hex: #00ff00;--h:120;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-limegreen {color: #32cd32}
.a-brd-limegreen  {border-color: #32cd32}
.a-bkg-limegreen {background-color: #32cd32}
:root {--limegreen: #32cd32}
.a-btn-limegreen {--hex: #32cd32;--h:120;--s:61%;--l:50%;--btn-color:#ffffff}
.a-font-linen {color: #faf0e6}
.a-brd-linen  {border-color: #faf0e6}
.a-bkg-linen {background-color: #faf0e6}
:root {--linen: #faf0e6}
.a-btn-linen {--hex: #faf0e6;--h:30;--s:67%;--l:94%;--btn-color:#000000}
.a-font-magenta {color: #d71bce}
.a-brd-magenta  {border-color: #d71bce}
.a-bkg-magenta {background-color: #d71bce}
:root {--magenta: #d71bce}
.a-btn-magenta {--hex: #d71bce;--h:303;--s:78%;--l:47%;--btn-color:#ffffff}
.a-font-magenta {color: #ff00ff}
.a-brd-magenta  {border-color: #ff00ff}
.a-bkg-magenta {background-color: #ff00ff}
:root {--magenta: #ff00ff}
.a-btn-magenta {--hex: #ff00ff;--h:300;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-maroon {color: #800000}
.a-brd-maroon  {border-color: #800000}
.a-bkg-maroon {background-color: #800000}
:root {--maroon: #800000}
.a-btn-maroon {--hex: #800000;--h:0;--s:100%;--l:25%;--btn-color:#ffffff}
.a-font-mediumaquamarine {color: #66cdaa}
.a-brd-mediumaquamarine  {border-color: #66cdaa}
.a-bkg-mediumaquamarine {background-color: #66cdaa}
:root {--mediumaquamarine: #66cdaa}
.a-btn-mediumaquamarine {--hex: #66cdaa;--h:160;--s:51%;--l:60%;--btn-color:#ffffff}
.a-font-mediumblue {color: #0000cd}
.a-brd-mediumblue  {border-color: #0000cd}
.a-bkg-mediumblue {background-color: #0000cd}
:root {--mediumblue: #0000cd}
.a-btn-mediumblue {--hex: #0000cd;--h:240;--s:100%;--l:40%;--btn-color:#ffffff}
.a-font-mediumorchid {color: #ba55d3}
.a-brd-mediumorchid  {border-color: #ba55d3}
.a-bkg-mediumorchid {background-color: #ba55d3}
:root {--mediumorchid: #ba55d3}
.a-btn-mediumorchid {--hex: #ba55d3;--h:288;--s:59%;--l:58%;--btn-color:#ffffff}
.a-font-mediumpurple {color: #9370db}
.a-brd-mediumpurple  {border-color: #9370db}
.a-bkg-mediumpurple {background-color: #9370db}
:root {--mediumpurple: #9370db}
.a-btn-mediumpurple {--hex: #9370db;--h:260;--s:60%;--l:65%;--btn-color:#ffffff}
.a-font-mediumseagreen {color: #3cb371}
.a-brd-mediumseagreen  {border-color: #3cb371}
.a-bkg-mediumseagreen {background-color: #3cb371}
:root {--mediumseagreen: #3cb371}
.a-btn-mediumseagreen {--hex: #3cb371;--h:147;--s:50%;--l:47%;--btn-color:#ffffff}
.a-font-mediumslateblue {color: #7b68ee}
.a-brd-mediumslateblue  {border-color: #7b68ee}
.a-bkg-mediumslateblue {background-color: #7b68ee}
:root {--mediumslateblue: #7b68ee}
.a-btn-mediumslateblue {--hex: #7b68ee;--h:249;--s:80%;--l:67%;--btn-color:#ffffff}
.a-font-mediumspringgreen {color: #00fa9a}
.a-brd-mediumspringgreen  {border-color: #00fa9a}
.a-bkg-mediumspringgreen {background-color: #00fa9a}
:root {--mediumspringgreen: #00fa9a}
.a-btn-mediumspringgreen {--hex: #00fa9a;--h:157;--s:100%;--l:49%;--btn-color:#ffffff}
.a-font-mediumturquoise {color: #48d1cc}
.a-brd-mediumturquoise  {border-color: #48d1cc}
.a-bkg-mediumturquoise {background-color: #48d1cc}
:root {--mediumturquoise: #48d1cc}
.a-btn-mediumturquoise {--hex: #48d1cc;--h:178;--s:60%;--l:55%;--btn-color:#ffffff}
.a-font-mediumvioletred {color: #c71585}
.a-brd-mediumvioletred  {border-color: #c71585}
.a-bkg-mediumvioletred {background-color: #c71585}
:root {--mediumvioletred: #c71585}
.a-btn-mediumvioletred {--hex: #c71585;--h:322;--s:81%;--l:43%;--btn-color:#ffffff}
.a-font-midnight {color: #1b23b3}
.a-brd-midnight  {border-color: #1b23b3}
.a-bkg-midnight {background-color: #1b23b3}
:root {--midnight: #1b23b3}
.a-btn-midnight {--hex: #1b23b3;--h:237;--s:74%;--l:40%;--btn-color:#ffffff}
.a-font-midnightblue {color: #191970}
.a-brd-midnightblue  {border-color: #191970}
.a-bkg-midnightblue {background-color: #191970}
:root {--midnightblue: #191970}
.a-btn-midnightblue {--hex: #191970;--h:240;--s:64%;--l:27%;--btn-color:#ffffff}
.a-font-mintcream {color: #f5fffa}
.a-brd-mintcream  {border-color: #f5fffa}
.a-bkg-mintcream {background-color: #f5fffa}
:root {--mintcream: #f5fffa}
.a-btn-mintcream {--hex: #f5fffa;--h:150;--s:100%;--l:98%;--btn-color:#000000}
.a-font-mistyrose {color: #ffe4e1}
.a-brd-mistyrose  {border-color: #ffe4e1}
.a-bkg-mistyrose {background-color: #ffe4e1}
:root {--mistyrose: #ffe4e1}
.a-btn-mistyrose {--hex: #ffe4e1;--h:6;--s:100%;--l:94%;--btn-color:#000000}
.a-font-moccasin {color: #ffe4b5}
.a-brd-moccasin  {border-color: #ffe4b5}
.a-bkg-moccasin {background-color: #ffe4b5}
:root {--moccasin: #ffe4b5}
.a-btn-moccasin {--hex: #ffe4b5;--h:38;--s:100%;--l:85%;--btn-color:#000000}
.a-font-moonstone {color: #2294b1}
.a-brd-moonstone  {border-color: #2294b1}
.a-bkg-moonstone {background-color: #2294b1}
:root {--moonstone: #2294b1}
.a-btn-moonstone {--hex: #2294b1;--h:192;--s:68%;--l:41%;--btn-color:#ffffff}
.a-font-navajowhite {color: #ffdead}
.a-brd-navajowhite  {border-color: #ffdead}
.a-bkg-navajowhite {background-color: #ffdead}
:root {--navajowhite: #ffdead}
.a-btn-navajowhite {--hex: #ffdead;--h:36;--s:100%;--l:84%;--btn-color:#000000}
.a-font-navy {color: #000080}
.a-brd-navy  {border-color: #000080}
.a-bkg-navy {background-color: #000080}
:root {--navy: #000080}
.a-btn-navy {--hex: #000080;--h:240;--s:100%;--l:25%;--btn-color:#ffffff}
.a-font-new-color-#3f8963 {color: #3f8963}
.a-brd-new-color-#3f8963  {border-color: #3f8963}
.a-bkg-new-color-#3f8963 {background-color: #3f8963}
:root {--new-color-#3f8963: #3f8963}
.a-btn-new-color-#3f8963 {--hex: #3f8963;--h:149;--s:37%;--l:39%;--btn-color:#ffffff}
.a-font-oldlace {color: #fdf5e6}
.a-brd-oldlace  {border-color: #fdf5e6}
.a-bkg-oldlace {background-color: #fdf5e6}
:root {--oldlace: #fdf5e6}
.a-btn-oldlace {--hex: #fdf5e6;--h:39;--s:85%;--l:95%;--btn-color:#000000}
.a-font-olive {color: #808000}
.a-brd-olive  {border-color: #808000}
.a-bkg-olive {background-color: #808000}
:root {--olive: #808000}
.a-btn-olive {--hex: #808000;--h:60;--s:100%;--l:25%;--btn-color:#ffffff}
.a-font-olivedrab {color: #6b8e23}
.a-brd-olivedrab  {border-color: #6b8e23}
.a-bkg-olivedrab {background-color: #6b8e23}
:root {--olivedrab: #6b8e23}
.a-btn-olivedrab {--hex: #6b8e23;--h:80;--s:60%;--l:35%;--btn-color:#ffffff}
.a-font-orange {color: #e66702}
.a-brd-orange  {border-color: #e66702}
.a-bkg-orange {background-color: #e66702}
:root {--orange: #e66702}
.a-btn-orange {--hex: #e66702;--h:27;--s:98%;--l:45%;--btn-color:#ffffff}
.a-font-orange {color: #ffa500}
.a-brd-orange  {border-color: #ffa500}
.a-bkg-orange {background-color: #ffa500}
:root {--orange: #ffa500}
.a-btn-orange {--hex: #ffa500;--h:39;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-orange-1 {color: #f49106}
.a-brd-orange-1  {border-color: #f49106}
.a-bkg-orange-1 {background-color: #f49106}
:root {--orange-1: #f49106}
.a-btn-orange-1 {--hex: #f49106;--h:35;--s:95%;--l:49%;--btn-color:#ffffff}
.a-font-orange-2 {color: #ff5e00}
.a-brd-orange-2  {border-color: #ff5e00}
.a-bkg-orange-2 {background-color: #ff5e00}
:root {--orange-2: #ff5e00}
.a-btn-orange-2 {--hex: #ff5e00;--h:22;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-orangered {color: #ff4500}
.a-brd-orangered  {border-color: #ff4500}
.a-bkg-orangered {background-color: #ff4500}
:root {--orangered: #ff4500}
.a-btn-orangered {--hex: #ff4500;--h:16;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-orchid {color: #da70d6}
.a-brd-orchid  {border-color: #da70d6}
.a-bkg-orchid {background-color: #da70d6}
:root {--orchid: #da70d6}
.a-btn-orchid {--hex: #da70d6;--h:302;--s:59%;--l:65%;--btn-color:#ffffff}
.a-font-palegoldenrod {color: #eee8aa}
.a-brd-palegoldenrod  {border-color: #eee8aa}
.a-bkg-palegoldenrod {background-color: #eee8aa}
:root {--palegoldenrod: #eee8aa}
.a-btn-palegoldenrod {--hex: #eee8aa;--h:55;--s:67%;--l:80%;--btn-color:#000000}
.a-font-palegreen {color: #98fb98}
.a-brd-palegreen  {border-color: #98fb98}
.a-bkg-palegreen {background-color: #98fb98}
:root {--palegreen: #98fb98}
.a-btn-palegreen {--hex: #98fb98;--h:120;--s:93%;--l:79%;--btn-color:#000000}
.a-font-paleturquoise {color: #afeeee}
.a-brd-paleturquoise  {border-color: #afeeee}
.a-bkg-paleturquoise {background-color: #afeeee}
:root {--paleturquoise: #afeeee}
.a-btn-paleturquoise {--hex: #afeeee;--h:180;--s:65%;--l:81%;--btn-color:#000000}
.a-font-palevioletred {color: #db7093}
.a-brd-palevioletred  {border-color: #db7093}
.a-bkg-palevioletred {background-color: #db7093}
:root {--palevioletred: #db7093}
.a-btn-palevioletred {--hex: #db7093;--h:340;--s:60%;--l:65%;--btn-color:#ffffff}
.a-font-papayawhip {color: #ffefd5}
.a-brd-papayawhip  {border-color: #ffefd5}
.a-bkg-papayawhip {background-color: #ffefd5}
:root {--papayawhip: #ffefd5}
.a-btn-papayawhip {--hex: #ffefd5;--h:37;--s:100%;--l:92%;--btn-color:#000000}
.a-font-peachpuff {color: #ffdab9}
.a-brd-peachpuff  {border-color: #ffdab9}
.a-bkg-peachpuff {background-color: #ffdab9}
:root {--peachpuff: #ffdab9}
.a-btn-peachpuff {--hex: #ffdab9;--h:28;--s:100%;--l:86%;--btn-color:#000000}
.a-font-peru {color: #cd853f}
.a-brd-peru  {border-color: #cd853f}
.a-bkg-peru {background-color: #cd853f}
:root {--peru: #cd853f}
.a-btn-peru {--hex: #cd853f;--h:30;--s:59%;--l:53%;--btn-color:#ffffff}
.a-font-pink {color: #ffc0cb}
.a-brd-pink  {border-color: #ffc0cb}
.a-bkg-pink {background-color: #ffc0cb}
:root {--pink: #ffc0cb}
.a-btn-pink {--hex: #ffc0cb;--h:350;--s:100%;--l:88%;--btn-color:#000000}
.a-font-plum {color: #dda0dd}
.a-brd-plum  {border-color: #dda0dd}
.a-bkg-plum {background-color: #dda0dd}
:root {--plum: #dda0dd}
.a-btn-plum {--hex: #dda0dd;--h:300;--s:47%;--l:75%;--btn-color:#000000}
.a-font-powderblue {color: #b0e0e6}
.a-brd-powderblue  {border-color: #b0e0e6}
.a-bkg-powderblue {background-color: #b0e0e6}
:root {--powderblue: #b0e0e6}
.a-btn-powderblue {--hex: #b0e0e6;--h:187;--s:52%;--l:80%;--btn-color:#000000}
.a-font-purple {color: #800080}
.a-brd-purple  {border-color: #800080}
.a-bkg-purple {background-color: #800080}
:root {--purple: #800080}
.a-btn-purple {--hex: #800080;--h:300;--s:100%;--l:25%;--btn-color:#ffffff}
.a-font-rebeccapurple {color: #663399}
.a-brd-rebeccapurple  {border-color: #663399}
.a-bkg-rebeccapurple {background-color: #663399}
:root {--rebeccapurple: #663399}
.a-btn-rebeccapurple {--hex: #663399;--h:270;--s:50%;--l:40%;--btn-color:#ffffff}
.a-font-red {color: #ff0000}
.a-brd-red  {border-color: #ff0000}
.a-bkg-red {background-color: #ff0000}
:root {--red: #ff0000}
.a-btn-red {--hex: #ff0000;--h:0;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-rosybrown {color: #bc8f8f}
.a-brd-rosybrown  {border-color: #bc8f8f}
.a-bkg-rosybrown {background-color: #bc8f8f}
:root {--rosybrown: #bc8f8f}
.a-btn-rosybrown {--hex: #bc8f8f;--h:0;--s:25%;--l:65%;--btn-color:#ffffff}
.a-font-royalblue {color: #4169e1}
.a-brd-royalblue  {border-color: #4169e1}
.a-bkg-royalblue {background-color: #4169e1}
:root {--royalblue: #4169e1}
.a-btn-royalblue {--hex: #4169e1;--h:225;--s:73%;--l:57%;--btn-color:#ffffff}
.a-font-saddlebrown {color: #8b4513}
.a-brd-saddlebrown  {border-color: #8b4513}
.a-bkg-saddlebrown {background-color: #8b4513}
:root {--saddlebrown: #8b4513}
.a-btn-saddlebrown {--hex: #8b4513;--h:25;--s:76%;--l:31%;--btn-color:#ffffff}
.a-font-salmon {color: #fa8072}
.a-brd-salmon  {border-color: #fa8072}
.a-bkg-salmon {background-color: #fa8072}
:root {--salmon: #fa8072}
.a-btn-salmon {--hex: #fa8072;--h:6;--s:93%;--l:71%;--btn-color:#ffffff}
.a-font-sandybrown {color: #f4a460}
.a-brd-sandybrown  {border-color: #f4a460}
.a-bkg-sandybrown {background-color: #f4a460}
:root {--sandybrown: #f4a460}
.a-btn-sandybrown {--hex: #f4a460;--h:28;--s:87%;--l:67%;--btn-color:#000000}
.a-font-sapphire {color: #3d44c3}
.a-brd-sapphire  {border-color: #3d44c3}
.a-bkg-sapphire {background-color: #3d44c3}
:root {--sapphire: #3d44c3}
.a-btn-sapphire {--hex: #3d44c3;--h:237;--s:53%;--l:50%;--btn-color:#ffffff}
.a-font-sea-green {color: #099b9d}
.a-brd-sea-green  {border-color: #099b9d}
.a-bkg-sea-green {background-color: #099b9d}
:root {--sea-green: #099b9d}
.a-btn-sea-green {--hex: #099b9d;--h:181;--s:89%;--l:33%;--btn-color:#ffffff}
.a-font-seagreen {color: #2e8b57}
.a-brd-seagreen  {border-color: #2e8b57}
.a-bkg-seagreen {background-color: #2e8b57}
:root {--seagreen: #2e8b57}
.a-btn-seagreen {--hex: #2e8b57;--h:146;--s:50%;--l:36%;--btn-color:#ffffff}
.a-font-seashell {color: #fff5ee}
.a-brd-seashell  {border-color: #fff5ee}
.a-bkg-seashell {background-color: #fff5ee}
:root {--seashell: #fff5ee}
.a-btn-seashell {--hex: #fff5ee;--h:25;--s:100%;--l:97%;--btn-color:#000000}
.a-font-sienna {color: #a0522d}
.a-brd-sienna  {border-color: #a0522d}
.a-bkg-sienna {background-color: #a0522d}
:root {--sienna: #a0522d}
.a-btn-sienna {--hex: #a0522d;--h:19;--s:56%;--l:40%;--btn-color:#ffffff}
.a-font-silver {color: #c0c0c0}
.a-brd-silver  {border-color: #c0c0c0}
.a-bkg-silver {background-color: #c0c0c0}
:root {--silver: #c0c0c0}
.a-btn-silver {--hex: #c0c0c0;--h:0;--s:0%;--l:75%;--btn-color:#000000}
.a-font-skyblue {color: #87ceeb}
.a-brd-skyblue  {border-color: #87ceeb}
.a-bkg-skyblue {background-color: #87ceeb}
:root {--skyblue: #87ceeb}
.a-btn-skyblue {--hex: #87ceeb;--h:197;--s:71%;--l:73%;--btn-color:#000000}
.a-font-slateblue {color: #6a5acd}
.a-brd-slateblue  {border-color: #6a5acd}
.a-bkg-slateblue {background-color: #6a5acd}
:root {--slateblue: #6a5acd}
.a-btn-slateblue {--hex: #6a5acd;--h:248;--s:53%;--l:58%;--btn-color:#ffffff}
.a-font-slategray {color: #708090}
.a-brd-slategray  {border-color: #708090}
.a-bkg-slategray {background-color: #708090}
:root {--slategray: #708090}
.a-btn-slategray {--hex: #708090;--h:210;--s:13%;--l:50%;--btn-color:#ffffff}
.a-font-slategrey {color: #708090}
.a-brd-slategrey  {border-color: #708090}
.a-bkg-slategrey {background-color: #708090}
:root {--slategrey: #708090}
.a-btn-slategrey {--hex: #708090;--h:210;--s:13%;--l:50%;--btn-color:#ffffff}
.a-font-snow {color: #fffafa}
.a-brd-snow  {border-color: #fffafa}
.a-bkg-snow {background-color: #fffafa}
:root {--snow: #fffafa}
.a-btn-snow {--hex: #fffafa;--h:0;--s:100%;--l:99%;--btn-color:#000000}
.a-font-springgreen {color: #00ff7f}
.a-brd-springgreen  {border-color: #00ff7f}
.a-bkg-springgreen {background-color: #00ff7f}
:root {--springgreen: #00ff7f}
.a-btn-springgreen {--hex: #00ff7f;--h:150;--s:100%;--l:50%;--btn-color:#ffffff}
.a-font-steelblue {color: #4682b4}
.a-brd-steelblue  {border-color: #4682b4}
.a-bkg-steelblue {background-color: #4682b4}
:root {--steelblue: #4682b4}
.a-btn-steelblue {--hex: #4682b4;--h:207;--s:44%;--l:49%;--btn-color:#ffffff}
.a-font-tan {color: #d2b48c}
.a-brd-tan  {border-color: #d2b48c}
.a-bkg-tan {background-color: #d2b48c}
:root {--tan: #d2b48c}
.a-btn-tan {--hex: #d2b48c;--h:34;--s:44%;--l:69%;--btn-color:#000000}
.a-font-teal {color: #049cb7}
.a-brd-teal  {border-color: #049cb7}
.a-bkg-teal {background-color: #049cb7}
:root {--teal: #049cb7}
.a-btn-teal {--hex: #049cb7;--h:189;--s:96%;--l:37%;--btn-color:#ffffff}
.a-font-teal {color: #008080}
.a-brd-teal  {border-color: #008080}
.a-bkg-teal {background-color: #008080}
:root {--teal: #008080}
.a-btn-teal {--hex: #008080;--h:180;--s:100%;--l:25%;--btn-color:#ffffff}
.a-font-thistle {color: #d8bfd8}
.a-brd-thistle  {border-color: #d8bfd8}
.a-bkg-thistle {background-color: #d8bfd8}
:root {--thistle: #d8bfd8}
.a-btn-thistle {--hex: #d8bfd8;--h:300;--s:24%;--l:80%;--btn-color:#000000}
.a-font-tomato {color: #f63e54}
.a-brd-tomato  {border-color: #f63e54}
.a-bkg-tomato {background-color: #f63e54}
:root {--tomato: #f63e54}
.a-btn-tomato {--hex: #f63e54;--h:353;--s:91%;--l:60%;--btn-color:#ffffff}
.a-font-tomato {color: #ff6347}
.a-brd-tomato  {border-color: #ff6347}
.a-bkg-tomato {background-color: #ff6347}
:root {--tomato: #ff6347}
.a-btn-tomato {--hex: #ff6347;--h:9;--s:100%;--l:64%;--btn-color:#ffffff}
.a-font-turquoise {color: #40e0d0}
.a-brd-turquoise  {border-color: #40e0d0}
.a-bkg-turquoise {background-color: #40e0d0}
:root {--turquoise: #40e0d0}
.a-btn-turquoise {--hex: #40e0d0;--h:174;--s:72%;--l:56%;--btn-color:#ffffff}
.a-font-ukraine-flag-blue {color: #0056b8}
.a-brd-ukraine-flag-blue  {border-color: #0056b8}
.a-bkg-ukraine-flag-blue {background-color: #0056b8}
:root {--ukraine-flag-blue: #0056b8}
.a-btn-ukraine-flag-blue {--hex: #0056b8;--h:212;--s:100%;--l:36%;--btn-color:#FFD700}
.a-btn-ukraine-flag-blue-only-text {--btn-color:#FFD700}
.a-font-violet {color: #8876fe}
.a-brd-violet  {border-color: #8876fe}
.a-bkg-violet {background-color: #8876fe}
:root {--violet: #8876fe}
.a-btn-violet {--hex: #8876fe;--h:248;--s:99%;--l:73%;--btn-color:#ffffff}
.a-font-violet {color: #ee82ee}
.a-brd-violet  {border-color: #ee82ee}
.a-bkg-violet {background-color: #ee82ee}
:root {--violet: #ee82ee}
.a-btn-violet {--hex: #ee82ee;--h:300;--s:76%;--l:72%;--btn-color:#ffffff}
.a-font-whatsapp {color: #25d466}
.a-brd-whatsapp  {border-color: #25d466}
.a-bkg-whatsapp {background-color: #25d466}
:root {--whatsapp: #25d466}
.a-btn-whatsapp {--hex: #25d466;--h:142;--s:70%;--l:49%;--btn-color:#ffffff}
.a-font-wheat {color: #f5deb3}
.a-brd-wheat  {border-color: #f5deb3}
.a-bkg-wheat {background-color: #f5deb3}
:root {--wheat: #f5deb3}
.a-btn-wheat {--hex: #f5deb3;--h:39;--s:77%;--l:83%;--btn-color:#000000}
.a-font-white {color: #ffffff}
.a-brd-white  {border-color: #ffffff}
.a-bkg-white {background-color: #ffffff}
:root {--white: #ffffff}
.a-btn-white {--hex: #ffffff;--h:0;--s:0%;--l:100%;--btn-color:#000000}
.a-font-whitesmoke {color: #f5f5f5}
.a-brd-whitesmoke  {border-color: #f5f5f5}
.a-bkg-whitesmoke {background-color: #f5f5f5}
:root {--whitesmoke: #f5f5f5}
.a-btn-whitesmoke {--hex: #f5f5f5;--h:0;--s:0%;--l:96%;--btn-color:#000000}
.a-font-yellow {color: #ffff00}
.a-brd-yellow  {border-color: #ffff00}
.a-bkg-yellow {background-color: #ffff00}
:root {--yellow: #ffff00}
.a-btn-yellow {--hex: #ffff00;--h:60;--s:100%;--l:50%;--btn-color:#000000}
.a-font-yellowgreen {color: #9acd32}
.a-brd-yellowgreen  {border-color: #9acd32}
.a-bkg-yellowgreen {background-color: #9acd32}
:root {--yellowgreen: #9acd32}
.a-btn-yellowgreen {--hex: #9acd32;--h:80;--s:61%;--l:50%;--btn-color:#ffffff}



/*

gradient orange: linear-gradient(25deg, rgb(243, 128, 32), rgb(248, 173, 76))

const iconColors = [["default", null],["violet","#8876fe"], ["magenta", "#d71bce"],["indigo","#622aff"],["grape", "#6529f8"],  ["azure", "#027fff"],["tomato", "#f63e54"],
["orange", "#e66702"],["green", "#0c9d46"],["forest", "#0c8346"],["sea-green", "#099b9d"],  ["teal", "#049cb7"],["aqua", "#039e8f"],  ["moonstone", "#2294b1"],
["midnight", "#1b23b3"],  ["sapphire","#3d44c3"]];

*/
