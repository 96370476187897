.a-btn { border-radius: calc(var(--brdRadius, 0.1) * var(--height));
  height: var(--height);  letter-spacing: 0.5px;}
.a-btn.icon-only {padding: 0 calc((var(--height) - var(--iconSize) - 2 * var(--brdSize, 0px) - (var(--icon-lPad, 0) * var(--iconSize))  - (var(--icon-rPad, 0) * var(--iconSize)))  / 2);}
.a-btn-icon-container {width: calc(var(--iconSize) + (var(--icon-lPad, 0) * var(--iconSize)) + (var(--icon-rPad, 0) * var(--iconSize))); height: calc(var(--iconSize) + var(--icon-tPad, 0) * var(--iconSize) + var(--icon-bPad, 0) * var(--iconSize));
    padding: calc(var(--icon-tPad, 0) * var(--iconSize)) calc(var(--icon-rPad, 0) * var(--iconSize)) calc(var(--icon-bPad, 0) * var(--iconSize)) calc(var(--icon-lPad, 0) * var(--iconSize))}

/* Legacy css */
.a-btn {padding-left: var(--btnContainer); padding-right: var(--btnContainer);font-size: var(--btnSize);}
/* Legacy css */

.an-icon-lPad-2 {--icon-lPad: 0.02; }.an-icon-lPad-4 {--icon-lPad: 0.04; }.an-icon-lPad-6 {--icon-lPad: 0.06; }.an-icon-lPad-8 {--icon-lPad: 0.08; }
.an-icon-lPad-10 {--icon-lPad: 0.1; }.an-icon-lPad-12 {--icon-lPad: 0.12; }.an-icon-lPad-14 {--icon-lPad: 0.14; }.an-icon-lPad-16 {--icon-lPad: 0.16; }
.an-icon-lPad-18 {--icon-lPad: 0.18; }.an-icon-lPad-20 {--icon-lPad: 0.2; }.an-icon-lPad-22 {--icon-lPad: 0.22; }.an-icon-lPad-24 {--icon-lPad: 0.24; }
.an-icon-lPad-26 {--icon-lPad: 0.26; }.an-icon-lPad-28 {--icon-lPad: 0.28; }.an-icon-lPad-30 {--icon-lPad: 0.3; }

.an-icon-rPad-2 {--icon-rPad: 0.02; }.an-icon-rPad-4 {--icon-rPad: 0.04; }.an-icon-rPad-6 {--icon-rPad: 0.06; }.an-icon-rPad-8 {--icon-rPad: 0.08; }
.an-icon-rPad-10 {--icon-rPad: 0.1; }.an-icon-rPad-12 {--icon-rPad: 0.12; }.an-icon-rPad-14 {--icon-rPad: 0.14; }.an-icon-rPad-16 {--icon-rPad: 0.16; }
.an-icon-rPad-18 {--icon-rPad: 0.18; }.an-icon-rPad-20 {--icon-rPad: 0.2; }.an-icon-rPad-22 {--icon-rPad: 0.22; }.an-icon-rPad-24 {--icon-rPad: 0.24; }
.an-icon-rPad-26 {--icon-rPad: 0.26; }.an-icon-rPad-28 {--icon-rPad: 0.28; }.an-icon-rPad-30 {--icon-rPad: 0.3; }

.an-icon-tPad-2 {--icon-tPad: 0.02; }.an-icon-tPad-4 {--icon-tPad: 0.04; }.an-icon-tPad-6 {--icon-tPad: 0.06; }.an-icon-tPad-8 {--icon-tPad: 0.08; }
.an-icon-tPad-10 {--icon-tPad: 0.1; }.an-icon-tPad-12 {--icon-tPad: 0.12; }.an-icon-tPad-14 {--icon-tPad: 0.14; }.an-icon-tPad-16 {--icon-tPad: 0.16; }
.an-icon-tPad-18 {--icon-tPad: 0.18; }.an-icon-tPad-20 {--icon-tPad: 0.2; }.an-icon-tPad-22 {--icon-tPad: 0.22; }.an-icon-tPad-24 {--icon-tPad: 0.24; }
.an-icon-tPad-26 {--icon-tPad: 0.26; }.an-icon-tPad-28 {--icon-tPad: 0.28; }.an-icon-tPad-30 {--icon-tPad: 0.3; }

.an-icon-bPad-2 {--icon-bPad: 0.02; }.an-icon-bPad-4 {--icon-bPad: 0.04; }.an-icon-bPad-6 {--icon-bPad: 0.06; }.an-icon-bPad-8 {--icon-bPad: 0.08; }
.an-icon-bPad-10 {--icon-bPad: 0.1; }.an-icon-bPad-12 {--icon-bPad: 0.12; }.an-icon-bPad-14 {--icon-bPad: 0.14; }.an-icon-bPad-16 {--icon-bPad: 0.16; }
.an-icon-bPad-18 {--icon-bPad: 0.18; }.an-icon-bPad-20 {--icon-bPad: 0.2; }.an-icon-bPad-22 {--icon-bPad: 0.22; }.an-icon-bPad-24 {--icon-bPad: 0.24; }
.an-icon-bPad-26 {--icon-bPad: 0.26; }.an-icon-bPad-28 {--icon-bPad: 0.28; }.an-icon-bPad-30 {--icon-bPad: 0.3; }

.a-btn-size {--height: 40px;--btnSize: 15px; --btnContainer: 20px;}

.a-btn-tiny-1 {--height: 24px;--btnSize: 11px; --btnContainer: 15px;}
.a-btn-tiny-2 {--height: 24px;--btnSize: 12px; --btnContainer: 15px;}
.a-btn-tiny-3 {--height: 26px;--btnSize: 12px; --btnContainer: 15px;}
.a-btn-tiny-4 {--height: 26px;--btnSize: 13px; --btnContainer: 15px;}
.a-btn-tiny-5 {--height: 28px;--btnSize: 13px; --btnContainer: 18px;}


.a-btn-brdRad-0 {--brdRadius: 0} .a-btn-brdRad-5 {--brdRadius: 0.05}.a-btn-brdRad-10 {--brdRadius: 0.1} .a-btn-brdRad-15 {--brdRadius: 0.15} .a-btn-brdRad-20 {--brdRadius: 0.2} .a-btn-brdRad-25 {--brdRadius: 0.25} .a-btn-brdRad-30 {--brdRadius: 0.3} .a-btn-brdRad-35 {--brdRadius: 0.35} .a-btn-brdRad-40 {--brdRadius: 0.4} .a-btn-brdRad-45 {--brdRadius: 0.45} .a-btn-brdRad-50 {--brdRadius: 0.5}

.a-btn-brdSize-0 {--brdSize: 0px} .a-btn-brdSize-1 {--brdSize: 1px} .a-btn-brdSize-2 {--brdSize: 2px} .a-btn-brdSize-3 {--brdSize: 3px} .a-btn-brdSize-4 {--brdSize: 4px}



.a-btn-weight-400 {--btnWeight: 400} .a-btn-weight-500 {--btnWeight: 500} .a-btn-weight-600 {--btnWeight: 600} .a-btn-weight-700 {--btnWeight: 700}
.a-btn-container-5 {--btnContainer: 5px} .a-btn-container-10 {--btnContainer: 10px} .a-btn-container-15 {--btnContainer: 15px} .a-btn-container-20 {--btnContainer: 20px} .a-btn-container-25 {--btnContainer: 25px}
.a-btn-container-30 {--btnContainer: 30px} .a-btn-container-35 {--btnContainer: 35px} .a-btn-container-40 {--btnContainer: 40px} .a-btn-container-45 {--btnContainer: 45px} .a-btn-container-50 {--btnContainer: 50px}
.a-btn-container-55 {--btnContainer: 55px}.a-btn-container-60 {--btnContainer: 60px}.a-btn-container-65 {--btnContainer: 65px}.a-btn-container-70 {--btnContainer: 70px}
.a-btn-height-20 {--height: 20px} .a-btn-height-22 {--height: 22px}.a-btn-height-24 {--height: 24px}.a-btn-height-26 {--height: 26px}.a-btn-height-28 {--height: 28px} .a-btn-height-30 {--height: 30px} .a-btn-height-35 {--height: 35px} .a-btn-height-40 {--height: 40px} .a-btn-height-45 {--height: 45px}
.a-btn-height-50 {--height: 50px}.a-btn-height-55 {--height: 55px}.a-btn-height-60 {--height: 60px}.a-btn-height-65 {--height: 65px}.a-btn-height-70 {--height: 70px}.a-btn-height-75 {--height: 75px}
.a-btn-font-11 {--btnSize: 11px} .a-btn-font-12 {--btnSize: 12px}.a-btn-font-13 {--btnSize: 13px}.a-btn-font-14 {--btnSize: 14px}.a-btn-font-15 {--btnSize: 15px}.a-btn-font-16 {--btnSize: 16px}.a-btn-font-17 {--btnSize: 17px}
.a-btn-font-18 {--btnSize: 18px}.a-btn-font-19 {--btnSize: 19px}.a-btn-font-20 {--btnSize: 20px}.a-btn-font-21 {--btnSize: 21px}.a-btn-font-22 {--btnSize: 22px}.a-btn-font-23 {--btnSize: 23px}
.a-btn-font-24 {--btnSize: 24px}.a-btn-font-25 {--btnSize: 25px}.a-btn-font-26 {--btnSize: 26px}.a-btn-font-27 {--btnSize: 27px}.a-btn-font-28 {--btnSize: 28px}.a-btn-font-29 {--btnSize: 29px}.a-btn-font-30 {--btnSize: 30px}
/* Size: border, font, padding, weight*/




.a-btn-size-40 {--brdSize: 1px;border-radius: 6px; font-size: 16px; line-height: 20px; padding: 0 18px;height: 40px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:26px; line-height: 20px; padding: 0 18px;height: 40px; letter-spacing: 0.5px;font-weight: 500;
--iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;
 }
.a-btn-size-2 {--brdSize: 1px;border-radius: 18px; font-size: 13px; line-height: 1; padding: 0 18px;height: 36px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-2-icon {--brdSize: 0;border-radius: 50%; --iconSize: 20px; line-height: 1; padding: 0 10px;height: 36px; font-weight: 500; margin-right: 4px;
  --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
.a-btn-size-mini {--brdSize: 1px;border-radius: 11px; font-size: 12px; line-height: 12px; padding: 0 22px;height: 22px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-3 {--brdSize: 1px;border-radius: 21px; font-size: 16px; line-height: 16px; padding: 0 22px;height: 42px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-4 {--brdSize: 1px;border-radius: 23px; font-size: 18px; line-height: 20px; padding: 0 24px;height: 46px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-6 {--brdSize: 1px;border-radius: 32px; font-size: 23px; line-height: 20px; padding: 0 40px;height: 64px; letter-spacing: 0.5px;font-weight: 600; }
.a-btn-size-15 {--brdSize: 1px;border-radius: 18px; font-size: 15px; line-height: 1; padding: 0 20px;height: 36px; letter-spacing: 0.5px;font-weight: 500; }
.a-btn-size-3-icon {--brdSize: 0;border-radius: 50%; --iconSize: 24px; line-height: 1; padding: 0 10px;height: 42px; font-weight: 500; margin-right: 4px;
  --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
.a-btn-size-cs {--brdSize: 1px;border-radius: 9px; font-size: 22px; line-height: 22px; padding: 0 20px;height: 56px; letter-spacing: 0.5px;font-weight: 600;touch-action: manipulation; }


.a-btn-icon-text {--brdSize: 0;border-radius: calc(var(--brdRadius, 0.15) * var(--height));font-size: 15px; --iconSize: calc(23px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 25px 0 15px;--height: 36px;
   height: var(--height); font-weight: var(--a-btn-weight, 500); }




.a-btn-icon {--brdSize: 0;border-radius: calc(var(--brdRadius, 0.5) * var(--height)); --iconSize: calc(23px + var(--iconSizAdj, 0px)); line-height: 1;
  padding: 0 calc((var(--height) - var(--iconSize) - var(--iconLePad, 0px) - var(--iconRiPad, 0px)) / 2); --height: 42px; height: var(--height);  }

@media only screen and (max-width: 1068px) {
  .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:26px; line-height: 26px; padding: 0;height: 40px; letter-spacing: 0.5px;font-weight: 500;
  --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
  .a-btn-size-15 {--brdSize: 1px;border-radius: 16px; font-size: 14px;  padding: 0 16px;height: 32px;  }
  .a-btn-size {--brdSize: 1px;border-radius: 6px; font-size: 14px; line-height: 20px; padding: 0 18px;height: 38px; letter-spacing: 0.5px;font-weight: 500; }
  .a-btn-size-cs {--brdSize: 1px;border-radius: 9px; font-size: 21px; line-height: 22px; padding: 0 19px;height: 52px; letter-spacing: 0.5px;font-weight: 600;touch-action: manipulation; }
}

@media only screen and (max-width: 734px) {
  .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:22px; line-height: 20px; padding: 0;height: 35px; letter-spacing: 0.5px;font-weight: 500;}

  .a-btn-size-2 {--brdSize: 1px;border-radius: 16px; font-size: 13px; line-height: 15px; padding: 0 12px;height: 32px; letter-spacing: 0.5px;font-weight: 500; }
  .a-btn-size-6 {--brdSize: 1px;border-radius: 29px; font-size: 19px; line-height: 19px; padding: 0 35px;height: 58px; letter-spacing: 0.5px;font-weight: 600; }
  .a-btn-size-cs {--brdSize: 1px;border-radius: 9px; font-size: 19px; line-height: 19px; padding: 0 17px;height: 47px; letter-spacing: 0.5px;font-weight: 600;touch-action: manipulation; }
}

@media only screen and (max-width: 385px) {
    .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:20px; line-height: 20px; padding: 0;height: min(8.2vw, 32px); letter-spacing: 0.5px;font-weight: 500;}
}

@media only screen and (max-width: 335px) {
    .a-btn-size-40-icon {--brdSize: 1px;border-radius: 6px; font-size: 14px;--iconSize:18px; line-height: 1; padding: 0;height: min(7.5vw, 26px); letter-spacing: 0.5px;font-weight: 500;}
}
