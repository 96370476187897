/* Meta */
.meta {min-height: var(--cn-meta-pic-height);}
.meta-nopics {margin-top:12px;}
.meta .auth-pic {display: inline-block; vertical-align: middle; border-radius: 50%;height: var(--cn-meta-pic-height);margin: 5px 10px 5px 0;overflow: hidden;
  position: relative;width: var(--cn-meta-pic-height);z-index: 1;}
.auth_image {width: 100%; height: 100%; display: block; border: 0}
.meta-text {display: inline-block; vertical-align: middle; max-width: 60%}
.acFu-time-icon {height: calc(1.2 * var(--cn-meta-size)); line-height: calc(1.2 * var(--cn-meta-size))}
.acFu-last-updated {height: calc(1.5 * var(--cn-meta-size)); line-height: 1.5}
.acFu-main-title-updated { font-weight: 500; font-size: var(--cn-meta-size); color: rgb(115, 115, 115);line-height: 1.5; }
.acFu-author { font-weight: bold;  font-size: var(--cn-meta-size); color: rgb(115, 115, 115); line-height: 1.5; }
