.cl-range-layout {width: calc(50% - 8px);display: flex;flex-direction: column;justify-content: stretch; align-items: center;}

/* RangeSection */
.cl-range-sections {width: 100%;padding: 8px;box-shadow: 1px 1px 2px rgba(0,0,0,.2);border-radius: 6px; background-color: white;margin-bottom: 8px;}
.cl-range-sections:last-child {margin-bottom: 0}
.cl-range-section-title {font-size: 15px; line-height: 1; padding: 8px 12px 14px 12px; font-weight: 600; display: inline-block;cursor: pointer;position: relative;}
.scarlet-is-open .cl-range-section-title {font-size: 12px;padding: 0 12px;}
.cl-range-section-title:hover {color: dodgerblue}

@media (max-width:734px) {
.cl-range-layout {width: 100%; justify-content: flex-start;}
.cl-range-sections {margin: 12px 12px 24px 12px; width: calc(100% - 24px);padding:22px 0; border-radius: 16px;}
.scarlet-is-open .cl-range-sections {padding: 12px 0;}
.cl-range-sections:last-child {margin-bottom: 24px}
.cl-range-section-title {font-size: 18px;padding: 0 8px 16px 20px;}
.scarlet-is-open .cl-range-section-title {font-size: 13px;padding: 0 18px;}
.pickers-scrollable-heading {width: 100vw;position: sticky; top: calc(var(--tBar-height) + 100vw * 0.26);z-index: 99;overflow-x: auto; overflow-y: visible;
-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */; background-color: inherit;}
.scarlet-is-open .pickers-scrollable-heading { top: calc(var(--tBar-height) + 100vw * 0.2 - 30px); position: fixed;z-index: 100;}
.pickers-scrollable-heading::-webkit-scrollbar {display: none;}

}
