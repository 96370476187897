/*


@media only screen and (max-width: 1068px) {

}

@media only screen and (max-width: 734px)  {

}



*/
html {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: none;
}
.prova-svg:hover {fill: white!important}
.top-bar-vars.no-top-bar {--tBar-height: 0px!important}
.no-over-scroll {overscroll-behavior-y: none;}
.no-touch {touch-action: none}  /*Disable touch events on an element even with passive listeners*/
.no-pointer {pointer-events: none}
.no-mrB-last:last-child {margin-bottom: 0!important}
.no-mrT-first:first-child {margin-top: 0!important}
.width-max, .max-content {width: max-content !important; white-space: nowrap;}
.fit-content {width: fit-content;}
.width-auto {width: auto}
.lr-margin-auto {margin-left: auto;margin-right: auto;}
.no-scr-bar {-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */;}
.no-scr-bar::-webkit-scrollbar {display: none;}
.scroll-x-auto {width: 100%!important; overflow-x: auto; overflow-y: visible;}
.scroll-y-auto {height: 100%!important; overflow-y: auto; overflow-x: visible;}
.scroll-xy-auto {height: 100%;width: 100%; overflow: auto;}
.block-scroll {height: 100vh; overflow: hidden;}
.p-rel {position: relative;}
.p-abs {position: absolute;}
.z-1 {z-index: 1}
.p-abs-topRight {position: absolute;top: 0; right: 0; }
.p-abs-topRight-t20 {position: absolute;top: 0; right: 0; transform: translate(20%,-20%);}
.p-abs-topRight-t30 {position: absolute;top: 0; right: 0; transform: translate(30%,-30%);}
.p-abs-topRight-t40 {position: absolute;top: 0; right: 0; transform: translate(40%,-40%);}
.p-abs-topRight-t50 {position: absolute;top: 0; right: 0; transform: translate(50%,-50%);}
.p-abs-topRight-t60 {position: absolute;top: 0; right: 0; transform: translate(60%,-60%);}
.p-abs-topRight-t60 {position: absolute;top: 0; right: 0; transform: translate(70%,-70%);}
/*************** Scroll Bar Style **************/
.full-width-scr7 {width: calc(100% - 7px)}
h1,h2,h3,h4,h5,h6 {margin: 0; padding: 0}
.w100 {width: 100%; overflow: visible !important;}
input:autofill, input:-webkit-autofill-strong-password, input:-webkit-autofill-strong-password-viewable, input:-internal-autofill-selected, input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active, .w3-input:autofill {
background-color: transparent !important;
background-image:none !important;
color: inherit;

}
.h100vh {min-height: 100vh}

 /* width #0057B8, #FFD700*/




/* Track */

:root {scrollbar-color: #0057B8 #FFD700;}
::-webkit-scrollbar {
  width: 7px;
  -webkit-appearance: none;
}
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 8px grey;*/
  border-radius: 10px;
  background-color: #0057B8;
}



/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFD700;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /*background: hsl(35, 95%,65%);*/
}

@media (min-width:734px) { .phone-only {display: none !important;}}
@media (max-width:734px) { .no-phone {display: none !important;}}
.noselect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
:root {--content: 1380px;}
.fb-shadow {box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;}
 /*full height centered image - if width < height there will be margins left/right, if width >= height the img will be cut on both side to make the width == height*/

.mw-100 {min-width: 100px}.mw-110 {min-width: 110px} .mw-120 {min-width: 120px}.mw-130 {min-width: 130px}.mw-140 {min-width: 140px}.mw-150 {min-width: 150px}.mw-160 {min-width: 160px}.mw-170 {min-width: 170px}
.mw-180 {min-width: 180px}.mw-190 {min-width: 190px}.mw-200 {min-width: 200px} .mw-250 {min-width: 250px} .mw-300 {min-width: 300px} .mw-400 {min-width: 400px} .mw-500 {min-width: 500px}
.full-width {width: 100%}
.fake-block {height: 100vh; background: red}
.animated {animation-fill-mode: forwards; animation-duration: 1s; opacity: 0}
.a-square-img {position: relative; overflow: hidden; width: 100%; height: 100%} /* height js set to exactly 100%, width is set min 100% with the overflowing width not displayed*/
.a-square-img > img {height: 100%; max-height: 100%; max-width: none; min-width: 100%; margin: auto; position: absolute; left: -9999px; top: -9999px; right: -9999px; bottom: -9999px;}
.an-inline {display: inline;}
.an-inline-block {display: inline-block;}
.gray-hover{background-color: white} .gray-hover:hover{background-color: #f2f2f2}
.l-blue-hover{background-color: white} .l-blue-hover:hover{background-color: rgba(78, 137, 204, 0.39)}
.a-nowrap{  white-space: nowrap;}
.a-text-right {text-align: right!important;}
.a-size-base {  font-size: 14px!important;line-height: 20px!important;}
.no-hover {cursor: auto !important}
.invisible, .invisible div, .invisible span, .invisible a {visibility: hidden; }
.auto-cursor {cursor: auto !important;}
body {box-sizing: border-box; font-family: "Segoe UI", Arial, sans-serif;}
.d16 {width: 16px; height: 16px; line-height: 16px} .d17 {width: 17px; height: 17px; line-height: 17px}.d20 {width: 20px; height: 20px; line-height: 20px}.d24 {width: 24px; height: 24px; line-height: 24px}
.d25 {width: 25px; height: 25px; line-height: 25px} .d30 {width: 30px; height: 30px; line-height: 30px}
.ZI10 {z-index: 10}
hr {background-color: transparent;display: block;height: 1px;border-width: 0;border-top: 1px solid #e7e7e7;line-height: 19px;margin-top: 0;margin-bottom: 0;}
/**** flex **********/
.flx-btw {display: flex; align-items: center; justify-content: space-between; flex-wrap: nowrap;}
.flx-cen-btw {display: flex; align-content: space-between; justify-content: center; flex-direction: column;}
.flx-btw-str {display: flex; align-items: stretch; justify-content: space-between; flex-wrap: nowrap;}
.flx-btw-btm {display: flex; align-items: flex-end; justify-content: space-between; }
.flx-self-top {align-self: flex-start;}
.no-shrink {flex-shrink: 0;}
.grow-1 {flex-grow: 1}
.a-flex-col, .flex-col{display: flex; flex-direction: column !important;}
.a-flex-col-centered {display: flex; flex-direction: column !important; align-items: center;}
.flex-jCenter {justify-content: center}
.flex-jStart {justify-content: flex-start;}
.flex-aTop {align-items: flex-start;}
.flex-aCenter  {align-items: center;}
.flx-cen {display: flex; justify-content: center;}
.flx-cen-btm {display: flex;justify-content: center; align-items: flex-end;}
.flx-al-btm {align-items: flex-end;}.flx-al-top {align-items: flex-start;}.flx-al-cen {align-items: center;}
.flx-aTop-Jcen {display: flex; justify-content: center; align-items: flex-start; flex-wrap: nowrap;}
.flx-top-start {display: flex; justify-content: flex-start; align-items: flex-start; }
.a-wrap {flex-wrap: wrap !important;}
.a-no-wrap {flex-wrap: nowrap !important}
.flx-stretch {align-items: stretch !important;}
/*********************flex ************/



.a-sticky-col {position: -webkit-sticky; position: sticky; top: 0px;  height: 100%;}
.an-oriz-str-img {width: 100%; height: auto}
ul.ul-clear {margin: 0; padding: 0; list-style-type: none;}
ul.ul-clear > li {list-style: none; display: block;}
ul.ul-clear-inline {margin: 0; padding: 0; list-style-type: none;}
ul.ul-clear-inline > li {list-style: none; display: inline-block;}
/*************** Scroll Bar Style **************/
.overX-Hid {overflow-x: hidden; overflow-y: visible;}
.ov-hidd, .over-hid, .o-hid, .ov-hid {overflow: hidden}
.overflow-auto {overflow: auto;}
.scroll-x {width: 100%; overflow-x: auto;}
.zI1 {z-index: 1}
.z-ind-4 {z-index: 4;}
.back-image-cover {background-size: cover; background-repeat: no-repeat; background-position: center;  position: relative;}
.fullscreen-gym-slider {margin-bottom: 16px; margin-right: 16px; padding: 8px}
.home-block-container {margin: 64px auto}
.home-block-title-container {padding: 30px 40px 50px 40px; line-height: 1; font-weight: bold; font-size: 32px}
.mainTheme {color:#fff ; background-color:#3b5da1 !important}
.truncate{white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.app-content {max-width: 1280px; margin: 0 auto; padding: 20px 0;}
.tx-right-align {text-align: right;}
.tx-left-align {text-align: left;}
@media (max-width:900px) {
.fullscreen-gym-slider {margin-bottom: 8px; margin-right: 8px; padding: 0px}
.home-block-container {margin: 32px auto}

}

@media (max-width:520px) {
.mainTheme {background-color:white !important}
.home-block-container {border-top: 3px solid #35538f; margin: 0 auto !important;; padding-bottom: 10px}
.home-block-title-container {padding: 12px 16px 22px 16px; font-size: 6vw;}
}

@media (min-width:521px){

.zzhome-block-container {box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px 0px, rgba(0, 0, 0, 0.188235) 0px 4px 20px 0px; border-radius: 10px; overflow: hidden;}
}
@media (min-width:901px) {

}
@media (min-width:1200px) {

}

@media (min-width: 520px) and (max-height: 450px){
  .home-block-container {margin-left: 8px !important; margin-right: 8px !important}
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


/*
Shaking effect
.ts-inpage-push-dating__cta-button {
    position: absolute;
    text-align: center;
    top: 50%;
    right: 15px;
    width: 60px;
    height: 28px;
    line-height: 28px;
    margin-top: -14px;
    animation: linear shaking 2s infinite 3s;
    background: #c837ab;
    background: -moz-linear-gradient(-45deg, #c837ab 0, #ff543e 75%, #ff9349 100%);
    background: -webkit-linear-gradient(-45deg, #c837ab 0, #ff543e 75%, #ff9349 100%);
    background: linear-gradient(135deg, #c837ab 0, #ff543e 75%, #ff9349 100%);
    border-radius:14px
}



*/

@keyframes shaking {
    0%, 7% {
        transform:rotateZ(0)
    }

    15% {
        transform:rotateZ(-15deg)
    }

    20% {
        transform:rotateZ(10deg)
    }

    25% {
        transform:rotateZ(-10deg)
    }

    30% {
        transform:rotateZ(6deg)
    }

    35% {
        transform:rotateZ(-4deg)
    }

    100%, 40% {
        transform:rotateZ(0)
    }
}

.hero-image-wrapper .overlay[data-v-95e8bf52] {
    background-color: rgba(17,17,17,.35);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.hero-image-wrapper .overlay[data-v-95e8bf52]::before {
    content: "";
    position: absolute;
    height: 5rem;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(0deg,hsla(0,0%,100%,0),rgba(0,0,0,.7));
}

.hero-image-wrapper .overlay[data-v-95e8bf52]::after {
    content: "";
    position: absolute;
    height: 11.25rem;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg,transparent 0,#101010);
}
