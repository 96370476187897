.cn-rec-defs-orig {--topBarColorx: rgb(204, 0, 0) /* reddish */;--gridPadding:24px;--gridBorder:1px solid rgb(230, 230, 230);--gridBordRad:4px;--gridMargBot:32px;--gridBackgCol:white;
              --headerFontSize: 28px; --ulLeftPad: 16px; --liSize:16px;--liColor:rgb(38, 38, 38); --liVPad: 8px;--liLineHe:1.6;
    }

.cn-rec-defs {--topBarColorx: rgb(204, 0, 0) /* reddish */;--gridPadding:24px;--gridBorder:0;--gridBordRad:0 0 8px 8px;--gridMargBot:32px;--gridBackgCol:white;
              --headerFontSize: 28px; --ulLeftPad: 16px; --liSize:16px;--liColor:rgb(38, 38, 38); --liVPad: 8px;--liLineHe:1.5;--boxSh: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
@media screen and (max-width: 1024px) {
    .cn-rec-defs {--gridPadding:16px;--gridMargBot:16px;--headerfontSize: 23px; --liSize:15px;--liVPad: 5px;--liLineHe:1.35;}
}

@media screen and (max-width: 734px) {
    .cn-rec-defs {--gridPadding:16px;--gridMargBot:16px;--headerfontSize: 5vw; --liSize:max(3.6vw, 13px);}
}


.cn-rec {  padding: var(--gridPadding);  border: 1px solid var(--gridBorder);border: var(--gridBorder);border-radius: var(--gridBordRad);box-shadow: var(--boxSh);
  margin-bottom: var(--gridMargBot); font-family:  "Helvetica Neue", Helvetica, Arial, Utkal, sans-serif; background-color: var(--gridBackgCol);
  }
.cn-rec:first-child {  border-top: 4px solid var(--topBarColorx);}
.cn-rec-h2 {font-size: var(--headerFontSize);font-weight: 700;  margin: 0;  line-height: 1;}
.cn-rec-par {  line-height: 1.3; margin: 0;  -webkit-font-smoothing: antialiased;}
.cn-rec-ul {list-style-type: none;margin: 0; padding: 0 0 0 var(--ulLeftPad);}
.cn-rec-ul li {   margin: var(--liVPad) 0;  line-height: var(--liLineHe);  font-size: var(--liSize);  color: var(--liColor);}
.cn-rec-ul li::before {  content: "▪";  color: rgb(140, 140, 140);  width: 16px;  margin-left: -16px;  display: inline-block;}
