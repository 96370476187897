/*

ol.side-menu-ol-sub {padding: 0; margin: 0; list-style-type: none; width: 200%; overflow: hidden; }
ol.side-menu-ol-sub li.side-menu-li-sub {display: inline-block; width: 50%;vertical-align: top; position: relative;}
*/
.side-men-cont {overflow: hidden; display: block;width: 100%; height: 100%;position: relative;}
.side-men-cont::-webkit-scrollbar {
  width: 2px;
}
ol.side-menu-ol-sub {padding: 0; margin: 0; list-style-type: none; width: 100%;  position: relative; height: 100%}
ol.side-menu-ol-sub li.side-menu-li-sub-1 {display: block; padding: 60px 0; width: 100%; position: relative;z-index: 1; background-color: var(--sMenu-bkgCol); height: 100%; overflow: auto;}
ol.side-menu-ol-sub li.side-menu-li-sub-2 {display: block; padding: 60px 0;width: 100%; position: absolute;top: 0; left: 0; z-index: 10; background-color: var(--sMenu-bkgCol);
  height: 100%; overflow: auto;}

.dec1 {--iconSizAdj: -1px} .dec2 {--iconSizAdj: -2px} .dec3 {--iconSizAdj: -3px} .dec4 {--iconSizAdj: -4px}
.inc1 {--iconSizAdj: 1px} .inc2 {--iconSizAdj: 2px} .inc3 {--iconSizAdj: 3px} .inc4 {--iconSizAdj: 4px}

.side-menu-defs-Amz {
  --categoryTitleColor: rgb(17, 17, 17);
  --categoryTitleSize: 18px;
  --categoryTitleWeight: 700;
  --linkColor:rgb(17, 17, 17);
  --linkSize:14px; /*link-button text size*/
  --linkWeight: normal;/*link-button text font weight */
  --linkHeight: 44px;/*link-button container height. There is no vertical padding but flex centering so the height is important to subsitute the padding */
  --linkBkgHover: rgba(0,0,0,0.05);/*link-button has no background but on hover this background is displayed*/
  --linkContainer:8px; /* Left and right padding of the link-button container visible on hover when the background color is active*/
  --linkIconColor: DodgerBlue; /* the icon inside the button-link if present */
  --linkIconSize:30px;/* the icon inside the button-link if present */
  --linkSubArrowSize: 17px; /* When the link contains a submenu the right arrow appears on the right end of the link-button. The color is the same as the link text, the size is set here */
  --linkSubArrowColor: var(--linkColor);
  --linkIconRightMargin:8px;/* */
  --prevTitleSize:16px;
  --menu-container: 16px;
  --menuTopMargin: 16px;
  --userIntroHeight: 60px;
}

.side-menu-defs{
  --sMenu-bkgCol: white;
  /* Welcome */
  --userIntroHeight: 60px;

  /* Shared Containers  */
  --menu-container: 16px; /* Outer container padding of the menu */
  --linkContainer:8px; /* Left and right padding of the link-button  container and prev Link Container visible on hover activated background */
  --menuTopMargin: 16px; /* Top Margin of the whole ul after the previous block  */

  /* Previous   */
  --backLinkColor: rgb(50,50,50);
  --preSeparetorCol: rgb(220,220,220);

  --prevTitleSize:18px;
  --backLinkWeight: 500;

  --backIconSize: 25px;
  --backIconRightMargin:6px;/* */

  --backLinkHeight: 40px;
  --prevSectMargin: 8px;


  /* Category   Menu*/
  --categoryTitleColor: rgb(141, 143, 137);
  --catMenuBotHrCol: hsl(210,100%,85%);

  --catTitleHeight: 40px;
  --categoryTitleSize: 17px;
  --categoryTitleWeight: 600;
  --menuCatHrContMarg: 0 8px;
  --catMenuSectMargin: 12px;

  /* Link Button   */
  --linkColor:rgb(15, 15, 15);
  --linkBkgHover: rgba(0,0,0,0.05);/*link-button has no background but on hover this background is displayed*/
  --linkIconColor: DodgerBlue; /* the icon inside the button-link if present */
  --linkSubArrowColor: DodgerBlue;

  --linkSize:17px; /*link-button text size*/
  --linkWeight: 500;/*link-button text font weight */
  --linkHeight: 52px;/*link-button container height. There is no vertical padding but flex centering so the height is important to subsitute the padding */

  --linkIconSize: 25px;/* the icon inside the button-link if present */
  --linkIconRightMargin:16px;/*Margin Between the left icon and the following text*/
  --linkSubArrowSize: 18px; /* When the link contains a submenu the right arrow appears on the right end of the link-button. The color is the same as the link text, the size is set here */



}

@media only screen and (max-width: 1068px) {}
@media only screen and (max-width: 734px) {
  .side-menu-defs{
    /* Welcome */
    --userIntroHeight: 60px;

    /* Shared Containers  */
    --menu-container: 16px; /* Outer container padding of the menu */
    --linkContainer:8px; /* Left and right padding of the link-button  container and prev Link Container visible on hover activated background */
    --menuTopMargin: 18px; /* Top Margin of the whole ul after the previous block  */

    /* Previous   */

    --prevTitleSize:18px;
    --backLinkWeight: 450;

    --backIconSize: 30px;
    --backIconRightMargin:10px;/* */

    --backLinkHeight: 45px;
    --prevSectMargin: 8px;

    /* Category   Menu*/

    --catTitleHeight: 55px;
    --categoryTitleSize: 19px;
    --categoryTitleWeight: 600;
    --menuCatHrContMarg: 0 8px;
    --catMenuSectMargin: 22px;

    /* Link Button   */


    --linkSize: min(max(5.2vw, 16px), 19px); /*link-button text size*/
    --linkWeight: 500;/*link-button text font weight */
    --linkHeight: 60px;/*link-button container height. There is no vertical padding but flex centering so the height is important to subsitute the padding */

    --linkIconSize:30px;/* the icon inside the button-link if present */
    --linkIconRightMargin:30px;/*Margin Between the left icon and the following text*/
    --linkSubArrowSize: 26px; /* When the link contains a submenu the right arrow appears on the right end of the link-button. The color is the same as the link text, the size is set here */


}}



@media only screen and (max-width: 350px) {
    .side-menu-defs{
        --linkSize: min(max(5.2vw, 16px), 20px); /*link-button text size*/
        --linkWeight: 500;/*link-button text font weight */
        --linkHeight: 50px;/*link-button container height. There is no vertical padding but flex centering so the height is important to subsitute the padding */

        --linkIconSize:24px;/* the icon inside the button-link if present */
        --linkIconRightMargin:20px;/*Margin Between the left icon and the following text*/
        --linkSubArrowSize: 22px; /* When the link contains a submenu the right arrow appears on the right end of the link-button. The color is the same as the link text, the size is set here */
        }
}
