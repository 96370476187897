
.cl-col-hsv-s-range {position: absolute;top: 102.5%; width: 98%; font-size: 12px; line-height: 1; color: black;left: -6px;}
.cl-col-hsv-v-range {position: absolute;top: 50%; transform: translate(-13px,0px) rotate(-90deg);width: 98%;left: -50%;font-size: 12px; line-height: 1; color: black; height: 20px;}
.cl-col-hsv-comp-name {font-size: 15px; font-weight: 600; color: dodgerblue;}
.cl-col-hsv-0-rot {transform: translate(-4px, -2px) rotate(30deg); display: inline-block;}
.cl-sv-graph-cont {background: hsl(210,100%,97%);padding: 20px 20px 32px 32px;border-radius: 10px;}
.col-color-rect-hsv-bord-cont {width: calc(100%); padding: 2px 2px 0 0;  position: relative;z-index: 20; border-left: 3px solid dodgerblue; border-bottom: 3px solid dodgerblue; }
.cl-sv-dragghing .col-color-rect-hsv-bord-cont {border-color: orange}
.col-color-rect-bl-wh {width: 100%; padding-bottom: 100%; position: relative;z-index: 10; }
.col-color-rect-hsv-bord-cont::after {content: " ";position: absolute;bottom: calc(100%); left: -10px;border-width: 8px;border-style: solid;
border-color:   transparent transparent dodgerblue transparent;}
.cl-sv-dragghing .col-color-rect-hsv-bord-cont::after {border-color:   transparent transparent orange transparent;}
.col-color-rect-arr2 {content: " ";position: absolute;top: calc(100% - 7px); left: calc(100% + 2px);border-width: 8px;border-style: solid;
border-color:   transparent transparent  transparent dodgerblue;}
.cl-sv-dragghing .col-color-rect-arr2 {border-color:   transparent transparent  transparent orange;}
.col-color-rect-white-grad {position: absolute; background-image: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));inset: 0;z-index: 11}
.col-color-rect-black-grad {position: absolute; background-image:linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));inset: 0;z-index: 12}
.col-color-rect-bl-wh-cursor {position: absolute; z-index: 1000; cursor: default;touch-action: none;}
.col-color-rect-bl-wh-cursor-el { width: 20px; height: 20px;border-radius: 6px; border: 1px solid white; }
.wh-curs-dragged .col-color-rect-bl-wh-cursor-el {}
.cl-range-hsv-level {   line-height: 1;  padding: 4px 0;  font-size: 12px;  font-weight: 600;  border-radius: 20%;  background: dodgerblue;  color: white;  width: 40px;  text-align: center; cursor: pointer;}
.cl-sv-edit-cont {width: 150px;   border-radius: 4px; font-size: 22px; color: dodgerblue; z-index: 1000}
.cl-inp-sv-col-err {position: absolute;left: 50%;  top: 100%; transform: translate(-50%, 12px);font-size: 14px; width: 160px;z-index: 50;
  padding: 0 10px;border: none;border-radius: 6px 0 0 6px; border-left: 4px solid red; ;background-color: white; line-height: 1.2; min-height: 50px; font-weight: 500; color: black;}
.cl-sv-edit-done-ic { width: 22px; height: 22px; line-height: 22px; cursor: pointer;position: absolute;}
.cl-zi-100 {z-index: 100}

.cl-sv-imp-defs {
  /* container */   /**/
  --bRadius: 5px;  --brd:2px; --height: 60px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 10px ; --covLabelSize: 14px; --cLabWeight: 500;
  /* label */
  --labelTopPad: 8px;--labelBotPad: 0; --labelContPad: 8px;  --labelSize: 12px; --labWeight: 500;
  --labelAlign: left; ; --labelCol: black;
  /* input */
  --inpContPad: 45px; --inpSize: 22px; --inpPadTop: 16px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 40px;
}

@media (max-width:734px) {
.cl-sv-graph-cont {padding: 36px 26px 16px 36px;background: inherit;}
}
