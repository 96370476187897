.pagination-container {  border: 1px solid lightblue/*#e7e7e7*/;  padding: 8px;  border-radius: 2px;  font-size: 14px;  font-weight: 400;  line-height: 20px; color: rgb(68,68,68);}

/* Navigation */
.pagination-navigation {height: 24px; color: rgb(0, 113, 194); font-size: 14px; line-height: 20px; font-weight: 400}
.pagination-prev {margin-right: 16px; padding: 0 4px;}
.pagination-next {margin-left: 16px; padding: 0 4px;}
.pagination-next:hover .material-icons:not(.pagination-disabled:hover .material-icons), .pagination-prev:hover .material-icons:not(.pagination-disabled:hover .material-icons){color:#bc5b01 !important; }
.pagination-disabled {cursor: not-allowed; opacity: .4;}
.pag-nav-cont {width: 18px; height: 18px; line-height: 18px;}
/* Pages */
.pagination-page-item + .pagination-page-item {margin-left: 8px;}
.pagination-page-link {  display: -webkit-box;  display: -ms-flexbox;  display: -webkit-flex;  display: flex;  -webkit-box-align: center;  -ms-flex-align: center;  -webkit-align-items: center;  align-items: center;  -webkit-box-pack: center;
    -ms-flex-pack: center;  -webkit-justify-content: center;  justify-content: center;  color: #0071c2;  min-width: 24px;  height: 24px;padding: 2px 4px;-webkit-box-sizing: border-box;    -moz-box-sizing: border-box;
    box-sizing: border-box;text-decoration: none;border: 1px solid transparent;  border-radius: 2px;  font-weight: 400;  font-size: inherit;cursor: pointer;background: transparent; }
.pagination-page-link:hover {  border-color: #0071c2;  background-color: #0071c2;  color: #fff;}
.pagination-item-active .pagination-page-link {  border-color: #949494;  background-color: transparent;  color: #6b6b6b;  cursor: default;  }

/*Controls */
.pagination-controls {}
