.div-sel-col-1 {--selDr-col: 1} .div-sel-col-2{--selDr-col: 2} .div-sel-col-3 {--selDr-col: 3} .div-sel-col-4 {--selDr-col: 4} .div-sel-col-5 {--selDr-col: 5}
.div-sel-col-6 {--selDr-col: 6} .div-sel-col-7 {--selDr-col: 7} .div-sel-col-8 {--selDr-col: 8} .div-sel-col-9 {--selDr-col: 9}
.div-sel-disp-0 {--selDis: 0} .div-sel-disp-1 {--selDis: -1} .div-sel-disp-2 {--selDis: -2} .div-sel-disp-3 {--selDis: -3} .div-sel-disp-4 {--selDis: -4} .div-sel-disp-5 {--selDis: -5}
.div-select-optionN{position: absolute; top: calc(100% + 4px); left: calc(var(--selDis, 0) * 100%); z-index: 5; border-radius: 6px;
  background-color: var(--dropBkgColor); color: var(--dropColor);border-radius: var(--bRadius) var(--bRadius) var(--bRadius) var(--bRadius);
  overflow-y: scroll;-ms-overflow-style: none;scrollbar-width: none;width: calc(var(--selDr-col) * 100%); display: flex;flex-wrap: wrap;justify-content: flex-start;z-index: 50;
  max-height: 200px; }
.div-select-top .div-select-optionN {bottom: calc(100% + 8px); top: auto;z-index: 50}
.div-select-optionN::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.div-select-optionNsss::after {  content: " ";  position: absolute;  bottom: 100%;   right: 0px;  margin-right: 15px;  border-width: 7px;  border-style: solid;
  border-color: transparent transparent var(--dropBkgColor) transparent;}
.frm-drop-icon {padding-right: calc(var(--inpContPad) * 0.7); padding-top: 2px;}
.frm-selectItem {height: var(--dropHeight); padding-left: var(--inpContPad); padding-right: var(--inpContPad); width: calc(100% / var(--selDr-col)); font-size: var(--inpSize); font-weight: var(--inpWeight)}
.frm-selectItem:hover {background: var(--dropBkgHoverColor) ; color: var(--dropHoverColor);}
.frm-overlay {position: fixed; top: 0; left: 0; height: 100%; width: 100%; z-index: 9}
