    /* padding top - bottom        0-20px      inc 1*/
    .a-pdT-0 {padding-top: 0px!important;}.a-pdB-0 {padding-bottom: 0px!important;}.a-pdT-1 {padding-top: 1px;}.a-pdB-1 {padding-bottom: 1px;}.a-pdT-2 {padding-top: 2px;}.a-pdB-2 {padding-bottom: 2px;}.a-pdT-3 {padding-top: 3px;}.a-pdB-3 {padding-bottom: 3px;}.a-pdT-4 {padding-top: 4px;}.a-pdB-4 {padding-bottom: 4px;}.a-pdT-5 {padding-top: 5px;}.a-pdB-5 {padding-bottom: 5px;}.a-pdT-6 {padding-top: 6px;}.a-pdB-6 {padding-bottom: 6px;}.a-pdT-7 {padding-top: 7px;}.a-pdB-7 {padding-bottom: 7px;}.a-pdT-8 {padding-top: 8px;}.a-pdB-8 {padding-bottom: 8px;}.a-pdT-9 {padding-top: 9px;}.a-pdB-9 {padding-bottom: 9px;}.a-pdT-10 {padding-top: 10px;}.a-pdB-10 {padding-bottom: 10px;}.a-pdT-11 {padding-top: 11px;}.a-pdB-11 {padding-bottom: 11px;}.a-pdT-12 {padding-top: 12px;}.a-pdB-12 {padding-bottom: 12px;}.a-pdT-13 {padding-top: 13px;}.a-pdB-13 {padding-bottom: 13px;}.a-pdT-14 {padding-top: 14px;}.a-pdB-14 {padding-bottom: 14px;}.a-pdT-15 {padding-top: 15px;}.a-pdB-15 {padding-bottom: 15px;}.a-pdT-16 {padding-top: 16px;}.a-pdB-16 {padding-bottom: 16px;}.a-pdT-17 {padding-top: 17px;}.a-pdB-17 {padding-bottom: 17px;}.a-pdT-18 {padding-top: 18px;}.a-pdB-18 {padding-bottom: 18px;}.a-pdT-19 {padding-top: 19px;}.a-pdB-19 {padding-bottom: 19px;}.a-pdT-20 {padding-top: 20px;}.a-pdB-20 {padding-bottom: 20px;}
    /* padding top - bottom        22-38px      inc 2*/
    .a-pdT-22 {padding-top: 22px;}.a-pdB-22 {padding-bottom: 22px;}.a-pdT-24 {padding-top: 24px;}.a-pdB-24 {padding-bottom: 24px;}.a-pdT-26 {padding-top: 26px;}.a-pdB-26 {padding-bottom: 26px;}.a-pdT-28 {padding-top: 28px;}.a-pdB-28 {padding-bottom: 28px;}.a-pdT-30 {padding-top: 30px;}.a-pdB-30 {padding-bottom: 30px;}.a-pdT-32 {padding-top: 32px;}.a-pdB-32 {padding-bottom: 32px;}.a-pdT-34 {padding-top: 34px;}.a-pdB-34 {padding-bottom: 34px;}.a-pdT-36 {padding-top: 36px;}.a-pdB-36 {padding-bottom: 36px;}.a-pdT-38 {padding-top: 38px;}.a-pdB-38 {padding-bottom: 38px;}
    /* padding bottom 40-190px inc 10 */
    .a-pdB-40 {padding-bottom: 40px; }.a-pdB-50 {padding-bottom: 50px; }.a-pdB-60 {padding-bottom: 60px; }.a-pdB-70 {padding-bottom: 70px; }.a-pdB-80 {padding-bottom: 80px; }.a-pdB-90 {padding-bottom: 90px; }.a-pdB-100 {padding-bottom: 100px; }.a-pdB-110 {padding-bottom: 110px; }.a-pdB-120 {padding-bottom: 120px; }.a-pdB-130 {padding-bottom: 130px; }.a-pdB-140 {padding-bottom: 140px; }.a-pdB-150 {padding-bottom: 150px; }.a-pdB-160 {padding-bottom: 160px; }.a-pdB-170 {padding-bottom: 170px; }.a-pdB-180 {padding-bottom: 180px; }.a-pdB-190 {padding-bottom: 190px; }
    /* padding bottom 200-700px inc 100 */
    .a-pdB-200 {padding-bottom: 200px;}.a-pdB-300 {padding-bottom: 300px;}.a-pdB-400 {padding-bottom: 400px;}.a-pdB-500 {padding-bottom: 500px;}.a-pdB-600 {padding-bottom: 600px;}.a-pdB-700 {padding-bottom: 700px;}
    /* % padding bottom 5-100% inc 5 */
    .a-pPdB-5 {padding-bottom: 5%; }.a-pPdB-10 {padding-bottom: 10%; }.a-pPdB-15 {padding-bottom: 15%; }.a-pPdB-20 {padding-bottom: 20%; }.a-pPdB-25 {padding-bottom: 25%; }.a-pPdB-30 {padding-bottom: 30%; }.a-pPdB-35 {padding-bottom: 35%; }.a-pPdB-40 {padding-bottom: 40%; }.a-pPdB-45 {padding-bottom: 45%; }.a-pPdB-50 {padding-bottom: 50%; }.a-pPdB-55 {padding-bottom: 55%; }.a-pPdB-60 {padding-bottom: 60%; }.a-pPdB-65 {padding-bottom: 65%; }.a-pPdB-70 {padding-bottom: 70%; }.a-pPdB-75 {padding-bottom: 75%; }.a-pPdB-80 {padding-bottom: 80%; }.a-pPdB-85 {padding-bottom: 85%; }.a-pPdB-90 {padding-bottom: 90%; }.a-pPdB-95 {padding-bottom: 95%; }.a-pPdB-100 {padding-bottom: 100%; }
    /* pdT 40-200px inc 10 */
    .a-pdT-40 {padding-top: 40px; }.a-pdT-50 {padding-top: 50px; }.a-pdT-60 {padding-top: 60px; }.a-pdT-70 {padding-top: 70px; }.a-pdT-80 {padding-top: 80px; }.a-pdT-90 {padding-top: 90px; }.a-pdT-100 {padding-top: 100px; }.a-pdT-110 {padding-top: 110px; }.a-pdT-120 {padding-top: 120px; }.a-pdT-130 {padding-top: 130px; }.a-pdT-140 {padding-top: 140px; }.a-pdT-150 {padding-top: 150px; }.a-pdT-160 {padding-top: 160px; }.a-pdT-170 {padding-top: 170px; }.a-pdT-180 {padding-top: 180px; }.a-pdT-190 {padding-top: 190px; }.a-pdT-200 {padding-top: 200px; }

@media (max-width:1200px) {
    /* padding top - bottom        0-20px      inc 1*/
    .a-pdT-0-t {padding-top: 0px!important;}.a-pdB-0 {padding-bottom: 0px!important;} .a-pdT-1-t {padding-top: 1px;}.a-pdB-1 {padding-bottom: 1px;} .a-pdT-2-t {padding-top: 2px;}.a-pdB-2 {padding-bottom: 2px;} .a-pdT-3-t {padding-top: 3px;}.a-pdB-3 {padding-bottom: 3px;} .a-pdT-4-t {padding-top: 4px;}.a-pdB-4 {padding-bottom: 4px;} .a-pdT-5-t {padding-top: 5px;}.a-pdB-5 {padding-bottom: 5px;} .a-pdT-6-t {padding-top: 6px;}.a-pdB-6 {padding-bottom: 6px;} .a-pdT-7-t {padding-top: 7px;}.a-pdB-7 {padding-bottom: 7px;} .a-pdT-8-t {padding-top: 8px;}.a-pdB-8 {padding-bottom: 8px;} .a-pdT-9-t {padding-top: 9px;}.a-pdB-9 {padding-bottom: 9px;} .a-pdT-10-t {padding-top: 10px;}.a-pdB-10 {padding-bottom: 10px;} .a-pdT-11-t {padding-top: 11px;}.a-pdB-11 {padding-bottom: 11px;} .a-pdT-12-t {padding-top: 12px;}.a-pdB-12 {padding-bottom: 12px;} .a-pdT-13-t {padding-top: 13px;}.a-pdB-13 {padding-bottom: 13px;} .a-pdT-14-t {padding-top: 14px;}.a-pdB-14 {padding-bottom: 14px;} .a-pdT-15-t {padding-top: 15px;}.a-pdB-15 {padding-bottom: 15px;} .a-pdT-16-t {padding-top: 16px;}.a-pdB-16 {padding-bottom: 16px;} .a-pdT-17-t {padding-top: 17px;}.a-pdB-17 {padding-bottom: 17px;} .a-pdT-18-t {padding-top: 18px;}.a-pdB-18 {padding-bottom: 18px;} .a-pdT-19-t {padding-top: 19px;}.a-pdB-19 {padding-bottom: 19px;} .a-pdT-20-t {padding-top: 20px;}.a-pdB-20 {padding-bottom: 20px;}
    /* padding top - bottom        22-38px      inc 2*/
    .a-pdT-22-t {padding-top: 22px;}.a-pdB-22 {padding-bottom: 22px;} .a-pdT-24-t {padding-top: 24px;}.a-pdB-24 {padding-bottom: 24px;} .a-pdT-26-t {padding-top: 26px;}.a-pdB-26 {padding-bottom: 26px;} .a-pdT-28-t {padding-top: 28px;}.a-pdB-28 {padding-bottom: 28px;} .a-pdT-30-t {padding-top: 30px;}.a-pdB-30 {padding-bottom: 30px;} .a-pdT-32-t {padding-top: 32px;}.a-pdB-32 {padding-bottom: 32px;} .a-pdT-34-t {padding-top: 34px;}.a-pdB-34 {padding-bottom: 34px;} .a-pdT-36-t {padding-top: 36px;}.a-pdB-36 {padding-bottom: 36px;} .a-pdT-38-t {padding-top: 38px;}.a-pdB-38 {padding-bottom: 38px;}
    /* padding bottom 40-190px inc 10 */
    .a-pdB-40-t {padding-bottom: 40px; } .a-pdB-50-t {padding-bottom: 50px; } .a-pdB-60-t {padding-bottom: 60px; } .a-pdB-70-t {padding-bottom: 70px; } .a-pdB-80-t {padding-bottom: 80px; } .a-pdB-90-t {padding-bottom: 90px; } .a-pdB-100-t {padding-bottom: 100px; } .a-pdB-110-t {padding-bottom: 110px; } .a-pdB-120-t {padding-bottom: 120px; } .a-pdB-130-t {padding-bottom: 130px; } .a-pdB-140-t {padding-bottom: 140px; } .a-pdB-150-t {padding-bottom: 150px; } .a-pdB-160-t {padding-bottom: 160px; } .a-pdB-170-t {padding-bottom: 170px; } .a-pdB-180-t {padding-bottom: 180px; } .a-pdB-190-t {padding-bottom: 190px; }
    /* % padding bottom 5-100% inc 5 */
    .a-pPdB-5-t {padding-bottom: 5%; } .a-pPdB-10-t {padding-bottom: 10%; } .a-pPdB-15-t {padding-bottom: 15%; } .a-pPdB-20-t {padding-bottom: 20%; } .a-pPdB-25-t {padding-bottom: 25%; } .a-pPdB-30-t {padding-bottom: 30%; } .a-pPdB-35-t {padding-bottom: 35%; } .a-pPdB-40-t {padding-bottom: 40%; }
    .a-pPdB-45-t {padding-bottom: 45%; } .a-pPdB-50-t {padding-bottom: 50%; } .a-pPdB-55-t {padding-bottom: 55%; } .a-pPdB-60-t {padding-bottom: 60%; } .a-pPdB-65-t {padding-bottom: 65%; } .a-pPdB-70-t {padding-bottom: 70%; } .a-pPdB-75-t {padding-bottom: 75%; } .a-pPdB-80-t {padding-bottom: 80%; }
    .a-pPdB-85-t {padding-bottom: 85%; } .a-pPdB-90-t {padding-bottom: 90%; } .a-pPdB-95-t {padding-bottom: 95%; } .a-pPdB-100-t {padding-bottom: 100%; }
    /* pdT 40-150px inc 10 */
    .a-pdT-40-t {padding-top: 40px;} .a-pdT-50-t {padding-top: 50px;} .a-pdT-60-t {padding-top: 60px;} .a-pdT-70-t {padding-top: 70px;} .a-pdT-80-t {padding-top: 80px;} .a-pdT-90-t {padding-top: 90px;} .a-pdT-100-t {padding-top: 100px;} .a-pdT-110-t {padding-top: 110px;} .a-pdT-120-t {padding-top: 120px;} .a-pdT-130-t {padding-top: 130px;} .a-pdT-140-t {padding-top: 140px;} .a-pdT-150-t {padding-top: 150px;}


  }



@media (max-width:734px) {
    /* padding top - bottom        0-20px      inc 1*/
    .a-pdT-0-s {padding-top: 0px!important;}.a-pdB-0 {padding-bottom: 0px!important;} .a-pdT-1-s {padding-top: 1px;}.a-pdB-1 {padding-bottom: 1px;} .a-pdT-2-s {padding-top: 2px;}.a-pdB-2 {padding-bottom: 2px;} .a-pdT-3-s {padding-top: 3px;}.a-pdB-3 {padding-bottom: 3px;} .a-pdT-4-s {padding-top: 4px;}.a-pdB-4 {padding-bottom: 4px;} .a-pdT-5-s {padding-top: 5px;}.a-pdB-5 {padding-bottom: 5px;} .a-pdT-6-s {padding-top: 6px;}.a-pdB-6 {padding-bottom: 6px;} .a-pdT-7-s {padding-top: 7px;}.a-pdB-7 {padding-bottom: 7px;} .a-pdT-8-s {padding-top: 8px;}.a-pdB-8 {padding-bottom: 8px;} .a-pdT-9-s {padding-top: 9px;}.a-pdB-9 {padding-bottom: 9px;} .a-pdT-10-s {padding-top: 10px;}.a-pdB-10 {padding-bottom: 10px;} .a-pdT-11-s {padding-top: 11px;}.a-pdB-11 {padding-bottom: 11px;} .a-pdT-12-s {padding-top: 12px;}.a-pdB-12 {padding-bottom: 12px;} .a-pdT-13-s {padding-top: 13px;}.a-pdB-13 {padding-bottom: 13px;} .a-pdT-14-s {padding-top: 14px;}.a-pdB-14 {padding-bottom: 14px;} .a-pdT-15-s {padding-top: 15px;}.a-pdB-15 {padding-bottom: 15px;} .a-pdT-16-s {padding-top: 16px;}.a-pdB-16 {padding-bottom: 16px;} .a-pdT-17-s {padding-top: 17px;}.a-pdB-17 {padding-bottom: 17px;} .a-pdT-18-s {padding-top: 18px;}.a-pdB-18 {padding-bottom: 18px;} .a-pdT-19-s {padding-top: 19px;}.a-pdB-19 {padding-bottom: 19px;} .a-pdT-20-s {padding-top: 20px;}.a-pdB-20 {padding-bottom: 20px;}
    /* padding top - bottom        22-38px      inc 2*/
    .a-pdT-22-s {padding-top: 22px;}.a-pdB-22 {padding-bottom: 22px;} .a-pdT-24-s {padding-top: 24px;}.a-pdB-24 {padding-bottom: 24px;} .a-pdT-26-s {padding-top: 26px;}.a-pdB-26 {padding-bottom: 26px;} .a-pdT-28-s {padding-top: 28px;}.a-pdB-28 {padding-bottom: 28px;} .a-pdT-30-s {padding-top: 30px;}.a-pdB-30 {padding-bottom: 30px;} .a-pdT-32-s {padding-top: 32px;}.a-pdB-32 {padding-bottom: 32px;} .a-pdT-34-s {padding-top: 34px;}.a-pdB-34 {padding-bottom: 34px;} .a-pdT-36-s {padding-top: 36px;}.a-pdB-36 {padding-bottom: 36px;} .a-pdT-38-s {padding-top: 38px;}.a-pdB-38 {padding-bottom: 38px;}
  /* padding bottom 40-190px inc 10 */
    .a-pdB-40-s {padding-bottom: 40px; } .a-pdB-50-s {padding-bottom: 50px; } .a-pdB-60-s {padding-bottom: 60px; } .a-pdB-70-s {padding-bottom: 70px; } .a-pdB-80-s {padding-bottom: 80px; } .a-pdB-90-s {padding-bottom: 90px; } .a-pdB-100-s {padding-bottom: 100px; }
    .a-pdB-110-s {padding-bottom: 110px; } .a-pdB-120-s {padding-bottom: 120px; } .a-pdB-130-s {padding-bottom: 130px; } .a-pdB-140-s {padding-bottom: 140px; } .a-pdB-150-s {padding-bottom: 150px; } .a-pdB-160-s {padding-bottom: 160px; } .a-pdB-170-s {padding-bottom: 170px; }
    .a-pdB-180-s {padding-bottom: 180px; } .a-pdB-190-s {padding-bottom: 190px; }
    /* padding bottom 200-500px inc 100 */
    .a-pdB-200-s {padding-bottom: 200px;} .a-pdB-300-s {padding-bottom: 300px;} .a-pdB-400-s {padding-bottom: 400px;} .a-pdB-500-s {padding-bottom: 500px;}
    /* % padding bottom 5-100% inc 5 */
    .a-pPdB-5-s {padding-bottom: 5%; } .a-pPdB-10-s {padding-bottom: 10%; } .a-pPdB-15-s {padding-bottom: 15%; } .a-pPdB-20-s {padding-bottom: 20%; } .a-pPdB-25-s {padding-bottom: 25%; } .a-pPdB-30-s {padding-bottom: 30%; } .a-pPdB-35-s {padding-bottom: 35%; } .a-pPdB-40-s {padding-bottom: 40%; }
    .a-pPdB-45-s {padding-bottom: 45%; } .a-pPdB-50-s {padding-bottom: 50%; } .a-pPdB-55-s {padding-bottom: 55%; } .a-pPdB-60-s {padding-bottom: 60%; } .a-pPdB-65-s {padding-bottom: 65%; } .a-pPdB-70-s {padding-bottom: 70%; } .a-pPdB-75-s {padding-bottom: 75%; }
    .a-pPdB-80-s {padding-bottom: 80%; } .a-pPdB-85-s {padding-bottom: 85%; } .a-pPdB-90-s {padding-bottom: 90%; } .a-pPdB-95-s {padding-bottom: 95%; } .a-pPdB-100-s {padding-bottom: 100%; }
    /* pdT 40-150px inc 10 */
    .a-pdT-40-s {padding-top: 40px;} .a-pdT-50-s {padding-top: 50px;} .a-pdT-60-s {padding-top: 60px;} .a-pdT-70-s {padding-top: 70px;} .a-pdT-80-s {padding-top: 80px;} .a-pdT-90-s {padding-top: 90px;} .a-pdT-100-s {padding-top: 100px;} .a-pdT-110-s {padding-top: 110px;} .a-pdT-120-s {padding-top: 120px;} .a-pdT-130-s {padding-top: 130px;} .a-pdT-140-s {padding-top: 140px;} .a-pdT-150-s {padding-top: 150px;}
}
