

.input-divN {border-radius: var(--bRadius); line-height: 1; border: var(--brd) solid var(--brdBlurCol);
  height: var(--height); }
.form-round-noLab.bordered .input-divN  {border: var(--brd) solid hsl(35,95%,51%)}
.input-divN-err {border-radius: var(--bRadius); line-height: 1; border: var(--brd) solid var(--brdErrCol);height: var(--height)}
.input-divN:hover, .input-divN:focus {border: var(--brd) solid var(--brdFocusCol)}
.form-round-noLab.bordered .input-divN:hover, .form-round-noLab.bordered  .input-divN:focus {border: var(--brd) solid hsl(35,95%,51%)!important}
/* Defines the covering label, the bigger label displayed wehn no value has been yet set */
.frm-cov-lab {padding: var(--covLabelPad); width: 100%; font-size: var(--covLabelSize); font-weight: var(--cLabWeight); text-align: var(--covLabelAlign);line-height: 1.1}

/* label */
.frm-label {display: block; font-size: var(--labelSize); text-align: var(--labelAlign); position: absolute;top: 0; left: 100; z-index: 2; width: 100%;
  padding: var(--labelTopPad) var(--labelContPad) var(--labelBotPad) var(--labelContPad); font-weight: var(--labWeight); color: var(--labelColor)}
