.a-0 {--a: 0.0}.a-1 {--a: 0.1}.a-2 {--a: 0.2}.a-3 {--a: 0.3}.a-4 {--a: 0.4}.a-5 {--a: 0.5}.a-6 {--a: 0.6}.a-7 {--a: 0.7}.a-8 {--a: 0.8}.a-9 {--a: 0.9}.a-10 {--a: 1}
.top-bar-hsla-bkg {--barBkg:  hsla(var(--h),var(--s),var(--l),var(--a, 1));}
.top-bar-no-bkg {--barBkg: transparent;}
.top-bar-size-def {--intraMargin:16px; --sidesMergins: 16px; --c-barWidth: min(1200px, 60vw);}

.top-bar-vars {--tBarHeight: var(--tBarHeight-l, 60px);}
.top-bar-top-padding {padding-top: var(--tBarHeight, 0)}
.top-bar-container {  background-color: var(--barBkg); height: var(--tBarHeight); width: 100%; z-index: 2000; position: relative; top:0; left: 0; transform: translate3d(0,0,0);}
.top-bar-fixed {position: fixed;}
.top-bar-absolute {position: absolute; }

.tbar-flex-cont {display: flex; height: 100%; flex-wrap: nowrap; align-content: center; justify-content: space-between;}
.top-bar-ov-1 {--tBar-overlay: .1}.top-bar-ov-2 {--tBar-overlay: .2}.top-bar-ov-3 {--tBar-overlay: .3}.top-bar-ov-4 {--tBar-overlay: .4}.top-bar-ov-5 {--tBar-overlay: .5}.top-bar-ov-6 {--tBar-overlay: .6}.top-bar-ov-7 {--tBar-overlay: .7}
.top-bar-container-overlay {content: "";  position: absolute;  height: 150%;  top: 0;  left: 0;  right: 0;  background-image: linear-gradient(180deg, rgba(0,0,0,var(--tBar-overlay, .4)) 33%, rgba(0,0,0,0));}
.tbar-mobile {display: none !important;}
.tbar-std {display: flex !important;}

@media only screen and (max-width: 1068px) {
  .top-bar-vars {--tBarHeight: var(--tBarHeight-m);}
  .top-bar-size-def {  --intraMargin:6px; --sidesMergins: 10px; --c-barWidth: min(1100px, 65vw); }
}

@media only screen and (max-width: 734px) {
  .top-bar-vars {--tBarHeight: var(--tBarHeight-s);}
  .top-bar-size-def {--intraMargin:4px; --sidesMergins: 4px;--c-barWidth: 50vw;}
  .tbar-mobile {display: flex !important}
  .tbar-std {display: none !important}
}

/*
linear-gradient(0deg,hsla(0,0%,100%,0),rgba(0,0,0,.5) 90%, rgba(0,0,0,.7) 100%)  HW


*/
