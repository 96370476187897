.hero-title-container {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: center; align-items: center; }
.hero-rot-h1 {padding: 0; margin: 0 0 0 0; line-height: 1.2; font-weight: 700; color: white; font-size: 42px; text-shadow: 0px 0 12px rgba(0,0,0,.7)}
.hero-rot-h3 {padding: 0; margin: 0; margin: 25px 0 0 0; line-height: 1.2; font-weight: 500; font-size: 25px; color: Snow; text-shadow: 0px 0 12px rgba(0,0,0,.7)}
.hero-content-width {/*width: var(--contentWidth) ;*/ margin: 0 auto; height: var(--frontCont);}
.hero-title-gradient {  width: 100%;  height: 100%;  position: absolute;  display: block;  bottom: 0;  left: 0;  z-index: 2;}
/* .backgroundgradient {background-image: linear-gradient(0deg,#000,rgba(0,0,0,0) 50%);} */
@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 734px) {
/*  .hero-title-container {max-width: 75vw;margin: 0 auto;}*/
  .hero-title-container h1 {font-size: 7.5vw; text-align: center;}
  .hero-title-container h2 { text-align: center;}
}

.hero-btm-title-container {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: flex-end; align-items: center; padding-bottom: 30px; text-align: center;}
.hero-btm-h1 {padding: 0; max-width: 800px; margin: 0 0 0 0; line-height: 1.1; font-weight: 700; color: white; font-size: 46px; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}
.hero-btm-h3 {padding: 0; max-width: 550px; margin: 0; margin: 15px 0 0 0; line-height: 1.3; font-weight: 500; font-size: 21px; color: Snow; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}
.hero-btm-gradient {background-image: linear-gradient(0deg,rgba(0,0,0,.9),rgba(0,0,0,0) 40%);}
.hero-top-title-container {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: flex-start; align-items: center; padding-top: calc(var(--tBarHeight) + 40px);text-align: center;}
.hero-top-h1 {padding: 0; margin: 0 0 0 0; max-width: 700px;line-height: 1.1; font-weight: 700; color: white; font-size: 46px; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}
.hero-top-h3 {padding: 0; margin: 0; max-width: 700px;margin: 15px 0 0 0; line-height: 1.2; font-weight: 500; font-size: 21px; color: Snow; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}
.hero-top-gradient {background-image: linear-gradient(180deg,rgba(0,0,0,.7) ,rgba(0,0,0,0) 50%);}
@media only screen and (max-width: 1568px) {
  .hero-btm-title-container {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: flex-end; align-items: center; padding-bottom: 3vh;}
  .hero-btm-h1 {padding: 0; margin: 0 0 0 0; line-height: 1.2; font-weight: 700; color: white; font-size: min(2.8vw,40px); text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}
  .hero-btm-h3 {padding: 0; margin: 0; margin: 5px 0 0 0; line-height: 1.2; font-weight: 500; font-size: min(1.5vw,23px); color: Snow; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}

  .hero-top-title-container {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: flex-start; align-items: center; padding-top: calc(var(--tBarHeight) + 30px);}
  .hero-top-h1 {padding: 0; margin: 0 0 0 0; line-height: 1.2; font-weight: 700; color: white; font-size: min(2.8vw,40px); text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}
  .hero-top-h3 {padding: 0; margin: 0; margin: 5px 0 0 0; line-height: 1.2; font-weight: 500; font-size: min(1.5vw,23px); color: Snow; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5}

}

@media only screen and (max-width: 734px)  {
  .hero-btm-title-container {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: flex-end; align-items: center; padding-bottom: 4vh; }
  .hero-btm-h1 {padding: 0; margin: 0 0 0 0; line-height: 1.2; font-weight: 700; color: white; font-size: 7vw; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5;max-width: 90vw;}
  .hero-btm-h3 {padding: 0; margin: 0; margin: 3px 0 0 0; line-height: 1.2; font-weight: 500; font-size: 4.2vw; color: snow; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5;max-width: 90vw;}
  .hero-btm-gradient {background-image: linear-gradient(0deg,rgba(0,0,0,.9),rgba(0,0,0,0) 45%);}
  .hero-top-title-container {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: flex-start; align-items: center; padding-top: calc(var(--tBarHeight) + 2.5vh); }
  .hero-top-h1 {padding: 0; margin: 0 0 0 0; line-height: 1.2; font-weight: 700;  font-size: 7vw; text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5;max-width: 90vw;color: white;}
  .hero-top-h3 {padding: 0; margin: 0; margin: 3px 0 0 0; line-height: 1.2; font-weight: 500; font-size: 4.2vw;  text-shadow: 0px 0 12px rgba(0,0,0,.7);z-index: 5;max-width: 90vw;color: snow;}
  .hero-top-gradient {background-image: linear-gradient(180deg,rgba(0,0,0,.7) ,rgba(0,0,0,0) 50%);}
}
