.cl-color-box {width: 160px; background-color: var(--hex); margin: 10px; padding: 12px; text-align: center; color: var(--btn-color);
   border-radius: 32px; }

.cl-colors-list-cont {background-color: white; border-radius: 8px; box-shadow: 1px 1px 1px rgba(0,0,0,.2);}
.cl-colors-list-title { font-weight: 600; font-size: 22px; color: dodgerblue ; }
.cl-list-header-cont {height: 40px; }
.cl-done-moving-info {border-radius: 10px 3px 3px 10px; border: 1px solid green; border-left: 4px solid green; background-color: HSL(120,100%,96%)}
.cl-list-sticky-header {position: sticky; top: 60px; background-color: rgba(255,255,255,.9); z-index: 20; }
.cl-list-hide-all {position: absolute;top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255,255,255,.9)}
.cl-header-border { box-shadow: 0 1px 4px rgba(0,0,0,.3);}
.color-viewEdit-container {height: auto; margin: 64px auto;}
.col-color-rect {width: 28%;height: 100%;border-radius: 8px}
.col-picker-container {width: 35%; position:relative;margin: 0 4%;}
.cl-list-hide-all {}
.col-color-info {width: 37%; margin: 0 4%;height: 100%; box-shadow: 0px 1px 2px  rgba(0,0,0,.2);background-color: white; padding: 16px}
.col-picker-open-btn {width: 31%; border-radius: 8px; box-shadow: 0px 1px 2px  rgba(0,0,0,.2); height: 100%; background-color: white}
.col-picker-close-btn {position: absolute; top: -35px; right: -35px}
.cl-picker-actions {position: absolute; top: 50%; left: 100%; margin-left: 48px;transform: translateY(-50%);}

.cl-col-info-det {font-size: 14px; font-weight: 400; color: rgb(110,110,110); margin-top: 10px}
.col-color-info-btnCol {margin: 12px 0; border-radius: 6px; padding: 8px 14px;font-size: 14px; font-weight: 400;}
.cl-color-code-cont {width: 32.3%; border-radius: 6px; padding: 8px 14px; background-color: #1010a3; position: relative;}
.cl-col-type {font-weight: 600; color: #fbb103; line-height:1.1;margin-bottom: 3px; font-size: 15px}
.cl-code-el-pad{padding-right: 2px; color: #19f306; font-weight: 600}
.cl-color-code-cont:hover {background-color: #1d1dd9}

.cl-classes-container {margin: 10px 0;}
.cl-class-el {width: 48.5%;  border-radius: 6px; padding: 10px 14px; background-color: #8f10df; margin-bottom: 8px; position: relative; cursor: pointer;}
.cl-class-title {font-size: 16px; font-weight: 600}
.cl-class-type {font-size: 13px; line-height: 1; font-weight: 700; color: #e4e42e}
.cl-class-name {font-size: 15px; margin-top: 3px; color: #eeeee9;line-height: 1; font-weight: 700}
.cl-class-el:hover {background-color: #a730f2}


.cl-men-list-item {box-shadow: 1px 1px 2px rgba(0,0,0,.2); border-radius: 8px; }
.cl-save-pos {position: fixed;bottom: 60px; right: 48px; z-index: 100}
@media (hover: hover) {
  .cl-var-title-elem:hover:not(.cl-var-title-selected) { border-bottom: 3px solid gray; }
  .cl-men-list-item:hover {background-color: cornsilk;}
}

@media (max-width: 734px) {
  .cl-color-box {width: calc((100% - 32px) / 2); background-color: var(--hex); margin: 8px; padding: 14px; text-align: center; color: var(--btn-color);
     border-radius: 8px; font-weight: 500}
.cl-save-pos { right: 16px; bottom: 24px;}
}

.form-large-noLab {
  /* container */   /**/
  --bRadius: calc(var(--height) * 0.15); --brdBot: none ; --brd:none; --height: 45px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 20px ; --covLabelSize: 16px; --cLabWeight: 500;
  /* label */
  --labelTopPad: 0px;--labelBotPad: 0; --labelContPad: 0px;  --labelSize: 0px; --labWeight: 700;
  --labelAlign: left; ; --labelCol: black;
  /* input */
  --inpContPad: 12px; --inpSize: 28px; --inpPadTop: 0px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 40px;
}

.form-col-info-def {
  --focusBkgColor: rgb(245, 245, 245); --focusColor: black; --errorBkgColor: #ffdddd; --errorColor: black; --blurBkgColor: inherit; --blurColor: black;
  --dropBkgColor: hsl(35,95%,63%);--labelColor: rgb(80,80,80); --dropColor: white;--dropBkgHoverColor: hsl(35,95%,69%); --dropHoverColor: white;
  /* Border botttom colors */
  --brdBotCol: blue;--brdBotErrCol:red;--brdBotFocusCol:green;
}

.cl-list-name-defs {
  /* container */   /**/
  --bRadius: calc(var(--height) * 0.15); --brdBot: none ; --brd:none; --height: 40px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
    /* convering label */
  --covLabelPad: 20px ; --covLabelSize: 16px; --cLabWeight: 500;
  /* label */
  --labelTopPad: 0px;--labelBotPad: 0; --labelContPad: 0px;  --labelSize: 0px; --labWeight: 700;
  --labelAlign: left; ; --labelCol: black;
  /* input */
  --inpContPad: 16px; --inpSize: 22px; --inpPadTop: 0px; --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 40px;
}
