
.c-bar {width: var(--c-barWidth); z-index: 5; position: relative; } /* To avoid overflowing from the side bars, z-index is set to a higher values than the side bars and the background and position relative are set*/
.t-bar-logo {height: 33px;width: auto; margin-right: 20px; overflow: hidden; margin-top: 8px;background-color: transparent !important; }
.logo-cont {height: auto; }
.tbar-center {display: flex; justify-content: space-between;align-items: center;flex-wrap: nowrap; height: 100%}
.tbar-center-mobile {display: none}

@media only screen and (max-width: 1068px) {
    .t-bar-logo {  }
}

@media only screen and (max-width: 734px) {

    .tbar-center {display: block;height: 100%;}
    .t-bar-logo { margin-right: 0; overflow: hidden; height: 30px; margin-top: 5px}


}

@media only screen and (max-width: 385px) {
  .t-bar-logo { margin-right: 0; overflow: hidden; height: 28px; margin-top: 4px}


}

@media only screen and (max-width: 335px) {
    .t-bar-logo { margin-right: 0; overflow: hidden; height: 25px; margin-top: 4px}


}
