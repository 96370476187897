.side-men-anim-next-1 {animation: side-men-anim-next-1 200ms; transform: translateX(-30%); -webkit-transform: translateX(-30%);}
.side-men-anim-prev-1 {animation: side-men-anim-prev-1 200ms;transform: translateX(0); -webkit-transform: translateX(0);}
.side-men-anim-next-2 {animation: side-men-anim-next-2 200ms; transform: translateX(0); -webkit-transform: translateX(0);}
.side-men-anim-prev-2 {animation: side-men-anim-prev-2 200ms ease-in;transform: translateX(100%); -webkit-transform: translateX(100%);}
.side-men-2 {transform: translateX(100%); -webkit-transform: translateX(100%);}
.side-menu-fad-opac{opacity: 0.1;}

@-webkit-keyframes side-men-anim-next-1 {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }

  to {
      -webkit-transform: translateX(-30%);
      transform: translateX(-30%);

  }
}

@keyframes side-men-anim-next-1 {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }

  to {
      -webkit-transform: translateX(-30%);
      transform: translateX(-30%);

  }
}

@-webkit-keyframes side-men-anim-next-2 {
  from {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);

  }
}

@keyframes side-men-anim-next-2 {
  from {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);

  }
}

@-webkit-keyframes side-men-anim-prev-1 {
  from {
      -webkit-transform: translateX(-30%);
      transform: translateX(-30%);

  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes side-men-anim-prev-1 {
  from {
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes side-men-anim-prev-2 {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);

  }

  to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
  }
}

@keyframes side-men-anim-prev-2 {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }

  to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
  }
}
