
.header-noImg {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    background: rgba(13, 117, 197, 0.92);;
}
.he-noImg-bkgImage {
    pointer-events: none;
    position: absolute;
    width: 100%;
    min-height: 320px;
    z-index: -1;
    left: 0;
}
@media only screen and (max-width: 860px) {
   .header-noImg {
        max-width: 100%;
        padding: 0 2%;
    }
}
.he-noImg-container {
    margin: 0 auto;
    max-width: 1140px;
    width: 100%;
    display: flex;
    padding: 40px 60px;
    color: #fff;
}
@media only screen and (max-width: 860px) {
    .he-noImg-container {
        padding: 20px;
    }
}

.he-noImg-left {
    flex-shrink: 0;
    flex-basis: 80%;
}

@media only screen and (max-width: 860px) {
    .he-noImg-left {
        flex-basis: 100%;
    }
}
.he-noImg-left h1 {
    color: #fff;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 30px;
    font-size: 42px;

}

@media only screen and (max-width: 600px) {
    .he-noImg-left h1 {
        font-size: 26px;
        line-height: 32px;

    }
}
.he-noImg-descr {
    display: inline-block;
    font-weight: 400;
    max-width: 800px;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 28px;
    min-height: 65px;
}

@media only screen and (max-width: 600px) {
    .he-noImg-descr {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 30px;
    }
}

.he-noImg-descr-date {
    font-size: 12px;

    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
}
