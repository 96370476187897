:root {--border: #0071c2; --background: #fff;--bkgHover: #ebf3ff;--selectedText:#fff;--drDwnText: #333;--drDwnBkg: #fff;--drDwnBkgHover: #f5f5f5}
.sort-bar {position: relative;background: var(--background);border-radius: 3px;border: none;box-sizing: border-box;}
.sort-bar ul.sort-bar-list-cont {display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin: 0;padding: 0;list-style: none;font-size: 12px;position: relative;padding-right: 42px;}
li.sort-bar-li:first-child {border-left-width: 1px;border-radius: 2px 0 0 2px;}
li.sort-bar-li:last-child {  border-right-width: 1px;  border-radius: 0 2px 2px 0;}
li.sort-bar-li {border: 1px solid var(--border);border-left-width: 0; -ms-flex-positive: 1;flex-grow: 1;position: relative;display: block;text-align: center;box-sizing: border-box;font-size: 14px;font-weight: 400;line-height: 20px;}
li.selected {background: var(--border)}
.sort-bar-text {display: block;text-decoration: none;white-space: nowrap;font-weight: 400;padding: 7px 12px;color: var(--border);cursor: pointer; height: 100%}
.sort-bar-text:hover, .drDwn-more .icon-drop:hover {background-color: var(--bkgHover)}
li.selected .sort-bar-text {color: var(--selectedText);  }
li.selected .sort-bar-text:hover {background: transparent}
li.sort-bar-drDwn {position: relative;}
li.drDwn-more {position: absolute;  top: 0;  right: 0; width: 42px;  height: 100%; -ms-flex-negative: 0;  flex-shrink: 0;  -ms-flex-positive: 0;  flex-grow: 0;}
li.sort-bar-drDwn ul.drop-menu{ position: absolute;bottom: 0;right: -1px; min-width: 200px; transform: translateY(100%);padding: 4px 0;background: var(--drDwnBkg);  border-radius: 2px;  overflow: hidden;  border: none;z-index: 100;
  box-shadow: 0 1px 8px 0 rgba(0,0,0,.12),0 2px 3px -1px rgba(0,0,0,.2); margin: 0; list-style: none; font-size:  12px; display: none;}
li.sort-bar-drDwn:hover ul.drop-menu {display: block;}
li.sort-bar-drDwn ul.drop-menu li {display: block; text-align: left; box-sizing: border-box;font-size: 14px;font-weight: 400;line-height: 20px;}
li.sort-bar-drDwn ul.drop-menu li .drop-row {padding: 12px 16px; color: var(--drDwnText);background-color: var(--drDwnBkg);display: block;text-decoration: none;white-space: nowrap;font-weight: 400;cursor: pointer;}
li.sort-bar-drDwn ul.drop-menu li .drop-row:hover {  background: var(--drDwnBkgHover);outline: none;}
li.sort-bar-drDwn ul.drop-menu li:last-child .drop-row {border-bottom: 0}
