
/* Colors , border */
/*.a-btn-bordered {border: var(--brdSize) solid hsl(var(--h),var(--s),93%); color:  hsl(var(--h),var(--s),93%); background-color: inherit; }
.a-btn-bordered:hover {border-color: hsl(var(--h),var(--s),83%); color: hsl(var(--h),var(--s),83%);}
.a-btn-bordered .material-icons {color: hsl(var(--h),var(--s),93%)!important; }
.a-btn-bordered:hover .material-icons{color: hsl(var(--h),var(--s),83%)!important; }
*/

.l-hov-1 {--l-hov: 1%}.l-hov-2 {--l-hov: 2%}.l-hov-3 {--l-hov: 3%}.l-hov-4 {--l-hov: 4%}.l-hov-5 {--l-hov: 5%}.l-hov-6 {--l-hov: 6%}.l-hov-7 {--l-hov: 7%}.l-hov-8 {--l-hov: 8%}.l-hov-9 {--l-hov: 9%}.l-hov-10 {--l-hov: 10%}
.l-hov-11 {--l-hov: 11%}.l-hov-12 {--l-hov: 12%}.l-hov-13 {--l-hov: 13%}.l-hov-14 {--l-hov: 14%}.l-hov-15 {--l-hov: 15%}.l-hov-16 {--l-hov: 16%}.l-hov-17 {--l-hov: 17%}.l-hov-18 {--l-hov: 18%}.l-hov-19 {--l-hov: 19%}.l-hov-20 {--l-hov: 20%}

.icon-btn-color {fill: var(--iconColor);}

.a-btn-bordered, .a-btn-bordered-dark {border: var(--brdSize, 1px) solid hsla(var(--h),var(--s),var(--l), var(--a, 1));
  color: hsla(var(--h),var(--s),var(--l), var(--a, 1)); --iconColor: hsla(var(--h),var(--s),var(--l), var(--a, 1));}

.a-btn-bordered-whiteBkg {border: var(--brdSize, 1px) solid hsla(var(--h),var(--s),var(--l), var(--a, 1)); background-color: white;
                          color:  hsla(var(--h),var(--s),var(--l), var(--a, 1));--iconColor: hsla(var(--h),var(--s),var(--l), var(--a, 1));}
.a-bkg-transparent {background-color: transparent;}
.a-btn-bordOnHover {border: var(--brdSize, 1px) solid transparent;color: var(--btn-color);--iconColor:var(--btn-color); }
.a-btn-bordOnHover .a-btn-tooltip {bottom: -7px;}

.a-btn-textOnHover {border: none; color: var(--btn-color); --iconColor:var(--btn-color);background-color: transparent;}
.a-btn-textOnHover .a-btn-tooltip {bottom: 0;}

.a-btn-filled, .a-btn-filled-dark {border: none;background-color: hsla(var(--h),var(--s),var(--l), var(--a, 1)); color: var(--btn-color); --iconColor:var(--btn-color);}
.a-btn-filled.no-filled-bkg, .a-btn-filled-dark.no-filled-bkg {background-color: transparent}

.a-btn-opac {opacity: 0.4}

.grad-1 {--btn-color: white;--grad-dir: 90deg;--grad-dir-hover: 270deg;--col1:red;--col2:green;--col3:blue;}
.r-grad-1 {--btn-color: white;--col1:red;--col2:pink;--col3:yellow;--stop1:35%;--stop2:100%;--stop3:100%;--stop1-hover:75%;}
.a-btn-2-lGrad, .a-btn-3-lGrad, .a-btn-2-rGrad, .a-btn-3-rGrad {border: none;color: var(--btn-color); --iconColor:var(--btn-color);}
.a-btn-3-lGrad { background: linear-gradient(var(--grad-dir, to right), var(--col1), var(--col2), var(--col3));}
.a-btn-2-lGrad { background: linear-gradient(var(--grad-dir, to right), var(--col1), var(--col2));}
.a-btn-3-rGrad { background: radial-gradient(var(--col1) var(--stop1), var(--col2) var(--stop2), var(--col3) var(--stop3));}
.a-btn-2-rGrad { background: radial-gradient(var(--col1) var(--stop1), var(--col2) var(--stop2));}

.a-btn-HW {border: var(--brdSize, 1px) solid transparent;background-color: rgba(0,0,0,.3); color: white; --iconColor:white;}
.a-btn-HW .a-btn-tooltip {bottom: -7px;}

/*
a-btn-bordered   -> text and border have the same color, on hover the luminosity is increased ( or reduced if -dark); background can be added as a class separately
      text and border: (--h,--s,--l,--a)
      hover opacity: --l-hov for the hover incresead opacity default is 10%
          (.a-btn-bordered-dark  same as before but on hover the opacity in reduced)
      background: transparent unless added with a separate class

.a-btn-bordered-whiteBkg: text and border have the same color, bkg is white, on hover bkg becomes a very light version of text and border color, same h,s value but very hight l; text and border should have a low l value, because on hover bkg should have a very high l to be almost white
      text and border: (--h,--s,--l,--a)
      bkg white
      bkg howr --l-hov -> bagground set to (--h,--s, 100 - llhov)

.a-btn-bordOnHover -> text and border have the same color but the border is displayed only on hover
    text and border: --btn-color
    backgrond: trasnparent unless added with a separate class
.a-btn-textOnHover -> text color changes on hover. Background not defined here, can be added separately as a class
    text: --btn-color
    text hover: --btn-colorH
    backgrond: trasnparent unless added with a separate class

.a-btn-filled, .a-btn-filled-dark -> background luminosity is increased on hover (or decreased if .dark)
    text: --btn-color
    background: (--h,--s,--l,--a) for the background + --l-hov for the hover incresead opacity default is 10%
    hover opacity: --l-hov
*/


@media (hover: hover) {
    .a-btn-bordered:hover:not(.no-hover) {border-color: hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1));
      color: hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1));--iconColor:hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1));}
    .a-btn-bordered-dark:hover:not(.no-hover) {border-color: hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1));
      color: hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1));--iconColor:hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1));}
    .a-btn-bordered-whiteBkg:hover:not(.no-hover) {background-color: hsla(var(--h),var(--s),var(--l-hov, 10%), var(--a, 1));}
    .a-btn-bordOnHover:hover:not(.no-hover) {border: var(--brdSize, 1px) solid var(--btn-color); }
    .a-btn-textOnHover:hover:not(.no-hover) {color: var(--btn-colorH);--iconColor:var(--btn-colorH);}
    .a-btn-filled:hover:not(.no-hover) {background-color:hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1)); }
    .a-btn-filled:hover:not(.no-hover).a-btn-black {background-color:hsla(var(--h),var(--s),calc(var(--l) + 20%), var(--a, 1)); }
    .a-btn-filled-dark:hover:not(.no-hover) {background-color:hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1))}
    .a-btn-3-lGrad:hover:not(.no-hover) { background: linear-gradient(var(--grad-dir-hover, to right), var(--col1), var(--col2), var(--col3));}
    .a-btn-2-lGrad:hover:not(.no-hover) { background: linear-gradient(var(--grad-dir-hover, to right), var(--col1), var(--col2));}
    .a-btn-3-rGrad:hover:not(.no-hover) { background: radial-gradient(var(--col1) var(--stop1-hover, var(--stop1,0%)), var(--col2) var(--stop2-hover, var(--stop2,50%)), var(--col3) var(--stop3-hover, var(--stop3,100%)));}
    .a-btn-2-rGrad:hover:not(.no-hover) { background: radial-gradient(var(--col1) var(--stop1-hover, var(--stop1,0%)), var(--col2) var(--stop2-hover, var(--stop2,50%)));}
    .a-btn-HW:hover:not(.no-hover) {border: var(--brdSize, 1px) solid #a9afbc;}
}

.a-btn-bordered.force-hover {border-color: hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1));
  color: hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1));--iconColor:hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1));}
.a-btn-bordered-dark.force-hover {border-color: hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1));
  color: hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1));--iconColor:hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1));}
.a-btn-bordered-whiteBkg.force-hover {background-color: hsla(var(--h),var(--s),calc(100% - var(--l-hov, 10%)), var(--a, 1));}
.a-btn-bordOnHover.force-hover {border: var(--brdSize, 1px) solid var(--btn-color); }
.a-btn-textOnHover.force-hover {color: var(--btn-colorH);--iconColor:var(--btn-colorH);}
.a-btn-filled.force-hover {background-color:hsla(var(--h),var(--s),calc(var(--l) + var(--l-hov, 10%)), var(--a, 1)); }
.a-btn-filled.a-btn-black.force-hover {background-color:hsla(var(--h),var(--s),calc(var(--l) + 20%), var(--a, 1)); }
.a-btn-filled-dark.force-hover {background-color:hsla(var(--h),var(--s),calc(var(--l) - var(--l-hov, 10%)), var(--a, 1))}
.a-btn-3-lGrad.force-hover { background: linear-gradient(var(--grad-dir-hover, to right), var(--col1), var(--col2), var(--col3));}
.a-btn-2-lGrad.force-hover { background: linear-gradient(var(--grad-dir-hover, to right), var(--col1), var(--col2));}
.a-btn-3-rGrad.force-hover { background: radial-gradient(var(--col1) var(--stop1-hover, var(--stop1,0%)), var(--col2) var(--stop2-hover, var(--stop2,50%)), var(--col3) var(--stop3-hover, var(--stop3,100%)));}
.a-btn-2-rGrad:.force-hover { background: radial-gradient(var(--col1) var(--stop1-hover, var(--stop1,0%)), var(--col2) var(--stop2-hover, var(--stop2,50%)));}
.a-btn-HW.force-hover {border: var(--brdSize, 1px) solid #a9afbc;}
