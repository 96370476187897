.feed-content-wrapper {
    font-family:  Arial, Sans-Serif;
    color: #666;
    font-size: 14px;
    font-weight: 300;
    margin: 0 auto;
    padding: 0;
    background: #fff;
    margin-bottom:  10px;
    width: 100%;
    

}
.feed-content-wrapper ul {margin: 0; padding: 0; list-style-type: none; display: block;}
.feed-header {
    background: #f2f2f2;
    background-color: rgb(204, 0, 0);
    margin: 0;
    position: relative;
    color: #404040; color: white;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
    font-size: 16px;
    line-height: 1.375;
    font-weight: 700;
    padding: 7px 7px 7px 7px;
}
/*
.feed-content-wrapper:hover  {
    background-color: rgba(230, 230,230, 0.3);
    background-color: hsl(211, 75%, 96%);

}
.feed-content-wrapper:hover .feed-header {


}
*/
.feed-content-wrapper ul li {
    color: #000;
    font-size: 15px;
    font-weight: 200;
    padding: 7px;
    transition: all .3s;
    display: block;
    line-height: 1.3333333333;
    border-top: 1px solid #d9d9d9;
    cursor: pointer;
}

.feed-content-wrapper li:hover {
    color: #cc0000;
    text-decoration: none;
    font-weight: 500;
}
