  /* height 0-30px  inc 1 */
  .a-height-0 {height: 0px !important; --height: 0px !important;}.a-height-1 {height: 1px; --height: 1px;}.a-height-2 {height: 2px; --height: 2px;}.a-height-3 {height: 3px; --height: 3px;}.a-height-4 {height: 4px; --height: 4px;}.a-height-5 {height: 5px; --height: 5px;}.a-height-6 {height: 6px; --height: 6px;}.a-height-7 {height: 7px; --height: 7px;}.a-height-8 {height: 8px; --height: 8px;}.a-height-9 {height: 9px; --height: 9px;}.a-height-10 {height: 10px; --height: 10px;}.a-height-11 {height: 11px; --height: 11px;}.a-height-12 {height: 12px; --height: 12px;}.a-height-13 {height: 13px; --height: 13px;}.a-height-14 {height: 14px; --height: 14px;}.a-height-15 {height: 15px; --height: 15px;}.a-height-16 {height: 16px; --height: 16px;}.a-height-17 {height: 17px; --height: 17px;}.a-height-18 {height: 18px; --height: 18px;}.a-height-19 {height: 19px; --height: 19px;}.a-height-20 {height: 20px; --height: 20px;}.a-height-21 {height: 21px; --height: 21px;}.a-height-22 {height: 22px; --height: 22px;}.a-height-23 {height: 23px; --height: 23px;}.a-height-24 {height: 24px; --height: 24px;}.a-height-25 {height: 25px; --height: 25px;}.a-height-26 {height: 26px; --height: 26px;}.a-height-27 {height: 27px; --height: 27px;}.a-height-28 {height: 28px; --height: 28px;}.a-height-29 {height: 29px; --height: 29px;}.a-height-30 {height: 30px; --height: 30px;}
  /* height 32-60px  inc 2 */
  .a-height-32 {height: 32px; --height: 32px;}.a-height-34 {height: 34px; --height: 34px;}.a-height-36 {height: 36px; --height: 36px;}.a-height-38 {height: 38px; --height: 38px;}.a-height-40 {height: 40px; --height: 40px;}.a-height-42 {height: 42px; --height: 42px;}.a-height-44 {height: 44px; --height: 44px;}.a-height-46 {height: 46px; --height: 46px;}.a-height-48 {height: 48px; --height: 48px;}.a-height-50 {height: 50px; --height: 50px;}.a-height-52 {height: 52px; --height: 52px;}.a-height-54 {height: 54px; --height: 54px;}.a-height-56 {height: 56px; --height: 56px;}.a-height-58 {height: 58px; --height: 58px;}.a-height-60 {height: 60px; --height: 60px;}
  /* height 65-100px  inc 5 */
  .a-height-65 {height: 65px; --height: 65px;}.a-height-70 {height: 70px; --height: 70px;}.a-height-75 {height: 75px; --height: 75px;}.a-height-80 {height: 80px; --height: 80px;}.a-height-85 {height: 85px; --height: 85px;}.a-height-90 {height: 90px; --height: 90px;}.a-height-95 {height: 95px; --height: 95px;}.a-height-100 {height: 100px; --height: 100px;}
  /* height 100-200px  inc 10 */
  .a-height-110 {height: 110px; --height: 110px;}.a-height-120 {height: 120px; --height: 120px;}.a-height-130 {height: 130px; --height: 130px;}.a-height-140 {height: 140px; --height: 140px;}.a-height-150 {height: 150px; --height: 150px;}.a-height-160 {height: 160px; --height: 160px;}.a-height-170 {height: 170px; --height: 170px;}.a-height-180 {height: 180px; --height: 180px;}.a-height-190 {height: 190px; --height: 190px;}
  /* height 200-500px  inc 50 */
  .a-height-200 {height: 200px; --height: 200px;}.a-height-250 {height: 250px; --height: 250px;}.a-height-300 {height: 300px; --height: 300px;}.a-height-350 {height: 350px; --height: 350px;}.a-height-400 {height: 400px; --height: 400px;}.a-height-450 {height: 450px; --height: 450px;}.a-height-500 {height: 500px; --height: 500px;}
  /* height 0-200vh  inc 10 */
  .a-vHeight-10 {height: 10vh; --height: 10vh;}.a-vHeight-20 {height: 20vh; --height: 20vh;}.a-vHeight-30 {height: 30vh; --height: 30vh;}.a-vHeight-40 {height: 40vh; --height: 40vh;}.a-vHeight-50 {height: 50vh; --height: 50vh;}.a-vHeight-60 {height: 60vh; --height: 60vh;}.a-vHeight-70 {height: 70vh; --height: 70vh;}.a-vHeight-80 {height: 80vh; --height: 80vh;}.a-vHeight-90 {height: 90vh; --height: 90vh;}.a-vHeight-100 {height: 100vh; --height: 100vh;}.a-vHeight-110 {height: 110vh; --height: 110vh;}.a-vHeight-120 {height: 120vh; --height: 120vh;}.a-vHeight-130 {height: 130vh; --height: 130vh;}.a-vHeight-140 {height: 140vh; --height: 140vh;}.a-vHeight-150 {height: 150vh; --height: 150vh;}.a-vHeight-160 {height: 160vh; --height: 160vh;}.a-vHeight-170 {height: 170vh; --height: 170vh;}.a-vHeight-180 {height: 180vh; --height: 180vh;}.a-vHeight-190 {height: 190vh; --height: 190vh;}.a-vHeight-200 {height: 200vh; --height: 200vh;}
  /* height 220-400vh  inc 20 */
  .a-vHeight-220 {height: 220vh; --height: 220vh;}.a-vHeight-240 {height: 240vh; --height: 240vh;}.a-vHeight-260 {height: 260vh; --height: 260vh;}.a-vHeight-280 {height: 280vh; --height: 280vh;}.a-vHeight-300 {height: 300vh; --height: 300vh;}.a-vHeight-320 {height: 320vh; --height: 320vh;}.a-vHeight-340 {height: 340vh; --height: 340vh;}.a-vHeight-360 {height: 360vh; --height: 360vh;}.a-vHeight-380 {height: 380vh; --height: 380vh;}.a-vHeight-400 {height: 400vh; --height: 400vh;}
  /* height 450-700vh  inc 50 */
  .a-vHeight-450 {height: 450vh; --height: 450vh;}.a-vHeight-500 {height: 500vh; --height: 500vh;}.a-vHeight-550 {height: 550vh; --height: 550vh;}.a-vHeight-600 {height: 600vh; --height: 600vh;}.a-vHeight-650 {height: 650vh; --height: 650vh;}.a-vHeight-700 {height: 700vh; --height: 700vh;}
  /* height 5-100% inc 5  + 33 and 66*/
  .a-pHeight-5 {height: 5%; --height: 5%;}.a-pHeight-10 {height: 10%; --height: 10%;}.a-pHeight-15 {height: 15%; --height: 15%;}.a-pHeight-20 {height: 20%; --height: 20%;}.a-pHeight-25 {height: 25%; --height: 25%;}.a-pHeight-30 {height: 30%; --height: 30%;}.a-pHeight-35 {height: 35%; --height: 35%;}.a-pHeight-40 {height: 40%; --height: 40%;}.a-pHeight-45 {height: 45%; --height: 45%;}.a-pHeight-50 {height: 50%; --height: 50%;}.a-pHeight-55 {height: 55%; --height: 55%;}.a-pHeight-60 {height: 60%; --height: 60%;}.a-pHeight-65 {height: 65%; --height: 65%;}.a-pHeight-70 {height: 70%; --height: 70%;}.a-pHeight-75 {height: 75%; --height: 75%;}.a-pHeight-80 {height: 80%; --height: 80%;}.a-pHeight-85 {height: 85%; --height: 85%;}.a-pHeight-90 {height: 90%; --height: 90%;}.a-pHeight-95 {height: 95%; --height: 95%;}.a-pHeight-100 {height: 100%; --height: 100%;}
  .a-pHeight-33 {height: 33.33%; --height: 33.33%;}.a-pHeight-66 {height: 66.66%; --height: 66.66%;}
@media (max-width:1200px) {
  /* height 0-30px  inc 1 */
  .a-height-0-t {height: 0px !important; --height: 0px !important;} .a-height-1-t {height: 1px; --height: 1px;} .a-height-2-t {height: 2px; --height: 2px;} .a-height-3-t {height: 3px; --height: 3px;} .a-height-4-t {height: 4px; --height: 4px;} .a-height-5-t {height: 5px; --height: 5px;} .a-height-6-t {height: 6px; --height: 6px;} .a-height-7-t {height: 7px; --height: 7px;} .a-height-8-t {height: 8px; --height: 8px;} .a-height-9-t {height: 9px; --height: 9px;} .a-height-10-t {height: 10px; --height: 10px;} .a-height-11-t {height: 11px; --height: 11px;} .a-height-12-t {height: 12px; --height: 12px;} .a-height-13-t {height: 13px; --height: 13px;} .a-height-14-t {height: 14px; --height: 14px;} .a-height-15-t {height: 15px; --height: 15px;} .a-height-16-t {height: 16px; --height: 16px;} .a-height-17-t {height: 17px; --height: 17px;} .a-height-18-t {height: 18px; --height: 18px;} .a-height-19-t {height: 19px; --height: 19px;} .a-height-20-t {height: 20px; --height: 20px;} .a-height-21-t {height: 21px; --height: 21px;} .a-height-22-t {height: 22px; --height: 22px;} .a-height-23-t {height: 23px; --height: 23px;} .a-height-24-t {height: 24px; --height: 24px;} .a-height-25-t {height: 25px; --height: 25px;} .a-height-26-t {height: 26px; --height: 26px;} .a-height-27-t {height: 27px; --height: 27px;} .a-height-28-t {height: 28px; --height: 28px;} .a-height-29-t {height: 29px; --height: 29px;} .a-height-30-t {height: 30px; --height: 30px;}
  /* height 32-60px  inc 2 */
  .a-height-32-t {height: 32px; --height: 32px;} .a-height-34-t {height: 34px; --height: 34px;} .a-height-36-t {height: 36px; --height: 36px;} .a-height-38-t {height: 38px; --height: 38px;} .a-height-40-t {height: 40px; --height: 40px;} .a-height-42-t {height: 42px; --height: 42px;} .a-height-44-t {height: 44px; --height: 44px;} .a-height-46-t {height: 46px; --height: 46px;} .a-height-48-t {height: 48px; --height: 48px;} .a-height-50-t {height: 50px; --height: 50px;} .a-height-52-t {height: 52px; --height: 52px;} .a-height-54-t {height: 54px; --height: 54px;} .a-height-56-t {height: 56px; --height: 56px;} .a-height-58-t {height: 58px; --height: 58px;} .a-height-60-t {height: 60px; --height: 60px;}
  /* height 65-100px  inc 5 */
  .a-height-65-t {height: 65px; --height: 65px;} .a-height-70-t {height: 70px; --height: 70px;} .a-height-75-t {height: 75px; --height: 75px;} .a-height-80-t {height: 80px; --height: 80px;} .a-height-85-t {height: 85px; --height: 85px;} .a-height-90-t {height: 90px; --height: 90px;} .a-height-95-t {height: 95px; --height: 95px;} .a-height-100-t {height: 100px; --height: 100px;}
  /* height 100-200px  inc 10 */
  .a-height-110-t {height: 110px; --height: 110px;} .a-height-120-t {height: 120px; --height: 120px;} .a-height-130-t {height: 130px; --height: 130px;} .a-height-140-t {height: 140px; --height: 140px;} .a-height-150-t {height: 150px; --height: 150px;} .a-height-160-t {height: 160px; --height: 160px;} .a-height-170-t {height: 170px; --height: 170px;} .a-height-180-t {height: 180px; --height: 180px;} .a-height-190-t {height: 190px; --height: 190px;}
  /* height 200-500px  inc 50 */
  .a-height-200-t {height: 200px; --height: 200px;} .a-height-250-t {height: 250px; --height: 250px;} .a-height-300-t {height: 300px; --height: 300px;} .a-height-350-t {height: 350px; --height: 350px;} .a-height-400-t {height: 400px; --height: 400px;} .a-height-450-t {height: 450px; --height: 450px;} .a-height-500-t {height: 500px; --height: 500px;}
  /* height 0-200vh  inc 10 */
  .a-vHeight-10-t {height: 10vh; --height: 10vh;} .a-vHeight-20-t {height: 20vh; --height: 20vh;} .a-vHeight-30-t {height: 30vh; --height: 30vh;} .a-vHeight-40-t {height: 40vh; --height: 40vh;} .a-vHeight-50-t {height: 50vh; --height: 50vh;} .a-vHeight-60-t {height: 60vh; --height: 60vh;} .a-vHeight-70-t {height: 70vh; --height: 70vh;} .a-vHeight-80-t {height: 80vh; --height: 80vh;} .a-vHeight-90-t {height: 90vh; --height: 90vh;} .a-vHeight-100-t {height: 100vh; --height: 100vh;} .a-vHeight-110-t {height: 110vh; --height: 110vh;} .a-vHeight-120-t {height: 120vh; --height: 120vh;} .a-vHeight-130-t {height: 130vh; --height: 130vh;} .a-vHeight-140-t {height: 140vh; --height: 140vh;} .a-vHeight-150-t {height: 150vh; --height: 150vh;} .a-vHeight-160-t {height: 160vh; --height: 160vh;} .a-vHeight-170-t {height: 170vh; --height: 170vh;} .a-vHeight-180-t {height: 180vh; --height: 180vh;} .a-vHeight-190-t {height: 190vh; --height: 190vh;} .a-vHeight-200-t {height: 200vh; --height: 200vh;}
  /* height 220-400vh  inc 20 */
  .a-vHeight-220-t {height: 220vh; --height: 220vh;} .a-vHeight-240-t {height: 240vh; --height: 240vh;} .a-vHeight-260-t {height: 260vh; --height: 260vh;} .a-vHeight-280-t {height: 280vh; --height: 280vh;} .a-vHeight-300-t {height: 300vh; --height: 300vh;} .a-vHeight-320-t {height: 320vh; --height: 320vh;} .a-vHeight-340-t {height: 340vh; --height: 340vh;} .a-vHeight-360-t {height: 360vh; --height: 360vh;} .a-vHeight-380-t {height: 380vh; --height: 380vh;} .a-vHeight-400-t {height: 400vh; --height: 400vh;}
  /* height 450-700vh  inc 50 */
  .a-vHeight-450-t {height: 450vh; --height: 450vh;} .a-vHeight-500-t {height: 500vh; --height: 500vh;} .a-vHeight-550-t {height: 550vh; --height: 550vh;} .a-vHeight-600-t {height: 600vh; --height: 600vh;} .a-vHeight-650-t {height: 650vh; --height: 650vh;} .a-vHeight-700-t {height: 700vh; --height: 700vh;}
  /* height 5-100% inc 5  + 33 and 66*/
  .a-pHeight-5-t {height: 5%; --height: 5%;} .a-pHeight-10-t {height: 10%; --height: 10%;} .a-pHeight-15-t {height: 15%; --height: 15%;} .a-pHeight-20-t {height: 20%; --height: 20%;} .a-pHeight-25-t {height: 25%; --height: 25%;} .a-pHeight-30-t {height: 30%; --height: 30%;} .a-pHeight-35-t {height: 35%; --height: 35%;} .a-pHeight-40-t {height: 40%; --height: 40%;} .a-pHeight-45-t {height: 45%; --height: 45%;} .a-pHeight-50-t {height: 50%; --height: 50%;} .a-pHeight-55-t {height: 55%; --height: 55%;} .a-pHeight-60-t {height: 60%; --height: 60%;} .a-pHeight-65-t {height: 65%; --height: 65%;} .a-pHeight-70-t {height: 70%; --height: 70%;} .a-pHeight-75-t {height: 75%; --height: 75%;} .a-pHeight-80-t {height: 80%; --height: 80%;} .a-pHeight-85-t {height: 85%; --height: 85%;} .a-pHeight-90-t {height: 90%; --height: 90%;} .a-pHeight-95-t {height: 95%; --height: 95%;} .a-pHeight-100-t {height: 100%; --height: 100%;}
  .a-pHeight-33-t {height: 33.33%; --height: 33.33%;}.a-pHeight-66 {height: 66.66%; --height: 66.66%;}

}

@media (max-width:734px) {
  /* height 0-30px  inc 1 */
  .a-height-0-s {height: 0px !important; --height: 0px !important;} .a-height-1-s {height: 1px; --height: 1px;} .a-height-2-s {height: 2px; --height: 2px;} .a-height-3-s {height: 3px; --height: 3px;} .a-height-4-s {height: 4px; --height: 4px;} .a-height-5-s {height: 5px; --height: 5px;} .a-height-6-s {height: 6px; --height: 6px;} .a-height-7-s {height: 7px; --height: 7px;} .a-height-8-s {height: 8px; --height: 8px;} .a-height-9-s {height: 9px; --height: 9px;} .a-height-10-s {height: 10px; --height: 10px;} .a-height-11-s {height: 11px; --height: 11px;} .a-height-12-s {height: 12px; --height: 12px;} .a-height-13-s {height: 13px; --height: 13px;} .a-height-14-s {height: 14px; --height: 14px;} .a-height-15-s {height: 15px; --height: 15px;} .a-height-16-s {height: 16px; --height: 16px;} .a-height-17-s {height: 17px; --height: 17px;} .a-height-18-s {height: 18px; --height: 18px;} .a-height-19-s {height: 19px; --height: 19px;} .a-height-20-s {height: 20px; --height: 20px;} .a-height-21-s {height: 21px; --height: 21px;} .a-height-22-s {height: 22px; --height: 22px;} .a-height-23-s {height: 23px; --height: 23px;} .a-height-24-s {height: 24px; --height: 24px;} .a-height-25-s {height: 25px; --height: 25px;} .a-height-26-s {height: 26px; --height: 26px;} .a-height-27-s {height: 27px; --height: 27px;} .a-height-28-s {height: 28px; --height: 28px;} .a-height-29-s {height: 29px; --height: 29px;} .a-height-30-s {height: 30px; --height: 30px;}
  /* height 32-60px  inc 2 */
  .a-height-32-s {height: 32px; --height: 32px;} .a-height-34-s {height: 34px; --height: 34px;} .a-height-36-s {height: 36px; --height: 36px;} .a-height-38-s {height: 38px; --height: 38px;} .a-height-40-s {height: 40px; --height: 40px;} .a-height-42-s {height: 42px; --height: 42px;} .a-height-44-s {height: 44px; --height: 44px;} .a-height-46-s {height: 46px; --height: 46px;} .a-height-48-s {height: 48px; --height: 48px;} .a-height-50-s {height: 50px; --height: 50px;} .a-height-52-s {height: 52px; --height: 52px;} .a-height-54-s {height: 54px; --height: 54px;} .a-height-56-s {height: 56px; --height: 56px;} .a-height-58-s {height: 58px; --height: 58px;} .a-height-60-s {height: 60px; --height: 60px;}
  /* height 65-100px  inc 5 */
  .a-height-65-s {height: 65px; --height: 65px;} .a-height-70-s {height: 70px; --height: 70px;} .a-height-75-s {height: 75px; --height: 75px;} .a-height-80-s {height: 80px; --height: 80px;} .a-height-85-s {height: 85px; --height: 85px;} .a-height-90-s {height: 90px; --height: 90px;} .a-height-95-s {height: 95px; --height: 95px;} .a-height-100-s {height: 100px; --height: 100px;}
  /* height 100-200px  inc 10 */
  .a-height-110-s {height: 110px; --height: 110px;} .a-height-120-s {height: 120px; --height: 120px;} .a-height-130-s {height: 130px; --height: 130px;} .a-height-140-s {height: 140px; --height: 140px;} .a-height-150-s {height: 150px; --height: 150px;} .a-height-160-s {height: 160px; --height: 160px;} .a-height-170-s {height: 170px; --height: 170px;} .a-height-180-s {height: 180px; --height: 180px;} .a-height-190-s {height: 190px; --height: 190px;}
  /* height 200-500px  inc 50 */
  .a-height-200-s {height: 200px; --height: 200px;} .a-height-250-s {height: 250px; --height: 250px;} .a-height-300-s {height: 300px; --height: 300px;} .a-height-350-s {height: 350px; --height: 350px;} .a-height-400-s {height: 400px; --height: 400px;} .a-height-450-s {height: 450px; --height: 450px;} .a-height-500-s {height: 500px; --height: 500px;}
  /* height 0-200vh  inc 10 */
  .a-vHeight-10-s {height: 10vh; --height: 10vh;} .a-vHeight-20-s {height: 20vh; --height: 20vh;} .a-vHeight-30-s {height: 30vh; --height: 30vh;} .a-vHeight-40-s {height: 40vh; --height: 40vh;} .a-vHeight-50-s {height: 50vh; --height: 50vh;} .a-vHeight-60-s {height: 60vh; --height: 60vh;} .a-vHeight-70-s {height: 70vh; --height: 70vh;} .a-vHeight-80-s {height: 80vh; --height: 80vh;} .a-vHeight-90-s {height: 90vh; --height: 90vh;} .a-vHeight-100-s {height: 100vh; --height: 100vh;} .a-vHeight-110-s {height: 110vh; --height: 110vh;} .a-vHeight-120-s {height: 120vh; --height: 120vh;} .a-vHeight-130-s {height: 130vh; --height: 130vh;} .a-vHeight-140-s {height: 140vh; --height: 140vh;} .a-vHeight-150-s {height: 150vh; --height: 150vh;} .a-vHeight-160-s {height: 160vh; --height: 160vh;} .a-vHeight-170-s {height: 170vh; --height: 170vh;} .a-vHeight-180-s {height: 180vh; --height: 180vh;} .a-vHeight-190-s {height: 190vh; --height: 190vh;} .a-vHeight-200-s {height: 200vh; --height: 200vh;}
  /* height 220-400vh  inc 20 */
  .a-vHeight-220-s {height: 220vh; --height: 220vh;} .a-vHeight-240-s {height: 240vh; --height: 240vh;} .a-vHeight-260-s {height: 260vh; --height: 260vh;} .a-vHeight-280-s {height: 280vh; --height: 280vh;} .a-vHeight-300-s {height: 300vh; --height: 300vh;} .a-vHeight-320-s {height: 320vh; --height: 320vh;} .a-vHeight-340-s {height: 340vh; --height: 340vh;} .a-vHeight-360-s {height: 360vh; --height: 360vh;} .a-vHeight-380-s {height: 380vh; --height: 380vh;} .a-vHeight-400-s {height: 400vh; --height: 400vh;}
  /* height 450-700vh  inc 50 */
  .a-vHeight-450-s {height: 450vh; --height: 450vh;} .a-vHeight-500-s {height: 500vh; --height: 500vh;} .a-vHeight-550-s {height: 550vh; --height: 550vh;} .a-vHeight-600-s {height: 600vh; --height: 600vh;} .a-vHeight-650-s {height: 650vh; --height: 650vh;} .a-vHeight-700-s {height: 700vh; --height: 700vh;}
  /* height 5-100% inc 5  + 33 and 66*/
  .a-pHeight-5-s {height: 5%; --height: 5%;} .a-pHeight-10-s {height: 10%; --height: 10%;} .a-pHeight-15-s {height: 15%; --height: 15%;} .a-pHeight-20-s {height: 20%; --height: 20%;} .a-pHeight-25-s {height: 25%; --height: 25%;} .a-pHeight-30-s {height: 30%; --height: 30%;} .a-pHeight-35-s {height: 35%; --height: 35%;} .a-pHeight-40-s {height: 40%; --height: 40%;} .a-pHeight-45-s {height: 45%; --height: 45%;} .a-pHeight-50-s {height: 50%; --height: 50%;} .a-pHeight-55-s {height: 55%; --height: 55%;} .a-pHeight-60-s {height: 60%; --height: 60%;} .a-pHeight-65-s {height: 65%; --height: 65%;} .a-pHeight-70-s {height: 70%; --height: 70%;} .a-pHeight-75-s {height: 75%; --height: 75%;} .a-pHeight-80-s {height: 80%; --height: 80%;} .a-pHeight-85-s {height: 85%; --height: 85%;} .a-pHeight-90-s {height: 90%; --height: 90%;} .a-pHeight-95-s {height: 95%; --height: 95%;} .a-pHeight-100-s {height: 100%; --height: 100%;}
  .a-pHeight-33-s {height: 33.33%; --height: 33.33%;}.a-pHeight-66 {height: 66.66%; --height: 66.66%;}
}
