.sld-pan-cont {position: fixed; height: 100%; width: 100%; top: 0;right: 0;left:0;  z-index: 199;  overflow: hidden; }
.sld-pan-abs-cont { position: absolute;top: 0;right: 0;  bottom: 0;  left: 0;  overflow: hidden;  overflow-y: auto;-webkit-font-smoothing: subpixel-antialiased; }
.sld-pan-abs-cont::-webkit-scrollbar {width: 0;}
.sld-pan-overlay {position: fixed; top: 0; width: 100%; height: 100%;background-color: rgba(0,0,0,0.5); z-index: 9}
.sld-pan-right {--sld-pan-btn-Xtrasf: 100%}
.sld-pan-left {--sld-pan-btn-Xtrasf: -100%}
.sld-pan-inner {position: absolute; width: var(--fx-side-width); right: 0; overflow: visible; min-height: 100%; -webkit-box-shadow: 0 0 14px #444;box-shadow: 0 0 14px #444; background-color: #fff; z-index: 100; }
.sld-pan-child-cont {width: 100%; overflow: hidden;height: 100vh}
.sld-pan-right .sld-pan-inner {right: 0; --sld-pan-Xtrasf: calc(1/2 * var(--fx-side-width)); }
.sld-pan-left .sld-pan-inner {left: 0; --sld-pan-Xtrasf: calc(-1/2 * var(--fx-side-width)); }
.sld-pan-fade-in {animation: sliding-panel-in 200ms; opacity: 1}
.sld-pan-fade-out {animation: sliding-panel-out 200ms; opacity: 0;}
.sliding-panel-close-button-in {animation: sliding-panel-close-button-in .2s;  }
.sld-pan-close-btn {  background: #0071c2;  width: 65px; color: #fff;cursor: pointer;  text-align: center;font-size: 16px;line-height: 16px;  -webkit-box-shadow: 0 0 14px #444;
  box-shadow: 0 0 14px #444;position: fixed;top: 12%; z-index: 10;}
.sld-pan-close-btn:hover  {background: #003580}
.sld-pan-right .sld-pan-close-btn {border-radius: 5px 0 0 5px; right: calc(var(--fx-side-width) - 19px);padding: 12px}
.sld-pan-left .sld-pan-close-btn {border-radius:  0 5px 5px 0; left: calc(var(--fx-side-width) - 19px);padding: 12px;}



@-webkit-keyframes sliding-panel-in {
  from {
      -webkit-transform: translateX(var(--sld-pan-Xtrasf));
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0;
  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }
}

@keyframes sliding-panel-in {
  from {
      -webkit-transform: translateX(var(--sld-pan-Xtrasf));
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0
  }

  to {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }
}

@-webkit-keyframes sliding-panel-out {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }

  to {
      -webkit-transform: translateX(var(--sld-pan-Xtrasf));
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0
  }
}

@keyframes sliding-panel-out {
  from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity:1
  }

  to {
      -webkit-transform: translateX(v);
      transform: translateX(var(--sld-pan-Xtrasf));
      opacity:0
  }
}
@-webkit-keyframes sliding-panel-close-button-in {
  from {
      opacity: 0;
      -webkit-transform: translateX(var(--sld-pan-btn-Xtrasf));
      transform:translateX(var(--sld-pan-btn-Xtrasf))
  }

  to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform:translateX(0)
  }
}

@keyframes sliding-panel-close-button-in {
  from {
      opacity: 0;
      -webkit-transform: translateX(var(--sld-pan-btn-Xtrasf));
      transform:translateX(var(--sld-pan-btn-Xtrasf))
  }

  to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform:translateX(0)
  }
}
