/* Font 10-50 px inc. 1 */

.a-font-10 {font-size: 10px} .a-font-11 {font-size: 11px} .a-font-12 {font-size: 12px} .a-font-13 {font-size: 13px} .a-font-14 {font-size: 14px} .a-font-15 {font-size: 15px} .a-font-16 {font-size: 16px} .a-font-17 {font-size: 17px} .a-font-18 {font-size: 18px} .a-font-19 {font-size: 19px} .a-font-20 {font-size: 20px} .a-font-21 {font-size: 21px} .a-font-22 {font-size: 22px} .a-font-23 {font-size: 23px} .a-font-24 {font-size: 24px} .a-font-25 {font-size: 25px} .a-font-26 {font-size: 26px} .a-font-27 {font-size: 27px} .a-font-28 {font-size: 28px} .a-font-29 {font-size: 29px} .a-font-30 {font-size: 30px} .a-font-31 {font-size: 31px} .a-font-32 {font-size: 32px} .a-font-33 {font-size: 33px} .a-font-34 {font-size: 34px} .a-font-35 {font-size: 35px} .a-font-36 {font-size: 36px} .a-font-37 {font-size: 37px} .a-font-38 {font-size: 38px} .a-font-39 {font-size: 39px} .a-font-40 {font-size: 40px} .a-font-41 {font-size: 41px} .a-font-42 {font-size: 42px} .a-font-43 {font-size: 43px} .a-font-44 {font-size: 44px} .a-font-45 {font-size: 45px} .a-font-46 {font-size: 46px} .a-font-47 {font-size: 47px} .a-font-48 {font-size: 48px} .a-font-49 {font-size: 49px} .a-font-50 {font-size: 50px}

/* Icon 1-30 inc 1*/
.an-icon-1 {--iconSize: 1px; }.an-icon-2 {--iconSize: 2px; }.an-icon-3 {--iconSize: 3px; }.an-icon-4 {--iconSize: 4px; }.an-icon-5 {--iconSize: 5px; }.an-icon-6 {--iconSize: 6px; }.an-icon-7 {--iconSize: 7px; }.an-icon-8 {--iconSize: 8px; }.an-icon-9 {--iconSize: 9px; }.an-icon-10 {--iconSize: 10px; }.an-icon-11 {--iconSize: 11px; }.an-icon-12 {--iconSize: 12px; }.an-icon-13 {--iconSize: 13px; }.an-icon-14 {--iconSize: 14px; }.an-icon-15 {--iconSize: 15px; }.an-icon-16 {--iconSize: 16px; }.an-icon-17 {--iconSize: 17px; }.an-icon-18 {--iconSize: 18px; }.an-icon-19 {--iconSize: 19px; }.an-icon-20 {--iconSize: 20px; }.an-icon-21 {--iconSize: 21px; }.an-icon-22 {--iconSize: 22px; }.an-icon-23 {--iconSize: 23px; }.an-icon-24 {--iconSize: 24px; }.an-icon-25 {--iconSize: 25px; }.an-icon-26 {--iconSize: 26px; }.an-icon-27 {--iconSize: 27px; }.an-icon-28 {--iconSize: 28px; }.an-icon-29 {--iconSize: 29px; }.an-icon-30 {--iconSize: 30px; }
/* Icon 32-50 inc 2*/
.an-icon-32 {--iconSize: 32px; }.an-icon-34 {--iconSize: 34px; }.an-icon-36 {--iconSize: 36px; }.an-icon-38 {--iconSize: 38px; }.an-icon-40 {--iconSize: 40px; }.an-icon-42 {--iconSize: 42px; }.an-icon-44 {--iconSize: 44px; }.an-icon-46 {--iconSize: 46px; }.an-icon-48 {--iconSize: 48px; }.an-icon-50 {--iconSize: 50px; }

.w200 {font-weight: 200}.w300 {font-weight: 300} .w400 {font-weight: 400} .w500 {font-weight: 500} .w600 {font-weight: 600} .w700 {font-weight: 700}
.lh-1 {line-height: 1} .lh-1-1 {line-height: 1.1} .lh-1-2 {line-height: 1.2} .lh-1-3 {line-height: 1.3} .lh-1-4 {line-height: 1.4} .lh-1-5 {line-height: 1.5} .lh-1-6 {line-height: 1.6}

@media (max-width:1200px) {
   .a-font-10-t {font-size: 10px} .a-font-11-t {font-size: 11px} .a-font-12-t {font-size: 12px} .a-font-13-t {font-size: 13px} .a-font-14-t {font-size: 14px} .a-font-15-t {font-size: 15px} .a-font-16-t {font-size: 16px} .a-font-17-t {font-size: 17px} .a-font-18-t {font-size: 18px} .a-font-19-t {font-size: 19px} .a-font-20-t {font-size: 20px} .a-font-21-t {font-size: 21px} .a-font-22-t {font-size: 22px} .a-font-23-t {font-size: 23px} .a-font-24-t {font-size: 24px} .a-font-25-t {font-size: 25px} .a-font-26-t {font-size: 26px} .a-font-27-t {font-size: 27px} .a-font-28-t {font-size: 28px} .a-font-29-t {font-size: 29px} .a-font-30-t {font-size: 30px} .a-font-31-t {font-size: 31px} .a-font-32-t {font-size: 32px} .a-font-33-t {font-size: 33px} .a-font-34-t {font-size: 34px} .a-font-35-t {font-size: 35px} .a-font-36-t {font-size: 36px} .a-font-37-t {font-size: 37px} .a-font-38-t {font-size: 38px} .a-font-39-t {font-size: 39px} .a-font-40-t {font-size: 40px} .a-font-41-t {font-size: 41px} .a-font-42-t {font-size: 42px} .a-font-43-t {font-size: 43px} .a-font-44-t {font-size: 44px} .a-font-45-t {font-size: 45px} .a-font-46-t {font-size: 46px} .a-font-47-t {font-size: 47px} .a-font-48-t {font-size: 48px} .a-font-49-t {font-size: 49px} .a-font-50-t {font-size: 50px}
   /* Icon 1-30 inc 1*/
   .an-icon-1-t {--iconSize: 1px; } .an-icon-2-t {--iconSize: 2px; } .an-icon-3-t {--iconSize: 3px; } .an-icon-4-t {--iconSize: 4px; } .an-icon-5-t {--iconSize: 5px; } .an-icon-6-t {--iconSize: 6px; } .an-icon-7-t {--iconSize: 7px; } .an-icon-8-t {--iconSize: 8px; } .an-icon-9-t {--iconSize: 9px; } .an-icon-10-t {--iconSize: 10px; } .an-icon-11-t {--iconSize: 11px; } .an-icon-12-t {--iconSize: 12px; } .an-icon-13-t {--iconSize: 13px; } .an-icon-14-t {--iconSize: 14px; } .an-icon-15-t {--iconSize: 15px; } .an-icon-16-t {--iconSize: 16px; } .an-icon-17-t {--iconSize: 17px; } .an-icon-18-t {--iconSize: 18px; } .an-icon-19-t {--iconSize: 19px; } .an-icon-20-t {--iconSize: 20px; } .an-icon-21-t {--iconSize: 21px; } .an-icon-22-t {--iconSize: 22px; } .an-icon-23-t {--iconSize: 23px; } .an-icon-24-t {--iconSize: 24px; } .an-icon-25-t {--iconSize: 25px; } .an-icon-26-t {--iconSize: 26px; } .an-icon-27-t {--iconSize: 27px; } .an-icon-28-t {--iconSize: 28px; } .an-icon-29-t {--iconSize: 29px; } .an-icon-30-t {--iconSize: 30px; }
   /* Icon 32-50 inc 2*/
   .an-icon-32-t {--iconSize: 32px; } .an-icon-34-t {--iconSize: 34px; } .an-icon-36-t {--iconSize: 36px; } .an-icon-38-t {--iconSize: 38px; } .an-icon-40-t {--iconSize: 40px; } .an-icon-42-t {--iconSize: 42px; } .an-icon-44-t {--iconSize: 44px; } .an-icon-46-t {--iconSize: 46px; } .an-icon-48-t {--iconSize: 48px; } .an-icon-50-t {--iconSize: 50px; }
   .w300-t {font-weight: 300} .w400-t {font-weight: 400} .w500-t {font-weight: 500} .w600-t {font-weight: 600} .w700-t {font-weight: 700}
   .lh-1-t {line-height: 1} .lh-1-1-t {line-height: 1.1} .lh-1-2-t {line-height: 1.2} .lh-1-3-t {line-height: 1.3} .lh-1-4-t {line-height: 1.4} .lh-1-5-t {line-height: 1.5} .lh-1-6-t {line-height: 1.6}


}

@media (max-width:734px) {
   .a-font-10-s {font-size: 10px} .a-font-11-s {font-size: 11px} .a-font-12-s {font-size: 12px} .a-font-13-s {font-size: 13px} .a-font-14-s {font-size: 14px} .a-font-15-s {font-size: 15px} .a-font-16-s {font-size: 16px} .a-font-17-s {font-size: 17px} .a-font-18-s {font-size: 18px} .a-font-19-s {font-size: 19px} .a-font-20-s {font-size: 20px} .a-font-21-s {font-size: 21px} .a-font-22-s {font-size: 22px} .a-font-23-s {font-size: 23px} .a-font-24-s {font-size: 24px} .a-font-25-s {font-size: 25px} .a-font-26-s {font-size: 26px} .a-font-27-s {font-size: 27px} .a-font-28-s {font-size: 28px} .a-font-29-s {font-size: 29px} .a-font-30-s {font-size: 30px} .a-font-31-s {font-size: 31px} .a-font-32-s {font-size: 32px} .a-font-33-s {font-size: 33px} .a-font-34-s {font-size: 34px} .a-font-35-s {font-size: 35px} .a-font-36-s {font-size: 36px} .a-font-37-s {font-size: 37px} .a-font-38-s {font-size: 38px} .a-font-39-s {font-size: 39px} .a-font-40-s {font-size: 40px} .a-font-41-s {font-size: 41px} .a-font-42-s {font-size: 42px} .a-font-43-s {font-size: 43px} .a-font-44-s {font-size: 44px} .a-font-45-s {font-size: 45px} .a-font-46-s {font-size: 46px} .a-font-47-s {font-size: 47px} .a-font-48-s {font-size: 48px} .a-font-49-s {font-size: 49px} .a-font-50-s {font-size: 50px}
   /* Icon 1-30 inc 1*/
   .an-icon-1-s {--iconSize: 1px; } .an-icon-2-s {--iconSize: 2px; } .an-icon-3-s {--iconSize: 3px; } .an-icon-4-s {--iconSize: 4px; } .an-icon-5-s {--iconSize: 5px; } .an-icon-6-s {--iconSize: 6px; } .an-icon-7-s {--iconSize: 7px; } .an-icon-8-s {--iconSize: 8px; } .an-icon-9-s {--iconSize: 9px; } .an-icon-10-s {--iconSize: 10px; } .an-icon-11-s {--iconSize: 11px; } .an-icon-12-s {--iconSize: 12px; } .an-icon-13-s {--iconSize: 13px; } .an-icon-14-s {--iconSize: 14px; } .an-icon-15-s {--iconSize: 15px; } .an-icon-16-s {--iconSize: 16px; } .an-icon-17-s {--iconSize: 17px; } .an-icon-18-s {--iconSize: 18px; } .an-icon-19-s {--iconSize: 19px; } .an-icon-20-s {--iconSize: 20px; } .an-icon-21-s {--iconSize: 21px; } .an-icon-22-s {--iconSize: 22px; } .an-icon-23-s {--iconSize: 23px; } .an-icon-24-s {--iconSize: 24px; } .an-icon-25-s {--iconSize: 25px; } .an-icon-26-s {--iconSize: 26px; } .an-icon-27-s {--iconSize: 27px; } .an-icon-28-s {--iconSize: 28px; } .an-icon-29-s {--iconSize: 29px; } .an-icon-30-s {--iconSize: 30px; }
   /* Icon 32-50 inc 2*/
   .an-icon-32-s {--iconSize: 32px; } .an-icon-34-s {--iconSize: 34px; } .an-icon-36-s {--iconSize: 36px; } .an-icon-38-s {--iconSize: 38px; } .an-icon-40-s {--iconSize: 40px; } .an-icon-42-s {--iconSize: 42px; } .an-icon-44-s {--iconSize: 44px; } .an-icon-46-s {--iconSize: 46px; } .an-icon-48-s {--iconSize: 48px; } .an-icon-50-s {--iconSize: 50px; }


   .w300-s {font-weight: 300} .w400-s {font-weight: 400} .w500-s {font-weight: 500} .w600-s {font-weight: 600} .w700-s {font-weight: 700}
   .lh-1-s {line-height: 1} .lh-1-1-s {line-height: 1.1} .lh-1-2-s {line-height: 1.2} .lh-1-3-s {line-height: 1.3} .lh-1-4-ts {line-height: 1.4} .lh-1-5-s {line-height: 1.5} .lh-1-6-s {line-height: 1.6}

   .a-vw-font-2-5-s {font-size:calc(max(12px,2.5vw));} .a-vw-font-2-6-s {font-size:calc(max(12px,2.6vw));} .a-vw-font-2-7-s {font-size:calc(max(12px,2.7vw));} .a-vw-font-2-8-s {font-size:calc(max(12px,2.8vw));} .a-vw-font-2-9-s {font-size:calc(max(12px,2.9vw));} .a-vw-font-3-s {font-size:calc(max(12px,3vw));} .a-vw-font-3-1-s {font-size:calc(max(12px,3.1vw));} .a-vw-font-3-2-s {font-size:calc(max(12px,3.2vw));} .a-vw-font-3-3-s {font-size:calc(max(12px,3.3vw));} .a-vw-font-3-4-s {font-size:calc(max(12px,3.4vw));} .a-vw-font-3-5-s {font-size:calc(max(12px,3.5vw));}

   .a-vw-font-3-6-s {font-size: 3.6vw;} .a-vw-font-3-7-s {font-size: 3.7vw;} .a-vw-font-3-8-s {font-size: 3.8vw;} .a-vw-font-3-9-s {font-size: 3.9vw;} .a-vw-font-4-s {font-size: 4vw;} .a-vw-font-4-1-s {font-size: 4.1vw;} .a-vw-font-4-2-s {font-size: 4.2vw;} .a-vw-font-4-3-s {font-size: 4.3vw;} .a-vw-font-4-4-s {font-size: 4.4vw;} .a-vw-font-4-5-s {font-size: 4.5vw;} .a-vw-font-4-6-s {font-size: 4.6vw;} .a-vw-font-4-7-s {font-size: 4.7vw;} .a-vw-font-4-8-s {font-size: 4.8vw;} .a-vw-font-4-9-s {font-size: 4.9vw;} .a-vw-font-5-s {font-size: 5vw;}

   .a-vw-font-5-2-s {font-size: 5.2vw;} .a-vw-font-5-4-s {font-size: 5.4vw;} .a-vw-font-5-6-s {font-size: 5.6vw;} .a-vw-font-5-8-s {font-size: 5.8vw;} .a-vw-font-6-s {font-size: 6vw;} .a-vw-font-6-2-s {font-size: 6.2vw;} .a-vw-font-6-4-s {font-size: 6.4vw;} .a-vw-font-6-6-s {font-size: 6.6vw;} .a-vw-font-6-8-s {font-size: 6.8vw;} .a-vw-font-7-s {font-size: 7vw;}

   .a-vw-font-7-5-s {font-size: 7.5vw;} .a-vw-font-8-s {font-size: 8vw;} .a-vw-font-8-5-s {font-size: 8.5vw;} .a-vw-font-9-s {font-size: 9vw;} .a-vw-font-9-5-s {font-size: 9.5vw;} .a-vw-font-10-s {font-size: 10vw;} .a-vw-font-10-5-s {font-size: 10.5vw;} .a-vw-font-11-s {font-size: 11vw;} .a-vw-font-11-5-s {font-size: 11.5vw;} .a-vw-font-12-s {font-size: 12vw;}

}

@media (max-width:350px) {
  .a-font-10-xs {font-size: 10px} .a-font-11-xs {font-size: 11px} .a-font-12-xs {font-size: 12px} .a-font-13-xs {font-size: 13px} .a-font-14-xs {font-size: 14px} .a-font-15-xs {font-size: 15px} .a-font-16-xs {font-size: 16px} .a-font-17-xs {font-size: 17px} .a-font-18-xs {font-size: 18px} .a-font-19-xs {font-size: 19px} .a-font-20-xs {font-size: 20px} .a-font-21-xs {font-size: 21px} .a-font-22-xs {font-size: 22px} .a-font-23-xs {font-size: 23px} .a-font-24-xs {font-size: 24px} .a-font-25-xs {font-size: 25px} .a-font-26-xs {font-size: 26px} .a-font-27-xs {font-size: 27px} .a-font-28-xs {font-size: 28px} .a-font-29-xs {font-size: 29px} .a-font-30-xs {font-size: 30px} .a-font-31-xs {font-size: 31px} .a-font-32-xs {font-size: 32px}

  .a-vw-font-2-5-xs {font-size:calc(max(12px,2.5vw));} .a-vw-font-2-6-xs {font-size:calc(max(12px,2.6vw));} .a-vw-font-2-7-xs {font-size:calc(max(12px,2.7vw));} .a-vw-font-2-8-xs {font-size:calc(max(12px,2.8vw));} .a-vw-font-2-9-xs {font-size:calc(max(12px,2.9vw));} .a-vw-font-3-xs {font-size:calc(max(12px,3vw));} .a-vw-font-3-1-xs {font-size:calc(max(12px,3.1vw));} .a-vw-font-3-2-xs {font-size:calc(max(12px,3.2vw));} .a-vw-font-3-3-xs {font-size:calc(max(12px,3.3vw));} .a-vw-font-3-4-xs {font-size:calc(max(12px,3.4vw));} .a-vw-font-3-5-xs {font-size:calc(max(12px,3.5vw));}

  .a-vw-font-3-6-xs {font-size: 3.6vw;} .a-vw-font-3-7-xs {font-size: 3.7vw;} .a-vw-font-3-8-xs {font-size: 3.8vw;} .a-vw-font-3-9-xs {font-size: 3.9vw;} .a-vw-font-4-xs {font-size: 4vw;} .a-vw-font-4-1-xs {font-size: 4.1vw;} .a-vw-font-4-2-xs {font-size: 4.2vw;} .a-vw-font-4-3-xs {font-size: 4.3vw;} .a-vw-font-4-4-xs {font-size: 4.4vw;} .a-vw-font-4-5-xs {font-size: 4.5vw;} .a-vw-font-4-6-xs {font-size: 4.6vw;} .a-vw-font-4-7-xs {font-size: 4.7vw;} .a-vw-font-4-8-xs {font-size: 4.8vw;} .a-vw-font-4-9-xs {font-size: 4.9vw;} .a-vw-font-5-xs {font-size: 5vw;}

  .a-vw-font-5-2-xs {font-size: 5.2vw;} .a-vw-font-5-4-xs {font-size: 5.4vw;} .a-vw-font-5-6-xs {font-size: 5.6vw;} .a-vw-font-5-8-xs {font-size: 5.8vw;} .a-vw-font-6-xs {font-size: 6vw;} .a-vw-font-6-2-xs {font-size: 6.2vw;} .a-vw-font-6-4-xs {font-size: 6.4vw;} .a-vw-font-6-6-xs {font-size: 6.6vw;} .a-vw-font-6-8-xs {font-size: 6.8vw;} .a-vw-font-7-xs {font-size: 7vw;}

  .a-vw-font-7-5-xs {font-size: 7.5vw;} .a-vw-font-8-xs {font-size: 8vw;} .a-vw-font-8-5-xs {font-size: 8.5vw;} .a-vw-font-9-xs {font-size: 9vw;} .a-vw-font-9-5-xs {font-size: 9.5vw;} .a-vw-font-10-xs {font-size: 10vw;} .a-vw-font-10-5-xs {font-size: 10.5vw;} .a-vw-font-11-xs {font-size: 11vw;} .a-vw-font-11-5-xs {font-size: 11.5vw;} .a-vw-font-12-xs {font-size: 12vw;}
}







/*
@media (max-width:1200) {

}

@media (max-width:734) {

}

*/
