
.hero-defs {--frontCont: calc(100% - 55px); --bkgCont: calc(100% + var(--gridElMinHeight, 0px)); --contentWidth: 1380px;--heroWidth: 100%}
.hero-rotator-container {margin: 0 auto; width: var(--heroWidth); height: var(--frontCont); overflow: visible;}
.hero-rot-arrow-top {top: calc(50% - var(--cont-height) / 2) !important;}
@media only screen and (max-width: 1500px) {
  .hero-defs {--frontCont: 100vh;  --contentWidth: 80vw}
  .hero-rotator-container {}
}
@media only screen and (max-width: 734px) {
    .hero-defs {--frontCont: calc(100% - 55px);  --contentWidth: 85vw}
    .hero-rotator-container  {}
}
