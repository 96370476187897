.dev-hero-header {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 2; justify-content: center; align-items: center; padding: 0 50px;text-align: center;}
.dev-hero-h1 {padding: 0; margin: 0 0 0 0; line-height: 1.2; font-weight: 700; font-size: 40px; color: white; }
.dev-hero-h2 {padding: 0; margin: 24px 0 48px 0 ; line-height: 1.2; font-weight: 500; font-size: 20px; color: Snow; padding: 0 30px;}
.dev-hero-flex-cont {display: flex; justify-content: center; align-items: center;}
.dev-hero-img { margin: 0 auto; height: 70%; width: auto; max-width: 90%}
.dHero-header-container {z-index: 3; overflow: hidden; height: 100%; width: 50%}
.dHero-img-container {z-index: 2; overflow: hidden; height: 100%; width: 50%}


@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 734px) {
  .dev-hero-flex-cont {flex-direction: column; z-index: 2;position: relative; text-align: center; justify-content: space-between; --contentWidth: 83vw}
  .dev-hero-grad {position: absolute;top: 0;width: 100vw; height: 100%;z-index: 1; background-image: radial-gradient(rgb(199,199,199)0, rgb(140,140,140) 90%);}
  .dev-hero-header {padding:0 ;}
  .dev-hero-h1 {font-size: 32px; margin-bottom: 12px; position: relative;z-index: 2;padding-top: 16px;}
  .dev-hero-h2 {margin: 0px 0 20px 0; font-size: 17px; position: relative;z-index: 2;font-weight: 600;padding: 0; max-height: calc(17px * 1.2 * 3); overflow: hidden; text-overflow: ellipsis;}
  .dHero-img-container {width: 100%; height: 25%;}
  .dev-hero-img {height: 100%}
  .dev-hero-but {position: relative;z-index: 2;}
  .dev-hero-headings {flex-basis: 37.5%;position: relative;z-index: 2;}
}
