.iconLePad-1 {--iconLePad:1px}.iconLePad-2 {--iconLePad:2px}.iconLePad-3 {--iconLePad:3px}.iconLePad-4 {--iconLePad:4px}.iconLePad-5 {--iconLePad:5px}.iconLePad-6 {--iconLePad:6px}
.iconRiPad-1 {--iconRiPad: 1px}.iconRiPad-2 {--iconRiPad: 2px}.iconRiPad-3 {--iconRiPad: 3px}.iconRiPad-4 {--iconRiPad: 4px}.iconRiPad-5 {--iconRiPad: 5px}.iconRiPad-6 {--iconRiPad: 6px}
.iconToPad-1 {--iconToPad: 1px}.iconToPad-2 {--iconToPad: 2px}.iconToPad-3 {--iconToPad: 3px}.inonToPad-4 {--iconToPad: 4px}.iconToPad-5 {--iconToPad: 5px}.iconToPad-6 {--iconToPad: 6px}
.iconBoPad-1 {--iconBoPad: 1px}.iconBoPad-2 {--iconBoPad: 2px}.iconBoPad-3 {--iconBoPad: 3px}.iconBoPad-4 {--iconBoPad: 4px}.iconBoPad-5 {--iconBoPad: 5px}.iconBoPad-6 {--iconBoPad: 6px}

.a-btn-top {--brdSize: 0;border-radius: 8px; font-size: 15px; line-height: 1; padding: 0 20px;--dropDwonPadding: 0 8px 0 14px; --height: 38px; height: var(--height); letter-spacing: 0.5px;font-weight: 700;}
.a-btn-top-icon {--brdSize: 0px;border-radius: 50%; --iconSize: calc(23px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 calc((var(--height) - var(--iconSize) - var(--iconLePad, 0px) - var(--iconRiPad, 0px)) / 2);--height: 42px;
   height: var(--height);  margin-right: 6px;}
.a-btn-top-icon-text {--brdSize: 0;border-radius: 6px;font-size: 15px; --iconSize: calc(23px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 25px 0 15px;--height: 36px;
   height: var(--height); font-weight: 500;--dropDwonPadding: 0 8px 0 9px;}


.a-btn-top.a-btn-margin, .a-btn-top-icon.a-btn-margin {margin-right: 8px}


li:last-child .a-btn-top.a-btn-margin,li:last-child .a-btn-top-icon.a-btn-margin {margin-right: 0}

.a-btn-top-noBkg {--brdSize: 1px;border-radius: 6px; font-size: 18px; line-height: 1; padding: 0 14px;--dropDwonPadding: 0 8px 0 14px; height: 36px; letter-spacing: 0.5px;font-weight: 500; --noBkgMarginRight: 20px; }
.a-btn-top-icon-noBkg {--brdSize: 1px;border-radius: 50%; --iconSize: calc(24px + var(--iconSizAdj, 0px)); line-height: 24px; padding: 0 9px;height: 38px; font-weight: 500; margin-right: 6px;text-shadow: 4px 4px 16px rgba(0,0,0,1);--iconLePad:2px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;--noBkgMarginRight: 20px; }
.a-btn-top-noBkg-icon-text {--brdSize: 1px;border-radius: 20px; font-size: 18px;--iconSize: calc(24px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 14px 0 8px;--dropDwonPadding: 0 8px 0 14px; height: 40px; letter-spacing: 0.5px;font-weight: 500;--iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;--noBkgMarginRight: 20px;  }

.a-btn-top-noBkg.a-btn-margin, .a-btn-top-icon-noBkg.a-btn-margin {margin-right: var(--noBkgMarginRight)}
li:last-child .a-btn-top-noBkg.a-btn-margin, li:last-child .a-btn-top-icon-noBkg.a-btn-margin {margin-right: 0}


@media only screen and (max-width: 1068px) {

  .a-btn-top-noBkg {--brdSize: 1px;border-radius: 6px; font-size: 15px;  padding: 0 10px;--dropDwonPadding: 0 6px 0 12px; height: 32px; --noBkgMarginRight: 12px;}
  .a-btn-top-icon-noBkg {--brdSize: 1px;border-radius: 50%; --iconSize: calc(20px + var(--iconSizAdj, 0px)); line-height: 20px; padding: 0 7px;height: 32px;  margin-right: 3px;text-shadow: 4px 4px 16px rgba(0,0,0,1);--noBkgMarginRight: 12px;}
  .a-btn-top-noBkg-icon-text {--brdSize: 1px;border-radius: 16px; font-size: 15px;--iconSize: calc(20px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 14px 0 8px;--dropDwonPadding: 0 6px 0 12px; height: 32px;--noBkgMarginRight: 12px;  }
  .a-btn-top {--brdSize: 0;border-radius: 6px; font-size: 14px; line-height: 1; padding: 0 12px;--dropDwonPadding: 0 5px 0 12px; height: 34px; }
  .a-btn-top-icon {--brdSize: 0;border-radius: 50%; font-size: 22px; line-height: 20px; padding: 0 7px;height: 32px;  margin-right: 3px;}
  .a-btn-top-icon-text {--brdSize: 0;border-radius: 16px;font-size: 14px; --iconSize: calc(22px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 12px 0 6px;height: 32px; font-weight: 500;--dropDwonPadding: 0 6px 0 7px;
    --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
}

@media only screen and (max-width: 734px) {

  .a-btn-top-noBkg {--brdSize: 1px;border-radius: 6px; font-size: 15px;  padding: 0 10px;--dropDwonPadding: 0 6px 0 12px; height: 32px; --noBkgMarginRight: 12px;}
  .a-btn-top-icon-noBkg {--brdSize: 1px;border-radius: 50%; --iconSize: calc(24px + var(--iconSizAdj, 0px)); line-height: 20px; padding: 0 7px;height: 32px;  margin-right: 3px;text-shadow: 4px 4px 16px rgba(0,0,0,1);--noBkgMarginRight: 12px;}
  .a-btn-top-noBkg-icon-text {--brdSize: 1px;border-radius: 16px; font-size: 15px;--iconSize: calc(20px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 14px 0 8px;--dropDwonPadding: 0 6px 0 12px; height: 32px;--noBkgMarginRight: 12px;  }
  .a-btn-top {--brdSize: 0;border-radius: 6px; font-size: 13px; line-height: 1; padding: 0 8px;--dropDwonPadding: 0 3px 0 8px; height: 34px; }
  .a-btn-top-icon {--brdSize: 0;border-radius: 50%; --iconSize: calc(28px + var(--iconSizAdj, 0px)); line-height: 27px; padding: 0 min(1.4vw, 6px);height: 32px;  margin-right: 8px;}
  .a-btn-top-icon-text {--brdSize: 0;border-radius: 16px;font-size: 14px; --iconSize: calc(20px + var(--iconSizAdj, 0px)); line-height: 1; padding: 0 12px 0 6px;height: 32px; font-weight: 500;--dropDwonPadding: 0 6px 0 7px;
    --iconLePad:0px;--iconRiPad:0px;--iconToPad:0px;--iconBoPad:0px;}
}

@media only screen and (max-width: 385px) {
    .a-btn-top-icon {--brdSize: 0;border-radius: 50%; --iconSize: 27px; line-height: 27px; padding: 0 min(1.4vw, 6px);height: 32px;  margin-right: 4px;}
}

@media only screen and (max-width: 335px) {
    .a-btn-top-icon {--brdSize: 0;border-radius: 50%; --iconSize: 24px; line-height: 24px; padding: 0 min(1.4vw, 6px);height: 24px;  margin-right: 4px;}
}
