/* Row */
.cl-scale-container {box-shadow: 1px 1px 2px rgba(0,0,0,.2);border-radius: 6px; background-color: white;}
.cl-scale-title-container {   padding: 20px 22px 18px; position: relative;}

.cl-scale-subtitle {color: rgb(70,70,70)}
.cl-scale-elements-cont {padding: 16px 16px 20px 16px}
.cl-scale-disp-col {position: absolute;right: 60px; top: 50%; transform: translateY(-50%);font-size: 18px;padding: 0 28px;background-color: #13B6D3;
  color: white; line-height: 1; font-weight: 600; border-radius: 10px; height: 50px}
.cl-scale-bord-disp-act { margin: 0 8px; padding: 6px 8px; border-radius: 15px; background-color: red}
.cl-scale-bord-disp { margin: 0 2px; padding: 6px; border-radius: 10px; background-color: inherit;}
/* Scale Block */

.cl-scale-block-21 {width: calc((100% - 20 * 1px) / 21); height: 180px; position: relative;cursor: pointer;}
.cl-selected-block {--border: 4px; border: var(--border) solid dodgerblue}

.cl-rotated-selected {font-size: 20px; top: 50%; left: 50%; position: absolute;transform: translate(-50%, -50%) rotate(-90deg); letter-spacing: 1px}

/* Level */
.cl-variant-level { position: absolute; --top: -22px; top: var(--top); width: 100%; left: 0; text-align: center; font-size: 13px; font-weight: 500}
.cl-selected-block .cl-variant-level  {top: calc(var(--top) - var(--border)); color: dodgerblue}

.mappings-scrollable-heading {position: sticky; top: var(--tBar-height);display: flex;justify-content: center;}
/* ScBlToolTip */
.sc-block-tooltip {position: absolute; top: calc(50%); width: 200px; left: -140%; padding: 12px 20px; background-color: white;
  box-shadow: 1px 1px 2px rgba(0,0,0,.4); font-size: 15px; font-weight: 500; z-index: 5; line-height: 1.7; border-radius: 8px; color: black; cursor: auto; display: none; }
.sc-block-tooltip-arrow {position: absolute;bottom: 100%; right: calc(100px + 4px);border-width: 7px;border-style: solid;border-color:  transparent transparent white transparent;}
.cl-copied-clip-msg {position: absolute;top:4px; right: 6px; padding: 3px 6px;line-height: 1;background-color: white; color: black; font-size: 12px; font-weight: 600; border-radius: 4px;}

.cl-var-title-elem { margin-right: 32px; color: rgb(110,110,110);  cursor: pointer; padding: 14px 12px; border: 3px solid transparent;margin-top: 4px}
.cl-var-title-elem:last-child {margin-right: 0}
.cl-var-title-elem.cl-var-title-selected  {color: darkorange; border-bottom: 3px solid darkorange; font-weight: 600; }
.map-scr-heading-brd { box-shadow: 0 3px 3px rgba(0,0,0,.1);}
@media (max-width:734px) {
.cl-scale-container {box-shadow: none;border-radius: 0; width: 100%;  background-color: inherit;  min-height: 35vh;}
.cl-scale-title-container { padding: 22px 20px 30px 20px; position: relative; margin: 0 20px -10px 20px; background-color: white;box-shadow: 1px 1px 2px rgba(0,0,0,.2);border-radius: 16px 16px 0 0; }
.scarlet-is-open .cl-scale-title-container {padding: 15px 20px 21px 20px; font-size: 14px!important}
.cl-scale-elements-cont {padding:  0 20px;  width: 100% ; overflow-x: scroll; overflow-y: visible;-ms-overflow-style: none;
  /* IE and Edge */scrollbar-width: none;  /* Firefox */}
.cl-scale-elements-cont-width {width:330%;}
.cl-scale-block-21 { height: 30vh; position: relative;cursor: pointer;overflow: visible;}
.scarlet-is-open .cl-scale-block-21 { height: 26vh; }
.cl-scale-elements-cont::-webkit-scrollbar {display: none;}
.cl-variant-level { position: absolute; --top: -16px; display: none}
.sc-block-tooltip {position: absolute; top: 50px; width: 240px; left: calc(-120px + 50%);padding: 14px 20px;font-size: 17px;}
.cl-var-title-elem { margin-right: 24px;  padding: 0 12px; border: none;margin-top: 0}
.cl-var-title-elem.cl-var-title-selected  {color: darkorange; border-bottom: none; font-weight: 600; }
.mappings-scrollable-heading {display: block;width: 100%;position: sticky; top: calc(var(--tBar-height) + 100vw * 0.26);z-index: 20;overflow-x: auto; overflow-y: visible;
-ms-overflow-style: none;  /* IE and Edge */scrollbar-width: none;  /* Firefox */; background-color: hsla(60,56%,91%, .9)}
.scarlet-is-open .mappings-scrollable-heading { top: calc(var(--tBar-height) + 100vw * 0.2 - 30px); position: fixed;z-index: 100;}
.mappings-scrollable-heading::-webkit-scrollbar {display: none;}

}

@media  (hover: hover) {
  .cl-scale-block-21:hover:not(.cl-selected-block) .sc-block-tooltip {display: block;}
  .cl-scale-block-21:hover:not(.cl-selected-block) {--border: 5px; border:var(--border) solid tomato}
  .cl-scale-block-21:hover .cl-variant-level  {top: calc(var(--top) - var(--border)); color: tomato}


}
