.tip-size-5 {--tipArrowSize: 5px;}.tip-size-6 {--tipArrowSize: 6px;}.tip-size-7 {--tipArrowSize: 7px;}.tip-size-8 {--tipArrowSize: 8px;}.tip-size-9 {--tipArrowSize: 9px;}
.tip-size-10 {--tipArrowSize: 10px;}.tip-size-11 {--tipArrowSize: 11px;}.tip-size-12 {--tipArrowSize: 12px;}.tip-size-13 {--tipArrowSize: 13px;}.tip-size-14 {--tipArrowSize: 14px;}

.a-btn-drop-defs {
  --dropUlBkg: white;--dropBoxShColor: rgba(0,0,0,.4);
    /*Singfle drop Color*/
  --drColor:rgb(70, 70, 70); --drBkgHov: #f5f5f5;--drColorHov: var(--drColor);
    /*Multi drop Color  By default multi section hover is on text and not on row backgrund, to change that also the row size should have proper padding*/
  --drTitleColor: rgb(15, 17, 17); --drColorM: rgb(68, 68, 68);--drBkgHov:inherit;--drColorHovM: rgb(199, 81, 31);

  --dropBrdRadius: 6px;

  /*Singfle drop  -> There is no outer or intra Padding, all the paddign inside the button Li with hover Bkg effect*/
  --drSize: 14px; --drLineHe: 1.2; --drPadd: 16px 14px;--dropWeigh: 400;
  /*Multi Drop*/
  --outerPadding: 20px 24px 0 24px; --intraPadding: 20px;
  --drTitleSize: 16px; --drTitleWeight: 700; --drTitleMarBot: 10px;/* Title*/
  --drSizeM: 14px; --drLineHeM: 1.2; --drPaddiM: 0 0 9px 0 /* Padding x text hover, to be changed for Li hover */;--dropWeighM: 300;
}



.drop-wid-150 {--dropWidth: 150px;}.drop-wid-200 {--dropWidth: 200px;}.drop-wid-250 {--dropWidth: 250px;}.drop-wid-300 {--dropWidth: 300px;}.drop-wid-350 {--dropWidth: 350px;}.drop-wid-400 {--dropWidth: 400px;}.drop-wid-500 {--dropWidth: 500px;}.drop-wid-600 {--dropWidth: 600px;}

.a-btn-drop .a-btn-drop-menu { position: absolute;bottom: 0; width: var(--dropWidth); max-width: 80vw; transform: translateY(100%);padding: 0;
  background: var(--dropUlBkg);  border-radius: var(--dropBrdRadius);border: none;z-index: 100; box-shadow: 0 2px 4px var(--dropBoxShColor);
   margin: 0; list-style: none; font-family: Arial, sans-serif; display: none; opacity: 0; transition: opacity 1s ease-in;}
.a-btn-drop:hover .a-btn-drop-menu {display: block; opacity: 1;}

.a-btn-drop-sect-cont {display: flex; align-items: stretch;justify-content: flex-start;width: 100%; flex-wrap: wrap;padding: var(--outerPadding);}
.a-btn-drop-sect-row { padding-right: var(--intraPadding); margin-bottom: var(--intraPadding);}
.a-btn-drop-sect-title {margin-bottom: var(--drTitleMarBot); font-weight: var(--drTitleWeight); font-size: var(--drTitleSize);
  text-align: left;color: var(--drTitleColor); line-height: 1.2}



.a-btn-drop.a-btn-bordOnHover:hover {border-color: transparent}
.a-btn-drop-menu.drop-left {right: 0;}
.a-btn-drop-menu.drop-right {left: 0;}
.a-btn-drop-menu.drop-full {width: 100vw !important;}
.a-btn-drop-menu.drop-center {left: 50%; transform: translate(-50%, 100%) !important;}

.a-btn-drop-arrow {position: absolute;bottom: 100%; border-width: var(--tipArrowSize);border-style: solid;border-color:  transparent transparent var(--dropUlBkg) transparent;}
.a-btn-drop-row {display: block; box-sizing: border-box;overflow: hidden; width: 100%;border-radius: var(--dropBrdRadius);}
.a-btn-drop-row {padding: var(--drPaddi); text-align: left;background-color: inherit;display: block;cursor: pointer; color: var(--drColor);
  font-size: var(--drSize);line-height: var(--drLineHe); font-weight: var(--drWeight);}
.a-btn-drop-sect-row .a-btn-drop-row {padding: var(--drPaddiM);color: var(--drColorM); font-size: var(--drSizeM); line-height: var(--drLineHeM); font-weight: var(--drWeightM)}
.a-btn-drop-row:hover {  background: var(--drBkgHov);outline: none;color: var(--drColorHov)}
.a-btn-drop-sect-row .a-btn-drop-row:hover {  background: var(--drBkgHovM); color: var(--drColorHovM);}
