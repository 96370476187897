.feed-container { -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);border-radius: 8px;color: rgb(38, 38, 38);
  background-color: white;min-height: 200px; overflow: hidden;font-family: "Helvetica Neue", Helvetica, Arial, Utkal, sans-serif!important;position: relative;}
.ukr-feed-header {margin: var(--feed-cont-padding); }
.ukr-feed-header h3 {font-size: var(--feedTitleSize);line-height: 1.25; font-weight: 600}
.ukr-feed-header h4 {font-size: var(--feedsubtitleSize);margin-top: 2px;color: rgb(88, 88, 88);font-weight: 500}
.ukr-feed-date {color:rgb(204, 0, 0); font-size: 14px; font-weight: 500;margin-bottom: 2px;}
.ukr-feed-text {margin: var(--feed-cont-padding); }
.ukr-feed-tweet {margin: var(--feed-cont-padding) auto; width: 100%; position: relative;}
.ukr-feed-tweet > div {margin: 0 auto; width: auto;}
.twitter-tweet {margin: 0 auto !important; }
.ukr-feed-text p {font-size: 16px; line-height: 1.62; margin: 16px 0}
.ukr-feed-media-wrapper {width: 100%;padding-bottom: 56.25%;position: relative; }
.ukr-feed-media-abs {position: absolute;width: 100%;height: 100%}
.ukr-feed-author {position: absolute;bottom: 0; left: 0; display: flex;justify-content: flex-start;align-items: center;padding: 0 14px; height: 26px;
  background-color: rgba(255,255,255,.7); font-size: 12px; color:black; line-height: 1; font-weight: 600;  border-top-right-radius:6px}
.feed-auth-icon-cont {width: 20px;height: 20px;margin-right: 4px;margin-left: -8px}
.feed-auth-cop-icon {width: 14px;height: 14px;margin-right: 3px;margin-left: 0}
img.img-portrait {height: 100%; width: auto;object-position: 50% 50%;display: block;margin: 0 auto}
img.img-landscape {height: 100%; width: 100%;object-fit: cover;object-position: 50% 50%;}
.ukr-feed-ext-link-cont {border-top: 1px solid rgb(220,220,220); margin: 16px;padding-top: 16px;font-size: 16px; line-height: 1.2}
.ukr-feed-ext-link-cont h4 {font-weight: 600; color:rgb(204, 0, 0);font-size: 15px;margin-bottom: 4px;}
.ukr-feed-ext-link-cont > a {font-size: 16px; color: navy;display: block; font-weight: 400; margin: 8px;}
.ukr-feed-ext-link-cont > a:hover { color: dodgerblue;}
.ukr-feed-action {margin: var(--feed-cont-padding); }
.fb-post {display: block!important;}
.fb-post span {display: block!important; margin: 0 auto!important}
/*

*/
