
.cl-err-left {right: 100%; left: auto;margin-right: 60px; margin-left: 0;}
.cl-range-input-cont {width: 155px; margin-left: 16px; padding: 8px; background-color: dodgerblue; border-radius: 8px; height: 55px;}
.cl-range-input-inc-icons {width: 20px; height: 20px; line-height: 1; cursor: pointer;}
.cl-range-text-grad { -webkit-text-fill-color: transparent;  text-fill-color: transparent; -webkit-background-clip: text; background-clip: text;}
.scrollable-heading-stuck-bkg {background-color: rgba(255,255,255,.9) !important; z-index: 50}
.scarlet-is-open .scrollable-heading-stuck-bkg {background-color: rgba(255,255,255,1) !important; }
.dissolve-effect {opacity: 0; transition: opacity 1.5s linear;}
@media (max-width:734px) {
.cl-range-ed-container {width: 100%; padding-top: calc(100vw * 0.26);} /* The padding Top compensate the fixed Hex component*/
.conv-is-open {padding-top: calc(100vw * 0.2);}
.cl-range-ed-container.scarlet-is-open {padding-top: calc(100vw * 0.2);height: calc(100vh - 60px);display: flex;flex-direction: column;justify-content: flex-start;}
.cl-range-ed-container.scarlet-is-open.no-top-bar {height: 100vh;}
}





.cl-range-input-col {
  --focusBkgColor: hsl(210,100%,85%); --focusColor: black; --errorBkgColor: #ffdddd; --errorColor: black; --blurBkgColor: var(--focusBkgColor); --blurColor: black;
  --dropBkgColor: hsl(35,95%,63%); --labelColor: white; --dropColor: white;--dropBkgHoverColor: hsl(35,95%,69%); --dropHoverColor: white;
  /* Border botttom colors */
  --brdBlurCol: dodgerblue;--brdErrCol:red;--brdFocusCol:dodgerblue;
}
