/* Defaults ****/
.arr-bkg-blue-def {--bkgArrCol: 30,144,255;--iconCol: rgb(28,28,28);--iconColHover: rgb(28,28,28);--arrContShadow: rgba(0,0,0,0.4);--arrBordSize: 0px; --arrBordCol: transparent;--arrBordColHov: transparent;}
.arr-bkg-orange {--bkgArrCol: 255,94,0;--iconCol: rgba(255,255,255,.7);--iconColHover: rgb(255,255,255);--arrContShadow: rgba(0,0,0,0.4)}
.arr-bkg-opacity-def {--bkgArrOpac: .3;--bkgArrOpacHov: .8;}
.arr-bkg-opacity-1 {--bkgArrOpac: 1;--bkgArrOpacHov: .8;}
.arr-no-bkg{--bkgArrCol: 0,0,0;--bkgArrOpac: .0;--bkgArrOpacHov: .0;--iconCol: rgb(28,28,28);--iconColHover: rgb(180,180,180); }
.arr-no-bkg-orange{--bkgArrCol: 0,0,0;--bkgArrOpac: 0;--bkgArrOpacHov: 0;--iconCol: hsl(35, 95%,49%);--iconColHover: hsl(35, 95%,65%); }
.sld-arr-lg {--cont-height: 95px; --cont-width: 40px; --cont-width-circle: 55px;--icon-size: 33px;--iconLeftMarLeft: 0px;--iconRightMarLeft: 0px}
.sld-arr-md {--cont-height: 70px; --cont-width: 28px; --cont-width-circle: 35px;--icon-size: 25px;--iconLeftMarLeft: 0px;--iconRightMarLeft: 0px}
.sld-arr-sm {--cont-height: 40px; --cont-width: 26px; --cont-width-circle: 30px;--icon-size: 16px;--iconLeftMarLeft: -2px;--iconRightMarLeft: 3px}
.sld-arr-tn {--cont-height: 45px; --cont-width: 22px; --cont-width-circle: 24px;--icon-size: 15px;--iconLeftMarLeft: 0px;--iconRightMarLeft: 0px}
/* Defaults  End ****/

.sld-arr-cont { cursor: pointer; z-index: 4;background-color: rgba(var(--bkgArrCol),var(--bkgArrOpac,1));border: var(--arrBordSize) solid var(--arrBordCol);}

.sld-arr-size {height: var(--cont-height);width: var(--cont-width);}
.sld-arr-size.w3-circle {height: var(--cont-width-circle);width: var(--cont-width-circle);}
.w3-display-left.sld-arr-in {margin-left: 5px}
.w3-display-left.sld-arr-out {margin-left: calc((var(--cont-width) * -1) - 5px)}
.w3-display-left.sld-arr-mid {margin-left: calc(var(--cont-width) * -1/2)}
.w3-display-right.sld-arr-in {margin-right: 5px}
.w3-display-right.sld-arr-out {margin-right: calc((var(--cont-width) * -1) - 5px)}
.w3-display-right.sld-arr-mid {margin-right: calc(var(--cont-width) * -1/2)}

.w3-display-left .arr-ic-cont{margin-left: var(--iconLeftMarLeft);}
.w3-display-right .arr-ic-cont {margin-left: var(--iconRightMarLeft);}


.arr-ic-cont {width: var(--icon-size); height: var(--icon-size);line-height: var(--icon-size)}



@media only screen and (min-width: 1300px) {
  .sld-arr-cont:hover {background-color: rgba(var(--bkgArrCol),var(--bkgArrOpacHov,1));border: var(--arrBordSize) solid var(--arrBordColHov);}
  .left-shad:hover {box-shadow: 1px 1px 3px var(--arrContShadow);transform: translate(-3px, calc(-50% - 3px)) !important;}
  .right-shad:hover {box-shadow: -1px 1px 3px var(--arrContShadow);transform: translate(3px, calc(-50% - 3px)) !important;}
  .sld-arr-cont:hover .material-icons {color: var(--iconColHover)!important;cursor: pointer;}
}
