.cl-actions-comverter-cont {padding: 14px; background-color: #FBF4F6; border-radius: 4px;}
.cl-actions-comverter-cont h3 {font-weight: 600; line-height: 1; margin: 6px 0 12px 0; }
.cl-conv-fromBtn-width {width: calc( (100% - 24px) / 4 ); }
.cl-triplet-row { font-size: 16px;width: calc((100% - 32px) / 3 + 16px);}
.cl-triplet-row:first-child {width: calc((100% - 32px) / 3);}
.cl-triplet-row-label {width: 44px; z-index: 5;  padding: 0 12px 0 16px; line-height: 1; font-weight: 600; font-size: 16px}
.cl-conv-hex-label { padding-right: 16px; line-height: 1; font-weight: 600; font-size: 16px}
.cl-triplet-row:first-child .cl-triplet-row-label {width: 28px; padding-left: 0;}
.cl-conv-from {font-size: 13px;margin-bottom: 8px; font-weight: 600; color: #D2691E; line-height: 1}
.cl-inp-converter-error { margin: 16px auto; font-size: 14px; width: 200px;z-index: 50;
  padding: 0 10px;border: none;border-radius: 6px 0 0 6px; border-left: 4px solid red; ;background-color: white; line-height: 1.2; min-height: 50px;
   font-weight: 500; color: black;}
.hex-imp-act-defs {
  /* container */   /**/
  --bRadius: 5px;  --brd:2px; --height: 34px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
  /* input */
  --inpContPad: 16px; --inpSize: 15px;  --inpWeight: 500;
  /* dropdrown */
  --dropHeight: 40px;
}

.hex-imp-triplet-defs {
  /* container */   /**/
  --bRadius: 5px;  --brd:2px; --height: 34px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
  /* input */
  --inpContPad: 6px; --inpSize: 15px;  --inpWeight: 500;

}

@media (max-width:734px) {
.cl-actions-comverter-cont {padding: 0 12px; background-color: inherit; border-radius: 0;}
.cl-actions-comverter-cont h3 { margin: 6px 0 12px 0; font-weight: 500;}
.cl-conv-from {font-size: 14px;margin-bottom: 8px;  }
.cl-conv-hex-label { padding-right: 24px;  font-size: 22px}
.cl-triplet-row-label {width: auto;  padding: 0 16px 0 20px; font-size: 22px}
.cl-inp-converter-error { margin: 0 auto; font-size: 15px; width: 280px;z-index: 50;
  padding: 0 10px;border: none;border-radius: 8px; border-left: 4px solid red; ;background-color: white; line-height: 1.2; min-height: 55px;
   font-weight: 500; color: black;}
.hex-imp-act-defs {
  /* container */   /**/
  --bRadius: 5px;  --brd:2px; --height: 40px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
  /* input */
  --inpContPad: 20px; --inpSize: 20px;  --inpWeight: 500;

}

.hex-imp-triplet-defs {
  /* container */   /**/
  --bRadius: 5px;  --brd:2px; --height: 40px;  /*brdBot defines the bottom bar set 0 to disable the bar. use the color settings to define the color on typing/focus, on blur and when an error occurred*/
  /* input */
  --inpContPad: 10px; --inpSize: 20px;  --inpWeight: 500;

}

}
