
.ul-sld-cols-1 {--cols: 1;}.ul-sld-cols-3 {--cols: 3;} .ul-sld-cols-5 {--cols: 5;} .ul-sld-cols-7 {--cols: 7;} .ul-sld-cols-9 {--cols: 9;} .ul-sld-cols-11 {--cols: 11;}
.uld-sld-outer {position: relative;height: 100%;width: 100%}
.ul-sld-cont {height: 100%; --liWidth: calc(100% / var(--cols)); --index: calc((var(--cols) - 1) / 2); --final: calc(-1 * var(--liWidth) * var(--index));
--prev: calc(var(--final) - var(--liWidth)); --next: calc(var(--final) + var(--liWidth))}
.ul-sld-ul {height: 100%;width: calc(100% * var(--cols)); transform: translateX(var(--final)); -webkit-transform: translateX(var(--final));}
ul.ul-sld-ul > li {height: 100%;width: var(--liWidth); vertical-align: top;}
.ul-sld-anim-next {animation: ul-sld-anim-next 400ms ease-out;}
.ul-sld-anim-prev {animation: ul-sld-anim-prev 400ms ease-out;}


  @-webkit-keyframes ul-sld-anim-next {
    from {
        -webkit-transform: translateX(var(--next));
        transform: translateX(var(--next));
    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));

    }
  }

  @keyframes ul-sld-anim-next {
    from {
        -webkit-transform: translateX(var(--next));
        transform: translateX(var(--next));
    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));

    }
  }

  @-webkit-keyframes ul-sld-anim-prev {
    from {
        -webkit-transform: translateX(var(--prev));
        transform: translateX(var(--prev));

    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));
    }
  }

  @keyframes ul-sld-anim-prev {
    from {
        -webkit-transform: translateX(var(--prev));
        transform: translateX(var(--prev));
    }

    to {
        -webkit-transform: translateX(var(--final));
        transform: translateX(var(--final));
    }
  }
