.frHero-cont {position: relative; padding-top: 32px;height: 100%; display: flex; justify-content: center;align-items: center;}
.fr-hero-header {display: flex; flex-direction: column; height: 100%; position: relative; z-index: 1; justify-content: center; align-items: center; padding: 0 32px;text-align: center;}
.fr-hero-h1 {padding: 0; margin: 0 0 0 0; line-height: 1.2; font-weight: 700; font-size: 33px; color: white; }
.fr-hero-h2 {padding: 0; margin: 24px 0 40px 0 ; line-height: 1.2; font-weight: 500; font-size: 17px; color: Snow; }
.fr-hero-img { margin: 0 auto; height: 100%; width: auto;max-width: 100%}
.frHero-head-cont {z-index: 2; overflow: hidden; height: 100%; width: 50%} {z-index: 2; overflow: hidden; height: 100%; width: 50%}
.frHero-img-cont {z-index: 1; overflow: hidden; height: 100%; width: 50%}

@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 734px) {
  .fr-hero-header {padding: 0 0 0 0; justify-content: flex-start;;}
  .fr-hero-h1 {font-size: 32px;}
  .fr-hero-h2 {margin: 12px auto 20px auto; font-size: 16px; font-weight: 600;max-width: 80vw;max-height: calc(16px * 1.2 * 2)}
  .frHero-cont {flex-direction: column;justify-content: space-around;padding-top: 0;}
  .frHero-head-cont {width: 100%;height: auto; flex-basis: 85%; margin: 0 auto;}
  .frHero-img-cont {width: 100%; height: 40%;position: absolute;bottom: 0;}
  .fr-hero-grad {position: absolute;top: 50%;width: 100vw; height: 50%;z-index: 1; background-image: radial-gradient(rgb(199,199,199)0, rgb(140,140,140) 70%);}
  /*.frHero-btn {background-color: rgba(255,255,255,.8)!important;font-weight: 700 !important; font-size: 18px !important; color: dodgerblue!important;overflow: hidden;}*/
}
